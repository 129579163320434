import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import {
	NomisUnemploymentRateTimeSeriesQuery as NomisUnemploymentRateTimeSeriesQueryType,
	NomisUnemploymentRateTimeSeriesQuery$data,
} from '../../graphql/queries/__generated__/NomisUnemploymentRateTimeSeriesQuery.graphql'
import { NomisUnemploymentRateTimeSeriesQuery } from '../../graphql/queries/NomisUnemploymentRateTimeSeries'
import { UnemploymentRateChart } from './UnemploymentRateChart'

interface LadUnemploymentRateChartProps {
	ladCode: string
}

const LadUnemploymentRateChart: React.FC<LadUnemploymentRateChartProps> = ({ ladCode }) => {
	const data = useLazyLoadQuery<NomisUnemploymentRateTimeSeriesQueryType>(NomisUnemploymentRateTimeSeriesQuery, {
		geography: ladCode,
	})

	if (!data || !data.nomis) {
		return null
	}

	const model = processUnemploymentRateTimeSeriesData(data)

	return <UnemploymentRateChart model={model} />
}

function processUnemploymentRateTimeSeriesData(data: NomisUnemploymentRateTimeSeriesQuery$data) {
	const { rows, metadata } = data.nomis

	// Get unique dates and sort them
	const uniqueDates = Array.from(new Set(rows.map((row: any) => row.date.substring(0, 4)))).sort((a, b) =>
		a.localeCompare(b),
	)

	// Map the rows to include the geography label
	const mappedRows = rows.map((row: any) => {
		const geographyId = row.geography
		const geographyLabel =
			metadata.find((item: any) => item.type === 'geography' && item.id === geographyId)?.label || geographyId
		return { ...row, geographyLabel }
	})

	// Group rows by geography label
	const groupedData: Record<string, any[]> = mappedRows.reduce((acc: Record<string, any[]>, row: any) => {
		const { geographyLabel } = row
		if (!acc[geographyLabel]) {
			acc[geographyLabel] = []
		}
		acc[geographyLabel].push(row)
		return acc
	}, {})

	// Create datasets
	const datasets = Object.entries(groupedData).map(([key, value]) => {
		return {
			id: key,
			values: uniqueDates.map((date: any) => {
				const rowForDate = value.find((v) => v.date.substring(0, 4) === date)
				return rowForDate ? rowForDate.value : null
			}),
		}
	})

	return { labels: uniqueDates, datasets: datasets }
}

export { LadUnemploymentRateChart }
