import { theme } from '../Theme'
import { formatter } from '../utils/formatNumber'

export const tooltipConfig = (prefix: string, suffix: string) => ({
	callbacks: {
		label: function (context: any) {
			const datasetLabel = context.dataset.label || ''
			const value = context.raw
			return `${datasetLabel}: ${formatter(prefix, suffix)(value)}`
		},
	},
	usePointStyle: true,
	backgroundColor: theme.colors.primarySurface,
	bodyColor: theme.colors.darkText,
	titleColor: theme.colors.darkText,
	boxPadding: 4,
	borderColor: theme.colors.darkText,
	borderWidth: 0.5,
	padding: 12,
})
