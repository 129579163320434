/**
 * @generated SignedSource<<954d3b4bbb08ca9de1a041c1cc097c74>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ElderlySitesChartSiteFragment$data = {
  readonly numberOfBeds: number | null | undefined;
  readonly registrationDate: any | null | undefined;
  readonly " $fragmentType": "ElderlySitesChartSiteFragment";
};
export type ElderlySitesChartSiteFragment$key = {
  readonly " $data"?: ElderlySitesChartSiteFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ElderlySitesChartSiteFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ElderlySitesChartSiteFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registrationDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numberOfBeds",
      "storageKey": null
    }
  ],
  "type": "Site",
  "abstractKey": null
};

(node as any).hash = "7e2ec2d83579fe797b68e729e23ccf8a";

export default node;
