import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { LocalAuthorityByLadCodeQuery as LaQueryResponseType } from '../../../graphql/queries/__generated__/LocalAuthorityByLadCodeQuery.graphql'
import { LocalAuthorityByLadCodeQuery as LaQueryRequestType } from '../../../graphql/queries/LocalAuthorityByLad'
import { Col, Row } from '../../../shared/layout/Grid'
import { VerticalSpace } from '../../../shared/layout/Space'
import { Loading } from '../../../shared/Loading'
import { Panel } from '../../../shared/Panel'
import { PageTitle, ParagraphBold, ParagraphLight } from '../../../shared/Text'

interface CareFundingProps {
	ladCode: string
}

const CareFunding: React.FC<CareFundingProps> = ({ ladCode }) => {
	const { localAuthorityByLadCode: la } = useLazyLoadQuery<LaQueryResponseType>(LaQueryRequestType, { ladCode })
	const { name } = la

	return (
		<Row>
			<Col count={6}>
				<Panel>
					<VerticalSpace size="lg" />
					<PageTitle> {name} </PageTitle>
					<ParagraphBold>
						Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
						dolore magna aliquyam erat, sed diam voluptua. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
						diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
					</ParagraphBold>
					<ParagraphLight>
						Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
						dolore magna aliquyam erat, sed diam voluptua. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
						diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. Lorem ipsum
						dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore
						magna aliquyam erat, sed diam voluptua. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
						nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
					</ParagraphLight>
					<VerticalSpace size="lg" />
				</Panel>
			</Col>
		</Row>
	)
}

const Suspended: React.FC<CareFundingProps> = ({ ladCode }) => {
	return (
		<React.Suspense fallback={<Loading />}>
			<CareFunding ladCode={ladCode} />
		</React.Suspense>
	)
}

export { Suspended as CareFunding }
