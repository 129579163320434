/**
 * @generated SignedSource<<a8798d0b52dde123a59b119234de2a16>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type GeographyTypes = "country" | "lad" | "region" | "ward" | "%future added value";
export type GeographiesByCodesQuery$variables = {
  codes: ReadonlyArray<string>;
};
export type GeographiesByCodesQuery$data = {
  readonly geographiesByCodes: ReadonlyArray<{
    readonly code: string;
    readonly name: string;
    readonly parentCode: string | null | undefined;
    readonly type: GeographyTypes;
  }>;
};
export type GeographiesByCodesQuery = {
  response: GeographiesByCodesQuery$data;
  variables: GeographiesByCodesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "codes"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "codes",
        "variableName": "codes"
      }
    ],
    "concreteType": "Geography",
    "kind": "LinkedField",
    "name": "geographiesByCodes",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "code",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "parentCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GeographiesByCodesQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GeographiesByCodesQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8a516a8c1341d8b4896229958286503c",
    "id": null,
    "metadata": {},
    "name": "GeographiesByCodesQuery",
    "operationKind": "query",
    "text": "query GeographiesByCodesQuery(\n  $codes: [String!]!\n) {\n  geographiesByCodes(codes: $codes) {\n    code\n    parentCode\n    name\n    type\n  }\n}\n"
  }
};
})();

(node as any).hash = "6a64bd99c459bde95f6a7a1cbbaa53de";

export default node;
