import graphql from 'babel-plugin-relay/macro'

export const HouseholdIncomeByLadCodeQuery = graphql`
	query HouseholdIncomeByLadCodeQuery($ladCode: String!) {
		householdIncomeByLadCode(ladCode: $ladCode) {
			ladCode
			years {
				year
				totalInMillions
				population
				perHead
				index
				nationalMedianTotalInMillions
				nationalMedianPerHead
			}
		}
	}
`
