import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { ElderlyCareStatsByLadCodeQuery as EcsResponseType } from '../graphql/queries/__generated__/ElderlyCareStatsByLadCodeQuery.graphql'
import { HouseholdIncomeByLadCodeQuery as GDHIResponseQuery } from '../graphql/queries/__generated__/HouseholdIncomeByLadCodeQuery.graphql'
import { HousePriceIndexByLadCodeQuery as HPIResponseQuery } from '../graphql/queries/__generated__/HousePriceIndexByLadCodeQuery.graphql'
import { LocalAuthorityByLadCodeQuery as LaQueryResponseType } from '../graphql/queries/__generated__/LocalAuthorityByLadCodeQuery.graphql'
import { MedianAgeByLadCodeQuery as AgeResponseQuery } from '../graphql/queries/__generated__/MedianAgeByLadCodeQuery.graphql'
import { NomisUnemploymentRateTimeSeriesQuery as NomisUnemploymentRateTimeSeriesQueryType } from '../graphql/queries/__generated__/NomisUnemploymentRateTimeSeriesQuery.graphql'
import { PopulationBracketsByLadCodeQuery as PopResponseQuery } from '../graphql/queries/__generated__/PopulationBracketsByLadCodeQuery.graphql'
import { ElderlyCareStatsByLadCodeQuery as EcsRequestType } from '../graphql/queries/ElderlyCareStatsByLad'
import { HouseholdIncomeByLadCodeQuery as GHDIRequestQuery } from '../graphql/queries/HouseholdIncomeByLad'
import { HousePriceIndexByLadCodeQuery as HPIRequestQuery } from '../graphql/queries/HousePriceIndexByLad'
import { LocalAuthorityByLadCodeQuery as LaQueryRequestType } from '../graphql/queries/LocalAuthorityByLad'
import { MedianAgeByLadCodeQuery as AgeRequestQuery } from '../graphql/queries/MedianAgeByLad'
import { NomisUnemploymentRateTimeSeriesQuery } from '../graphql/queries/NomisUnemploymentRateTimeSeries'
import { PopulationBracketsByLadCodeQuery as PopRequestQuery } from '../graphql/queries/PopulationBrackets'
import { formatNumber } from '../utils/formatNumber'
import { Col, Container, Row } from './layout/Grid'
import { Line } from './layout/Line'
import { Loading } from './Loading'
import { Heading, Paragraph } from './Text'

interface QuickFactsProps {
	ladCode: string
}

const QuickFacts: React.FC<QuickFactsProps> = ({ ladCode }) => {
	const { localAuthorityByLadCode: la } = useLazyLoadQuery<LaQueryResponseType>(LaQueryRequestType, { ladCode })

	const { populationBracketsByLadCode: pop } = useLazyLoadQuery<PopResponseQuery>(PopRequestQuery, {
		ladCode,
		bracket: '0-100',
		yearsFuture: 1,
		yearsPast: 1,
	})

	const { elderlyCareStatsByLadCode: ecs } = useLazyLoadQuery<EcsResponseType>(EcsRequestType, {
		ladCode,
	})

	const { medianAgeByLadCode: age } = useLazyLoadQuery<AgeResponseQuery>(AgeRequestQuery, {
		ladCode,
		years: 1,
	})

	const { householdIncomeByLadCode: gdhi } = useLazyLoadQuery<GDHIResponseQuery>(GHDIRequestQuery, {
		ladCode,
	})

	const { nomis: emp } = useLazyLoadQuery<NomisUnemploymentRateTimeSeriesQueryType>(
		NomisUnemploymentRateTimeSeriesQuery,
		{
			geography: ladCode,
		},
	)

	const { housePriceIndexByLadCode: hpi } = useLazyLoadQuery<HPIResponseQuery>(HPIRequestQuery, {
		ladCode,
	})

	const nationalGDHI = '£ ' + formatNumber(22213)
	const nationalElderlyShare = formatNumber(19.1) + '%'
	const nationalHouseAvg = '£ ' + formatNumber(308605)

	const gdhiMostRecent = gdhi.years.reduce((mostRecent, current) => {
		return current.year > mostRecent.year ? current : mostRecent
	}, gdhi.years[0])
	const ladPop = pop.years[1].geographies[0].total
	const nationalPop = pop.years[1].geographies[2].total
	const ladHousePrice = hpi.ladYears[hpi.ladYears.length - 1].averagePrice

	const popLad = formatNumber(ladPop)
	const popComparison = formatNumber(nationalPop)
	const elderlyPopShare = formatNumber((ecs.populationOver65 / ladPop) * 100) + '%'
	const ladAge = formatNumber(age.ladAge)
	const nationalAge = formatNumber(age.nationalAge)
	const gdhiLad = '£ ' + formatNumber(gdhiMostRecent.perHead)
	const ladEmp = formatNumber(emp.rows[0].value || 0) + '%'
	const nationalEmp = formatNumber(emp.rows[1].value || 0) + '%'
	const ladAgHousePrice = '£ ' + formatNumber(ladHousePrice || 0)

	return (
		<Container>
			<Row>
				<Heading level={2}>{la.name}</Heading>
				<Paragraph>{`${la.regionName}, ${la.countryName}`}</Paragraph>
			</Row>
			<Row>
				<QuickFact heading="Population" value={popLad} comparison={popComparison} isComparable={false} />
				<QuickFact heading="Elderly Population Share" value={elderlyPopShare} comparison={nationalElderlyShare} />
				<QuickFact heading="Median Age" value={ladAge} comparison={nationalAge} />
				<QuickFact heading="Disposable Household Income" value={gdhiLad} comparison={nationalGDHI} />
				<QuickFact heading="Unemployment Rate" value={ladEmp} comparison={nationalEmp} />
				<QuickFact heading="House Price Average" value={ladAgHousePrice} comparison={nationalHouseAvg} />
			</Row>
		</Container>
	)
}

interface QuickFactProps {
	heading: string
	value: string
	comparison: string
	isComparable?: boolean
}

const QuickFact: React.FC<QuickFactProps> = ({ heading, value, comparison, isComparable = true }) => {
	const color = isComparable ? (value > comparison ? 'text-success' : 'text-danger') : 'text-body'

	return (
		<Col count={6}>
			<Line />
			<Heading level={6}>{heading}</Heading>
			<Heading level={5} className={color}>
				{value}
			</Heading>
			<Paragraph>{comparison}</Paragraph>
		</Col>
	)
}

const Suspended: React.FC<QuickFactsProps> = ({ ladCode }) => {
	return (
		<React.Suspense fallback={<Loading />}>
			<QuickFacts ladCode={ladCode} />
		</React.Suspense>
	)
}

export { Suspended as QuickFacts }
