import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import {
	PopulationBracketsByLadCodeQuery as PopulationQueryType,
	PopulationBracketsByLadCodeQuery$data as PopulationQueryDataType,
} from '../graphql/queries/__generated__/PopulationBracketsByLadCodeQuery.graphql'
import {
	StatXUniversalCreditHouseholdsQuery as UCQueryType,
	StatXUniversalCreditHouseholdsQuery$data as UCQueryDataType,
} from '../graphql/queries/__generated__/StatXUniversalCreditHouseholdsQuery.graphql'
import { PopulationBracketsByLadCodeQuery } from '../graphql/queries/PopulationBrackets'
import { StatXUniversalCreditHouseholdsQuery } from '../graphql/queries/StatXUniversalCreditHouseholds'
import { LineChart } from '../shared/charts/LineChart'
import { VerticalSpace } from '../shared/layout/Space'
import { Paragraph, Subtitle } from '../shared/Text'

interface HouseholdsWithUniversalCreditWorkingPopulationRatioProps {
	ladCode: string
}

const HouseholdsWithUniversalCreditWorkingPopulationRatio: React.FC<
	HouseholdsWithUniversalCreditWorkingPopulationRatioProps
> = ({ ladCode }) => {
	// Fetch working population data
	const populationData = useLazyLoadQuery<PopulationQueryType>(PopulationBracketsByLadCodeQuery, {
		ladCode: ladCode,
		bracket: '16-64', // Working-age population
		yearsPast: 5,
		yearsFuture: 0,
	})

	// Fetch Universal Credit households data
	const ucData = useLazyLoadQuery<UCQueryType>(StatXUniversalCreditHouseholdsQuery, {
		geography: ladCode,
	})

	// Process data
	const { labels, datasets } = transformData(populationData, ucData)

	return (
		<>
			<Subtitle>Universal Credit Households to Working Population Ratio</Subtitle>
			<Paragraph>
				The households on Universal Credit as a share of the working age population (16-64) over time.
			</Paragraph>
			<VerticalSpace size="sm" />
			<LineChart
				model={{
					labels,
					datasets,
				}}
				prefix=""
				suffix="%"
				dataLabelDisplayOption="showAll"
				aspectRatio={1}
			/>
		</>
	)
}

function transformData(
	populationData: PopulationQueryDataType,
	ucData: UCQueryDataType,
): { labels: string[]; datasets: { id: string; values: (number | null)[] }[] } {
	// Validate population data
	if (
		!populationData ||
		!populationData.populationBracketsByLadCode ||
		!populationData.populationBracketsByLadCode.years
	) {
		console.error('Invalid population data structure.')
		return { labels: [], datasets: [] }
	}

	// Validate Universal Credit data
	if (!ucData || !ucData.statx || !ucData.statx.rows || !ucData.statx.headers) {
		console.error('Invalid Universal Credit data structure.')
		return { labels: [], datasets: [] }
	}

	const populationYears = populationData.populationBracketsByLadCode.years

	// Build a map for working population data: { year: { geoName: total } }
	const workingPopulationMap: { [year: string]: { [geoName: string]: number } } = {}
	populationYears.forEach((yearEntry) => {
		const year = yearEntry.year.toString()
		if (!workingPopulationMap[year]) {
			workingPopulationMap[year] = {}
		}
		yearEntry.geographies.forEach((geo) => {
			workingPopulationMap[year][geo.geoName] = geo.total
		})
	})

	// Build a map for Universal Credit counts: { year: { geoName: count } }
	const ucHeaders = ucData.statx.headers
	const dateIndex = ucHeaders.indexOf('Month') // Adjust header names as per your data
	const geographyIndex = ucHeaders.indexOf('National - Regional - LA - OAs')
	const valueIndex = ucHeaders.indexOf('Households on Universal Credit')

	if (dateIndex === -1 || geographyIndex === -1 || valueIndex === -1) {
		console.error('Required headers not found in Universal Credit data.')
		return { labels: [], datasets: [] }
	}

	const ucRows = ucData.statx.rows

	// Build a map for UC counts: { year: { geoName: count } }
	const ucMap: { [year: string]: { [geoName: string]: number } } = {}

	ucRows.forEach((row) => {
		const dateStr = row[dateIndex] as string // e.g., "November 2023"
		const geoName = row[geographyIndex] as string
		const countStr = row[valueIndex] as string // UC households count

		// Extract year from dateStr (e.g., 'November 2023' -> '2023')
		let year: string
		if (dateStr.includes(' ')) {
			// Assuming format 'Month YYYY'
			const parts = dateStr.split(' ')
			if (parts.length === 2) {
				year = parts[1] // '2023'
			} else {
				// Unexpected format
				year = dateStr
			}
		} else {
			// Handle other date formats if necessary
			year = dateStr.slice(-4) // Assuming the last 4 characters are the year
		}

		if (!ucMap[year]) {
			ucMap[year] = {}
		}
		if (!ucMap[year][geoName]) {
			ucMap[year][geoName] = 0
		}
		ucMap[year][geoName] += parseInt(countStr, 10) // Sum counts per year and geography
	})

	// Collect all years and geographies
	const yearsSet = new Set<string>()
	const geographiesSet = new Set<string>()

	// Collect years from the UC data
	Object.keys(ucMap).forEach((year) => {
		yearsSet.add(year)
	})

	// Collect geographies from UC data
	ucRows.forEach((row) => {
		const geoName = row[geographyIndex] as string
		geographiesSet.add(geoName)
	})

	// Collect geographies from population data
	populationYears.forEach((yearEntry) => {
		yearEntry.geographies.forEach((geo) => {
			geographiesSet.add(geo.geoName)
		})
	})

	// Convert sets to arrays and sort
	const labels = Array.from(yearsSet).sort()
	const geographies = Array.from(geographiesSet)

	// Build datasets
	const datasets: { id: string; values: (number | null)[] }[] = []

	geographies.forEach((geoName) => {
		// Collect values for each year
		const values = labels.map((year) => {
			const ucCount = ucMap[year] && ucMap[year][geoName] !== undefined ? ucMap[year][geoName] : null
			const workingPopulation =
				workingPopulationMap[year] && workingPopulationMap[year][geoName] !== undefined
					? workingPopulationMap[year][geoName]
					: null

			if (ucCount !== null && workingPopulation !== null && workingPopulation > 0) {
				const ratio = (ucCount / workingPopulation) * 100
				return parseFloat(ratio.toFixed(2)) // Round to two decimal places
			} else {
				return null
			}
		})

		// Only include datasets with at least one non-null value
		if (values.some((value) => value !== null)) {
			datasets.push({
				id: geoName,
				values: values,
			})
		}
	})

	return { labels, datasets }
}

export { HouseholdsWithUniversalCreditWorkingPopulationRatio }
