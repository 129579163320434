/**
 * @generated SignedSource<<14f693dca1e0500622ae14434c42cc39>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type NomisIndustriesBroadWorkforceQuery$variables = {
  geography: string;
};
export type NomisIndustriesBroadWorkforceQuery$data = {
  readonly nomis: {
    readonly metadata: ReadonlyArray<{
      readonly id: string;
      readonly label: string;
      readonly type: string;
    }>;
    readonly rows: ReadonlyArray<{
      readonly date: string;
      readonly dimensions: any;
      readonly geography: string;
      readonly measures: string;
      readonly value: number | null | undefined;
    }>;
    readonly title: string;
    readonly url: string;
  };
};
export type NomisIndustriesBroadWorkforceQuery = {
  response: NomisIndustriesBroadWorkforceQuery$data;
  variables: NomisIndustriesBroadWorkforceQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "geography"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Literal",
            "name": "dataset",
            "value": "NM_189_1"
          },
          {
            "kind": "Variable",
            "name": "geography",
            "variableName": "geography"
          },
          {
            "kind": "Literal",
            "name": "measures",
            "value": "20100"
          },
          {
            "kind": "Literal",
            "name": "parents",
            "value": true
          },
          {
            "kind": "Literal",
            "name": "query",
            "value": "measure=2&employment_status=4&industry=163577857&industry=163577858&industry=163577859&industry=163577860&industry=163577861&industry=163577862&industry=163577863&industry=163577864&industry=163577865&industry=163577866&industry=163577867&industry=163577868&industry=163577869&industry=163577870&industry=163577871&industry=163577872&industry=163577873&industry=163577874"
          },
          {
            "kind": "Literal",
            "name": "time",
            "value": "latest"
          },
          {
            "kind": "Literal",
            "name": "useLabels",
            "value": false
          }
        ],
        "kind": "ObjectValue",
        "name": "query"
      }
    ],
    "concreteType": "NomisResponse",
    "kind": "LinkedField",
    "name": "nomis",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "NomisMetadata",
        "kind": "LinkedField",
        "name": "metadata",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "label",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "NomisRow",
        "kind": "LinkedField",
        "name": "rows",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "date",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dimensions",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "geography",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "measures",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NomisIndustriesBroadWorkforceQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NomisIndustriesBroadWorkforceQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "fac56bbeaecec34715cf65c29c230970",
    "id": null,
    "metadata": {},
    "name": "NomisIndustriesBroadWorkforceQuery",
    "operationKind": "query",
    "text": "query NomisIndustriesBroadWorkforceQuery(\n  $geography: String!\n) {\n  nomis(query: {dataset: \"NM_189_1\", geography: $geography, parents: true, time: \"latest\", measures: \"20100\", query: \"measure=2&employment_status=4&industry=163577857&industry=163577858&industry=163577859&industry=163577860&industry=163577861&industry=163577862&industry=163577863&industry=163577864&industry=163577865&industry=163577866&industry=163577867&industry=163577868&industry=163577869&industry=163577870&industry=163577871&industry=163577872&industry=163577873&industry=163577874\", useLabels: false}) {\n    metadata {\n      id\n      label\n      type\n    }\n    rows {\n      date\n      dimensions\n      geography\n      measures\n      value\n    }\n    title\n    url\n  }\n}\n"
  }
};
})();

(node as any).hash = "52375fd13d6493fb8603945a896830cc";

export default node;
