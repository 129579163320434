/**
 * Returns a function that maps a value to a bracket index
 */
function getBracketIndex(min: number, max: number, brackets: number) {
	const rangeSize = (max - min) / brackets

	return (value: number) => {
		const index = Math.floor((value - min) / rangeSize)
		return index >= brackets ? brackets - 1 : index
	}
}

export { getBracketIndex }
