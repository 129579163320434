import React from 'react'

interface ProgressBarProps {
	progress: number
	backgroundColor?: string
}

const style = { height: 5, width: '100%' }

const ProgressBar: React.FC<ProgressBarProps> = ({ progress, backgroundColor = '' }) => {
	return (
		<div
			className="progress"
			role="progressbar"
			aria-valuenow={progress}
			aria-valuemin={0}
			aria-valuemax={100}
			style={style}
		>
			<div className="progress-bar" style={{ width: progress, backgroundColor }}></div>
		</div>
	)
}

export { ProgressBar }
