// RelayProviderWithAuth.tsx
import { useAuth0 } from '@auth0/auth0-react'
import React from 'react'
import { RelayEnvironmentProvider } from 'react-relay/hooks'

import { getApiUrl } from '../Config'
import { createRelayEnvironment } from './environment' // Update the path as necessary

const RelayProviderWithAuth: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const { getAccessTokenSilently } = useAuth0()
	const apiUrl = getApiUrl()

	// Create the Relay environment with the token retrieval function
	const environment = createRelayEnvironment(apiUrl, getAccessTokenSilently)

	return <RelayEnvironmentProvider environment={environment}>{children}</RelayEnvironmentProvider>
}

export { RelayProviderWithAuth }
