import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { HourlyWagesByLadCodeQuery as HourlyWagesByLadCodeQueryType } from '../../../../graphql/queries/__generated__/HourlyWagesByLadCodeQuery.graphql'
import { NomisGDHITimeSeriesQuery as NomisGDHITimeSeriesQueryType } from '../../../../graphql/queries/__generated__/NomisGDHITimeSeriesQuery.graphql'
import { NomisIndustriesBroadWorkforceQuery as NomisIndustriesBroadWorkforceQueryType } from '../../../../graphql/queries/__generated__/NomisIndustriesBroadWorkforceQuery.graphql'
import { NomisUnemploymentRateTimeSeriesQuery as NomisUnemploymentRateTimeSeriesQueryType } from '../../../../graphql/queries/__generated__/NomisUnemploymentRateTimeSeriesQuery.graphql'
import { PopulationBracketsByLadCodeQuery as PopulationQueryType } from '../../../../graphql/queries/__generated__/PopulationBracketsByLadCodeQuery.graphql'
import { StatXUniversalCreditHouseholdsQuery as UCQueryType } from '../../../../graphql/queries/__generated__/StatXUniversalCreditHouseholdsQuery.graphql'
import { HourlyWagesByLadCodeQuery } from '../../../../graphql/queries/HourlyWagesByLad'
import { NomisGDHITimeSeriesQuery } from '../../../../graphql/queries/NomisGDHITimeSeries'
import { NomisIndustriesBroadWorkforceQuery } from '../../../../graphql/queries/NomisIndustriesBroadWorkforce'
import { NomisUnemploymentRateTimeSeriesQuery } from '../../../../graphql/queries/NomisUnemploymentRateTimeSeries'
import { PopulationBracketsByLadCodeQuery } from '../../../../graphql/queries/PopulationBrackets'
import { StatXUniversalCreditHouseholdsQuery } from '../../../../graphql/queries/StatXUniversalCreditHouseholds'
import { AIStructuredText } from '../../../../shared/Ai/AIStructuredText'
import { Loading } from '../../../../shared/Loading'

interface AIEconomicSummaryProps {
	ladCode: string
}

const AIEconomicSummary: React.FC<AIEconomicSummaryProps> = ({ ladCode }) => {
	const GDHIdata = useLazyLoadQuery<NomisGDHITimeSeriesQueryType>(NomisGDHITimeSeriesQuery, {
		geography: ladCode,
	})

	const unemploymentData = useLazyLoadQuery<NomisUnemploymentRateTimeSeriesQueryType>(
		NomisUnemploymentRateTimeSeriesQuery,
		{ geography: ladCode },
	)

	const hourlyWageData = useLazyLoadQuery<HourlyWagesByLadCodeQueryType>(HourlyWagesByLadCodeQuery, {
		ladCode: ladCode,
	})

	const populationData = useLazyLoadQuery<PopulationQueryType>(PopulationBracketsByLadCodeQuery, {
		ladCode: ladCode,
		bracket: '16-64',
		yearsPast: 5,
		yearsFuture: 0,
	})

	const ucData = useLazyLoadQuery<UCQueryType>(StatXUniversalCreditHouseholdsQuery, {
		geography: ladCode,
	})

	const industriesData = useLazyLoadQuery<NomisIndustriesBroadWorkforceQueryType>(NomisIndustriesBroadWorkforceQuery, {
		geography: ladCode,
	})

	const dataToAnalyse = {
		GDHI: GDHIdata,
		UnemploymentRate: unemploymentData,
		HourlyWage: hourlyWageData,
		HouseholdsOnUniversalCredit: ucData,
		Population: populationData,
		IndustriesByWorkforce: industriesData,
	}

	const prompt = `
    Analyse the economic state of the Local Authority (ladCode: ${ladCode}), comparing to its Region, and England based on the following Gross Disposable Household Income data: ${JSON.stringify(dataToAnalyse.GDHI)}
    Please answer in at least 2 paragraphs and between 100 - 200 chars.
  `

	const iteration = 3
	const key = `economic-summary-${ladCode}-${iteration}`

	return <AIStructuredText prompt={prompt} cacheKey={key} />
}

const SuspendedAIEconomicSummary: React.FC<AIEconomicSummaryProps> = ({ ladCode }) => {
	return (
		<React.Suspense fallback={<Loading />}>
			<AIEconomicSummary ladCode={ladCode} />
		</React.Suspense>
	)
}

export { SuspendedAIEconomicSummary as AIEconomicSummary }
