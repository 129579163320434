// this is for a horizontal barGraph component which still needs to be build and therefore transformed (currently it runs in retool via the old plotly custom component)
import graphql from 'babel-plugin-relay/macro'

export const NomisIndustriesBroadWorkforceQuery = graphql`
	query NomisIndustriesBroadWorkforceQuery($geography: String!) {
		nomis(
			query: {
				dataset: "NM_189_1"
				geography: $geography
				parents: true
				time: "latest"
				measures: "20100"
				query: "measure=2&employment_status=4&industry=163577857&industry=163577858&industry=163577859&industry=163577860&industry=163577861&industry=163577862&industry=163577863&industry=163577864&industry=163577865&industry=163577866&industry=163577867&industry=163577868&industry=163577869&industry=163577870&industry=163577871&industry=163577872&industry=163577873&industry=163577874"
				useLabels: false
			}
		) {
			metadata {
				id
				label
				type
			}
			rows {
				date
				dimensions
				geography
				measures
				value
			}
			title
			url
		}
	}
`
