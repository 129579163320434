import graphql from 'babel-plugin-relay/macro'

export const StatXUniversalCreditHouseholdsQuery = graphql`
	query StatXUniversalCreditHouseholdsQuery($geography: String!) {
		statx(
			query: {
				database: "UC_Households"
				table: "V_F_UC_HOUSEHOLDS"
				geography: $geography
				measures: [{ key: "count", value: "V_F_UC_HOUSEHOLDS" }]
				dimensions: [
					{
						field: "F_UC_HH_DATE:DATE_NAME"
						values: [
							"C_UC_HH_DATE:202311"
							"C_UC_HH_DATE:202212"
							"C_UC_HH_DATE:202112"
							"C_UC_HH_DATE:202012"
							"C_UC_HH_DATE:201912"
						]
						total: false
					}
				]
			}
		) {
			headers
			rows
			title
			url
		}
	}
`
