import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import {
	NomisGDHITimeSeriesQuery as NomisGDHITimeSeriesQueryType,
	NomisGDHITimeSeriesQuery$data,
} from '../../graphql/queries/__generated__/NomisGDHITimeSeriesQuery.graphql'
import { NomisGDHITimeSeriesQuery } from '../../graphql/queries/NomisGDHITimeSeries'
import { GDHITimeSeriesChart } from './GDHITimeSeries'

interface LadGDHITimeSeriesChartProps {
	ladCode: string
}

const LadGDHITimeSeriesChart: React.FC<LadGDHITimeSeriesChartProps> = ({ ladCode }) => {
	const data = useLazyLoadQuery<NomisGDHITimeSeriesQueryType>(NomisGDHITimeSeriesQuery, {
		geography: ladCode,
	})

	const model = processGDHITimeSeriesData(data)

	return <GDHITimeSeriesChart model={model} />
}

function processGDHITimeSeriesData(data: NomisGDHITimeSeriesQuery$data) {
	// Extract the rows and metadata from the query response
	const { rows, metadata } = data.nomis

	// Get unique dates (years) and sort them
	const uniqueDates = Array.from(new Set(rows.map((row: any) => row.date.substring(0, 4)))).sort((a, b) =>
		a.localeCompare(b),
	)

	// Map the rows to include the geography label
	const mappedRows = rows.map((row: any) => {
		const geographyId = row.geography
		const geographyLabel =
			metadata.find((item: any) => item.type === 'geography' && item.id === geographyId)?.label || geographyId
		return { ...row, geographyLabel }
	})

	// Group rows by geography label
	const groupedData: Record<string, any[]> = mappedRows.reduce((acc: Record<string, any[]>, row: any) => {
		const { geographyLabel } = row
		if (!acc[geographyLabel]) {
			acc[geographyLabel] = []
		}
		acc[geographyLabel].push(row)
		return acc
	}, {})

	// Create datasets
	const datasets = Object.entries(groupedData).map(([key, value]) => {
		return {
			id: key,
			values: uniqueDates.map((date: any) => {
				const rowForDate = value.find((v) => v.date.substring(0, 4) === date)
				return rowForDate ? rowForDate.value : null
			}),
		}
	})

	// Return the data in the required format
	return { labels: uniqueDates, datasets: datasets }
}

export { LadGDHITimeSeriesChart }
