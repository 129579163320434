import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import {
	HousePriceIndexByLadCodeQuery as HousePriceIndexByLadCodeQueryType,
	HousePriceIndexByLadCodeQuery$data,
} from '../graphql/queries/__generated__/HousePriceIndexByLadCodeQuery.graphql'
import {
	NomisGDHITimeSeriesQuery as NomisGDHITimeSeriesQueryType,
	NomisGDHITimeSeriesQuery$data,
} from '../graphql/queries/__generated__/NomisGDHITimeSeriesQuery.graphql'
import { HousePriceIndexByLadCodeQuery } from '../graphql/queries/HousePriceIndexByLad'
import { NomisGDHITimeSeriesQuery } from '../graphql/queries/NomisGDHITimeSeries'
import { BarChart, Model } from '../shared/charts/BarChart'
import { VerticalSpace } from '../shared/layout/Space'
import { Paragraph, Subtitle } from '../shared/Text'

interface Dataset {
	id: string
	values: (number | null)[]
}

interface HousingYearsToBuyChartProps {
	ladCode: string
}

const HousingYearsToBuyChart: React.FC<HousingYearsToBuyChartProps> = ({ ladCode }) => {
	const housePriceData = useLazyLoadQuery<HousePriceIndexByLadCodeQueryType>(HousePriceIndexByLadCodeQuery, {
		ladCode: ladCode,
	})

	const gdhiData = useLazyLoadQuery<NomisGDHITimeSeriesQueryType>(NomisGDHITimeSeriesQuery, {
		geography: ladCode,
	})

	const { labels, datasets } = processHousePriceAndGDHIData(housePriceData, gdhiData)

	return (
		<>
			<Subtitle>Years-to-Buy Ratio</Subtitle>
			<Paragraph>How many years does it take to buy a house assuming a 100% GDHI saving rate.</Paragraph>
			<VerticalSpace size="sm" />
			<BarChart
				horizontal={true}
				stacked={false}
				model={{
					labels,
					datasets,
				}}
				showLabels={true}
				alpha={0.9}
				aspectRatio={2}
			/>
		</>
	)

	function processHousePriceAndGDHIData(
		housePriceData: HousePriceIndexByLadCodeQuery$data,
		gdhiData: NomisGDHITimeSeriesQuery$data,
	): Model {
		if (!housePriceData || !housePriceData.housePriceIndexByLadCode) {
			return { labels: [], datasets: [] }
		}
		if (!gdhiData || !gdhiData.nomis) {
			return { labels: [], datasets: [] }
		}

		// **Step 1: Extract LAD Code and Labels**
		const ladData = housePriceData.housePriceIndexByLadCode
		const ladCode = ladData.ladCode
		const ladName = ladData.ladCode || ladCode // Use ladName if available
		const ladYears = ladData.ladYears || []
		const nationalYears = ladData.nationalYears || []

		// Build geography labels lookup from GDHI metadata
		const gdhiMetadata = gdhiData.nomis.metadata || []
		const geographyLookup: { [key: string]: string } = {}
		gdhiMetadata.forEach((item) => {
			if (item.type === 'geography') {
				geographyLookup[item.id] = item.label
			}
		})

		const ladGeographyLabel = geographyLookup[ladCode] || ladName
		const englandGeographyLabel = 'England' // GDHI data uses "England" as label

		// **Step 2: Extract GDHI Data for LAD and England**
		const gdhiRows = gdhiData.nomis.rows || []

		// Map GDHI data per geography and year
		const gdhiDataByGeography: { [geo: string]: Map<number, number> } = {}

		gdhiRows.forEach((row) => {
			const geographyId = row.geography
			const geographyLabel = geographyLookup[geographyId] || geographyId
			const year = parseInt(row.date)
			const value = row.value

			if (!gdhiDataByGeography[geographyLabel]) {
				gdhiDataByGeography[geographyLabel] = new Map<number, number>()
			}
			if (value !== undefined && value !== null) {
				gdhiDataByGeography[geographyLabel].set(year, value)
			}
		})

		// **Step 3: Map House Price Data per Geography**
		const housePriceDataByGeography: { [geo: string]: Map<number, number> } = {}

		// Prepare LAD house prices
		const ladHousePriceMap = new Map<number, number>()
		ladYears.forEach((item) => {
			const year = item.year
			const averagePrice = item.averagePrice
			if (averagePrice !== undefined && averagePrice !== null) {
				ladHousePriceMap.set(year, averagePrice)
			}
		})
		housePriceDataByGeography[ladGeographyLabel] = ladHousePriceMap

		// Prepare National (England) house prices
		const nationalHousePriceMap = new Map<number, number>()
		nationalYears.forEach((item) => {
			const year = item.year
			const averagePrice = item.averagePrice
			if (averagePrice !== undefined && averagePrice !== null) {
				nationalHousePriceMap.set(year, averagePrice)
			}
		})
		housePriceDataByGeography[englandGeographyLabel] = nationalHousePriceMap

		// **Step 4: Collect Common Years**
		const yearsSet = new Set<number>()

		// Collect years where both house price and GDHI data are available for LAD
		ladHousePriceMap.forEach((_, year) => {
			if (gdhiDataByGeography[ladGeographyLabel]?.has(year)) {
				yearsSet.add(year)
			}
		})

		// Collect years where both house price and GDHI data are available for England
		nationalHousePriceMap.forEach((_, year) => {
			if (gdhiDataByGeography[englandGeographyLabel]?.has(year)) {
				yearsSet.add(year)
			}
		})

		// Convert yearsSet to sorted array
		const years = Array.from(yearsSet).sort((a, b) => a - b)

		// **Step 5: Prepare Datasets**
		const datasets: Dataset[] = []
		const geographies = [ladGeographyLabel, englandGeographyLabel]

		geographies.forEach((geo) => {
			const housePriceMap = housePriceDataByGeography[geo]
			const gdhiMap = gdhiDataByGeography[geo]

			if (!housePriceMap || !gdhiMap) {
				console.warn(`Missing data for geography ${geo}`)
				return
			}

			const values = years.map((year) => {
				const housePriceValue = housePriceMap.get(year)
				const gdhiValue = gdhiMap.get(year)

				if (housePriceValue !== undefined && gdhiValue !== undefined && gdhiValue !== 0) {
					const ratio = housePriceValue / gdhiValue
					return parseFloat(ratio.toFixed(2))
				} else {
					return null
				}
			})

			datasets.push({
				id: geo,
				values: values,
			})
		})

		// **Step 6: Prepare Labels**
		const labels = years.map((year) => year.toString())

		return { labels, datasets }
	}
}

export { HousingYearsToBuyChart }
