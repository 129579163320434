import graphql from 'babel-plugin-relay/macro'

export const ElderlyCareStatsQuery = graphql`
	query ElderlyCareStatsQuery {
		elderlyCareStats {
			...ElderlyCareStatsFragment
			...LaTableElderlyCareStatsFragment
		}
	}
`
