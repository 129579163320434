function formatNumber(number: number): string {
	return number.toLocaleString('en-GB', {
		minimumFractionDigits: 0,
		maximumFractionDigits: 2,
	})
}
function formatter(prefix: string, suffix: string) {
	return (value: string | number) => {
		const formattedValue = formatNumber(Number(value))
		return `${prefix}${formattedValue}${suffix}`
	}
}
export { formatNumber, formatter }
