import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import {
	PopulationBracketsByLadCodeQuery as QueryResponseType,
	PopulationBracketsByLadCodeQuery$data as QueryResponseType$Data,
} from '../graphql/queries/__generated__/PopulationBracketsByLadCodeQuery.graphql'
import { PopulationBracketsByLadCodeQuery as QueryRequestType } from '../graphql/queries/PopulationBrackets'
import { LineChart } from '../shared/charts/LineChart'
import { VerticalSpace } from '../shared/layout/Space'
import { Paragraph, Subtitle } from '../shared/Text'

interface FutureElderlyPopulationChartProps {
	ladCode: string
}

const ElderlyPopulationShare: React.FC<FutureElderlyPopulationChartProps> = ({ ladCode }) => {
	// Fetch elderly population data
	const elderlyData = useLazyLoadQuery<QueryResponseType>(QueryRequestType, {
		ladCode: ladCode,
		bracket: '65-1000',
		yearsPast: 10,
		yearsFuture: 10,
	})

	// Fetch total population data
	const totalData = useLazyLoadQuery<QueryResponseType>(QueryRequestType, {
		ladCode: ladCode,
		bracket: '0-1000',
		yearsPast: 10,
		yearsFuture: 10,
	})

	const { labels, datasets } = transformElderlyPopulationShareData(elderlyData, totalData)

	return (
		<>
			<Subtitle>Elderly Population Share</Subtitle>
			<Paragraph>Historic and 10 year forecast</Paragraph>
			<VerticalSpace size="sm" />
			<LineChart
				model={{
					labels,
					datasets,
				}}
				prefix=""
				suffix="%"
				dataLabelDisplayOption="showAll"
				aspectRatio={1}
			/>
		</>
	)
}
function transformElderlyPopulationShareData(
	elderlyData: QueryResponseType$Data,
	totalData: QueryResponseType$Data,
): { labels: string[]; datasets: { id: string; values: (number | null)[] }[] } {
	if (!elderlyData || !elderlyData.populationBracketsByLadCode) {
		console.error('Invalid elderly data structure.')
		return { labels: [], datasets: [] }
	}

	if (!totalData || !totalData.populationBracketsByLadCode) {
		console.error('Invalid total data structure.')
		return { labels: [], datasets: [] }
	}

	const elderlyYearsData = elderlyData.populationBracketsByLadCode.years
	const totalYearsData = totalData.populationBracketsByLadCode.years

	if (!elderlyYearsData || elderlyYearsData.length === 0) {
		console.error('No elderly years data available.')
		return { labels: [], datasets: [] }
	}

	if (!totalYearsData || totalYearsData.length === 0) {
		console.error('No total years data available.')
		return { labels: [], datasets: [] }
	}

	// Build a set of years present in both datasets
	const elderlyYearsSet = new Set(elderlyYearsData.map((y) => y.year))
	const totalYearsSet = new Set(totalYearsData.map((y) => y.year))
	const commonYears = Array.from(elderlyYearsSet).filter((year) => totalYearsSet.has(year))
	commonYears.sort((a, b) => a - b)

	if (commonYears.length === 0) {
		console.error('No common years in elderly and total data.')
		return { labels: [], datasets: [] }
	}

	// Select the earliest year, current year, and latest year
	const earliestYear = commonYears[0]
	const currentYear = new Date().getFullYear()
	// Find the year in commonYears closest to the current year
	const closestCurrentYear = commonYears.reduce((prev, curr) =>
		Math.abs(curr - currentYear) < Math.abs(prev - currentYear) ? curr : prev,
	)
	const latestYear = commonYears[commonYears.length - 1]

	const yearsToIncludeSet = new Set([earliestYear, closestCurrentYear, latestYear])
	const yearsToInclude = Array.from(yearsToIncludeSet).sort((a, b) => a - b)

	// Build labels
	const labels = yearsToInclude.map((year) => year.toString())

	// Prepare maps for elderly and total data
	const elderlyDataMap: {
		[geoCode: string]: { [year: number]: { total: number; geoName: string } }
	} = {}
	elderlyYearsData.forEach((yearData) => {
		if (yearsToIncludeSet.has(yearData.year)) {
			yearData.geographies.forEach((geo) => {
				if (!elderlyDataMap[geo.geoCode]) {
					elderlyDataMap[geo.geoCode] = {}
				}
				elderlyDataMap[geo.geoCode][yearData.year] = { total: geo.total, geoName: geo.geoName }
			})
		}
	})

	const totalDataMap: { [geoCode: string]: { [year: number]: number } } = {}
	totalYearsData.forEach((yearData) => {
		if (yearsToIncludeSet.has(yearData.year)) {
			yearData.geographies.forEach((geo) => {
				if (!totalDataMap[geo.geoCode]) {
					totalDataMap[geo.geoCode] = {}
				}
				totalDataMap[geo.geoCode][yearData.year] = geo.total
			})
		}
	})

	// Compute the share for each geography and year
	const datasetsMap: { [geoCode: string]: { id: string; values: (number | null)[] } } = {}

	Object.keys(elderlyDataMap).forEach((geoCode) => {
		const geoElderlyData = elderlyDataMap[geoCode]
		const geoTotalData = totalDataMap[geoCode]
		const values = yearsToInclude.map((year) => {
			const elderlyTotal = geoElderlyData[year]?.total
			const totalTotal = geoTotalData?.[year]
			if (elderlyTotal != null && totalTotal != null && totalTotal !== 0) {
				return parseFloat(((elderlyTotal / totalTotal) * 100).toFixed(2))
			} else {
				return 0 // Replace null with 0 or any default value
			}
		})
		const geoName = Object.values(geoElderlyData)[0]?.geoName || geoCode
		datasetsMap[geoCode] = { id: geoName, values }
	})

	// Convert datasetsMap to array
	const datasets = Object.values(datasetsMap)

	return { labels, datasets }
}

export { ElderlyPopulationShare }
