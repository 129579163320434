import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import {
	StatXAttendanceAllowanceByAwardTimeSeriesQuery as QueryResponseType,
	StatXAttendanceAllowanceByAwardTimeSeriesQuery$data as QueryResponseType$Data,
} from '../graphql/queries/__generated__/StatXAttendanceAllowanceByAwardTimeSeriesQuery.graphql'
import { StatXAttendanceAllowanceByAwardTimeSeriesQuery as QueryRequestType } from '../graphql/queries/StatXAttendanceAllowanceByAwardTimeSeries'
import { BarChart, Model } from '../shared/charts/BarChart'
import { VerticalSpace } from '../shared/layout/Space'
import { Paragraph, Subtitle } from '../shared/Text'

interface AttendanceAllowanceChartProps {
	ladCode: string
}

const AttendanceAllowanceChartByAwardTimeSeries: React.FC<AttendanceAllowanceChartProps> = ({ ladCode }) => {
	const data = useLazyLoadQuery<QueryResponseType>(QueryRequestType, {
		geography: ladCode,
	})

	const { labels, datasets } = transformStatx(data)

	return (
		<>
			<Subtitle>Elderly Population with Care Needs</Subtitle>
			<Paragraph>
				Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
				dolore magna aliquyam erat, sed diam voluptua.
			</Paragraph>
			<VerticalSpace size="sm" />
			<BarChart
				horizontal={false}
				stacked={true}
				model={{
					labels,
					datasets,
				}}
				showLabels={true}
				alpha={0.9}
				aspectRatio={1}
			/>
		</>
	)
}

function transformStatx(data: QueryResponseType$Data): Model {
	if (!data || !data.statx || !data.statx.rows || !data.statx.headers) {
		return { labels: [], datasets: [] }
	}

	const headers = data.statx.headers
	const rows = data.statx.rows

	// Find the indices of the headers we need
	const quarterIndex = headers.indexOf('Quarter')
	const awardTypeIndex = headers.indexOf('AA Award Type')
	const geographyIndex = headers.indexOf('National - Regional - LA - OAs')
	const valueIndex = headers.indexOf('AA (entitled) - 2011 Geographies')

	if (quarterIndex === -1 || awardTypeIndex === -1 || geographyIndex === -1 || valueIndex === -1) {
		console.error('Required headers not found in data.')
		return { labels: [], datasets: [] }
	}

	// Filter data for the first geography (e.g., "Tameside")
	const targetGeography = rows[0][geographyIndex] // Assuming first geography is the one we need

	const filteredRows = rows.filter((row) => row[geographyIndex] === targetGeography)

	// Map award types to 'Full-time' and 'Part-Time'
	const awardTypeMapping = {
		'Higher Rate': 'Full-time',
		'Lower Rate': 'Part-Time',
	}

	// Collect data
	const labelsSet = new Set()
	const dataMap: { [key: string]: { [key: string]: number } } = {}

	filteredRows.forEach((row) => {
		const quarter = row[quarterIndex]
		const awardType = row[awardTypeIndex]
		const mappedAwardType = awardTypeMapping[awardType as keyof typeof awardTypeMapping]

		// Extract year from quarter (e.g., "Feb-19" -> "2019")
		const year = '20' + quarter.split('-')[1]
		labelsSet.add(year)

		const value = parseInt(row[valueIndex])

		if (!dataMap[mappedAwardType]) {
			dataMap[mappedAwardType] = {}
		}
		dataMap[mappedAwardType][year] = value
	})

	// Prepare labels sorted in ascending order
	const labels: string[] = Array.from(labelsSet).map(String).sort()

	// Prepare datasets
	const datasets = Object.keys(dataMap).map((awardType) => {
		const values = labels.map((year) => {
			const value = dataMap[awardType][year]
			return value !== undefined ? value : null
		})
		return {
			id: awardType,
			values: values,
		}
	})

	return {
		labels: labels,
		datasets: datasets,
	}
}

export { AttendanceAllowanceChartByAwardTimeSeries }
