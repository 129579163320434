import React from 'react'

interface SelectProps {
	options: { value: string; label: string }[]
	onChange?: (value: string) => void
	value?: string
}

const Select: React.FC<SelectProps> = ({ options, onChange, value }) => {
	return (
		<div className="input-group" style={{ maxWidth: '300px' }}>
			<select className="form-select" value={value} onChange={(e) => onChange && onChange(e.target.value)}>
				{options.map((option) => (
					<option key={option.value} value={option.value}>
						{option.label}
					</option>
				))}
			</select>
		</div>
	)
}

export { Select }
