/**
 * @generated SignedSource<<fa818e4cbfbe1578e606ac14318ccae3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type HousePriceIndexByLadCodeQuery$variables = {
  ladCode: string;
};
export type HousePriceIndexByLadCodeQuery$data = {
  readonly housePriceIndexByLadCode: {
    readonly ladCode: string;
    readonly ladGrowthRate: number;
    readonly ladYears: ReadonlyArray<{
      readonly averagePrice: number | null | undefined;
      readonly year: number;
    }>;
    readonly nationalGrowthRate: number;
    readonly nationalYears: ReadonlyArray<{
      readonly averagePrice: number | null | undefined;
      readonly year: number;
    }>;
  };
};
export type HousePriceIndexByLadCodeQuery = {
  response: HousePriceIndexByLadCodeQuery$data;
  variables: HousePriceIndexByLadCodeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ladCode"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "year",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "averagePrice",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "ladCode",
        "variableName": "ladCode"
      }
    ],
    "concreteType": "HousePriceIndex",
    "kind": "LinkedField",
    "name": "housePriceIndexByLadCode",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ladCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "HousePriceIndexYear",
        "kind": "LinkedField",
        "name": "ladYears",
        "plural": true,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ladGrowthRate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "HousePriceIndexYear",
        "kind": "LinkedField",
        "name": "nationalYears",
        "plural": true,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "nationalGrowthRate",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "HousePriceIndexByLadCodeQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "HousePriceIndexByLadCodeQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "e5051291a44e7f0eea9647507ca41de0",
    "id": null,
    "metadata": {},
    "name": "HousePriceIndexByLadCodeQuery",
    "operationKind": "query",
    "text": "query HousePriceIndexByLadCodeQuery(\n  $ladCode: String!\n) {\n  housePriceIndexByLadCode(ladCode: $ladCode) {\n    ladCode\n    ladYears {\n      year\n      averagePrice\n    }\n    ladGrowthRate\n    nationalYears {\n      year\n      averagePrice\n    }\n    nationalGrowthRate\n  }\n}\n"
  }
};
})();

(node as any).hash = "326206bc9e235e5dca91e2e8b813a7d3";

export default node;
