import graphql from 'babel-plugin-relay/macro'

export const SitesQuery = graphql`
	query SitesQuery($limit: Int!, $offset: Int!) {
		sites(limit: $limit, offset: $offset) {
			limit
			offset
			hasNextPage
			sites {
				...SitesSiteFragment
			}
		}
	}
`
