import React from 'react'
import { useFragment, useLazyLoadQuery } from 'react-relay'

import { SitesByLadCodeQuery } from '../../graphql/queries/__generated__/SitesByLadCodeQuery.graphql'
import { SitesByLadCodeQuery as QueryRequestType } from '../../graphql/queries/SitesByLadCode'
import { BarChart, Model } from '../../shared/charts/BarChart'
import { VerticalSpace } from '../../shared/layout/Space'
import { ParagraphSmallBold, TitleBox } from '../../shared/Text'
import {
	ElderlyBedsTimeSeriesChartSiteFragment$data,
	ElderlyBedsTimeSeriesChartSiteFragment$key,
} from './__generated__/ElderlyBedsTimeSeriesChartSiteFragment.graphql'
import { ElderlyBedsTimeSeriesChartSiteFragment } from './ElderlyBedsTimeSeriesChartSiteFragment'

interface ElderlyBedsTimeSeriesChartProps {
	ladCode: string
	showTitleWrapper?: boolean
}

const ElderlyBedsTimeSeriesChart: React.FC<ElderlyBedsTimeSeriesChartProps> = ({
	ladCode,
	showTitleWrapper = true,
}) => {
	const sitesData = useLazyLoadQuery<SitesByLadCodeQuery>(QueryRequestType, {
		ladCode: ladCode,
	})

	const sites = sitesData.sitesByLadCode.map((site: ElderlyBedsTimeSeriesChartSiteFragment$key) => {
		return useFragment<ElderlyBedsTimeSeriesChartSiteFragment$key>(ElderlyBedsTimeSeriesChartSiteFragment, site)
	}) as ElderlyBedsTimeSeriesChartSiteFragment$data[]

	const { labels, datasets } = getElderlyBeds(sites)

	return (
		<>
			{showTitleWrapper && (
				<>
					<TitleBox>Elderly Bed Supply</TitleBox>
					<ParagraphSmallBold>Beds in Care Homes for +65 age band</ParagraphSmallBold>
					<VerticalSpace size="sm" />
				</>
			)}
			<BarChart
				horizontal={false}
				stacked={false}
				model={{
					labels,
					datasets,
				}}
				showLabels={true}
				alpha={1}
				aspectRatio={2}
			/>
		</>
	)
}

function getElderlyBeds(sites: ElderlyBedsTimeSeriesChartSiteFragment$data[]): Model {
	// Check if data is available
	if (!sites || !Array.isArray(sites)) {
		return { labels: [], datasets: [] }
	}

	// Define the range of years
	const startYear = 2010
	const endYear = 2024

	// Initialize an object to store the results for each year
	const yearData: { [year: string]: { totalBeds: number } } = {}

	// Initialize yearData for each year
	for (let year = startYear; year <= endYear; year++) {
		yearData[year] = {
			totalBeds: 0,
		}
	}

	// Iterate through each site, filtering for those that specialize in care homes for the elderly
	sites.forEach((site) => {
		const registrationDate = site.registrationDate
		if (!registrationDate) {
			return
		}

		const registrationYear = new Date(registrationDate).getFullYear()

		// Only process if registrationYear is valid and within the range
		if (registrationYear && registrationYear <= endYear) {
			for (let year = registrationYear; year <= endYear; year++) {
				// Increment total number of beds for that year
				yearData[year].totalBeds += site.numberOfBeds || 0
			}
		}
	})

	// Prepare labels and datasets
	const labels: string[] = []
	const totalBedsValues: number[] = []

	for (let year = startYear; year <= endYear; year++) {
		labels.push(year.toString())
		totalBedsValues.push(yearData[year].totalBeds)
	}

	const datasets = [
		{
			id: 'Total Beds',
			values: totalBedsValues,
		},
	]

	return {
		labels: labels,
		datasets: datasets,
	}
}

export { ElderlyBedsTimeSeriesChart }
