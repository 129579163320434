import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { NomisTenureTypesQuery as QueryResponseType } from '../graphql/queries/__generated__/NomisTenureTypesQuery.graphql'
import { NomisTenureTypesQuery } from '../graphql/queries/NomisTenureTypes'
import { PieChart } from '../shared/charts/PieChart'
import { VerticalSpace } from '../shared/layout/Space'
import { Paragraph, Subtitle } from '../shared/Text'
import { nomisDataToPieChart } from '../utils/nomisToPieChart'

interface TenureTypePieChartProps {
	ladCode: string
}

const TenureTypePieChart: React.FC<TenureTypePieChartProps> = ({ ladCode }) => {
	const data = useLazyLoadQuery<QueryResponseType>(NomisTenureTypesQuery, {
		geography: ladCode,
	})

	const model = nomisDataToPieChart(data.nomis, 'Tenure of household', ['Owned', 'Social rented', 'Private rented'])

	return (
		<>
			<Subtitle>Tenure Types</Subtitle>
			<Paragraph>Share of population by type and region</Paragraph>
			<VerticalSpace size="sm" />
			<PieChart model={model} aspectRatio={1} />
		</>
	)
}

export { TenureTypePieChart }
