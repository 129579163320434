/**
 * @generated SignedSource<<f4588d75a725950fbb4fdd33b0c43b9d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SitesByLadCodeQuery$variables = {
  ladCode: string;
};
export type SitesByLadCodeQuery$data = {
  readonly sitesByLadCode: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"ElderlyBedShortagesForecastChartSiteFragment" | "ElderlyBedsForecastSiteFragment" | "ElderlyBedsTimeSeriesChartSiteFragment" | "ElderlyCareForecastSiteFragment" | "ElderlySitesChartSiteFragment" | "ReportSitesByLadQueryFragment" | "SitesTableFragment">;
  }>;
};
export type SitesByLadCodeQuery = {
  response: SitesByLadCodeQuery$data;
  variables: SitesByLadCodeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ladCode"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ladCode",
    "variableName": "ladCode"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SitesByLadCodeQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "sitesByLadCode",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ReportSitesByLadQueryFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SitesTableFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ElderlyBedShortagesForecastChartSiteFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ElderlyBedsForecastSiteFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ElderlySitesChartSiteFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ElderlyCareForecastSiteFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ElderlyBedsTimeSeriesChartSiteFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SitesByLadCodeQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "sitesByLadCode",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "location",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "postalAddressTownCity",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numberOfBeds",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "registrationDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currentRatings",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "specialisms",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "serviceTypes",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "website",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "62da1155ed764f975f2694ff6555a6e2",
    "id": null,
    "metadata": {},
    "name": "SitesByLadCodeQuery",
    "operationKind": "query",
    "text": "query SitesByLadCodeQuery(\n  $ladCode: String!\n) {\n  sitesByLadCode(ladCode: $ladCode) {\n    ...ReportSitesByLadQueryFragment\n    ...SitesTableFragment\n    ...ElderlyBedShortagesForecastChartSiteFragment\n    ...ElderlyBedsForecastSiteFragment\n    ...ElderlySitesChartSiteFragment\n    ...ElderlyCareForecastSiteFragment\n    ...ElderlyBedsTimeSeriesChartSiteFragment\n  }\n}\n\nfragment ElderlyBedShortagesForecastChartSiteFragment on Site {\n  registrationDate\n  numberOfBeds\n}\n\nfragment ElderlyBedsForecastSiteFragment on Site {\n  registrationDate\n  numberOfBeds\n}\n\nfragment ElderlyBedsTimeSeriesChartSiteFragment on Site {\n  registrationDate\n  numberOfBeds\n}\n\nfragment ElderlyCareForecastSiteFragment on Site {\n  registrationDate\n  numberOfBeds\n}\n\nfragment ElderlySitesChartSiteFragment on Site {\n  registrationDate\n  numberOfBeds\n}\n\nfragment ReportSitesByLadQueryFragment on Site {\n  id\n  name\n  location\n}\n\nfragment SitesTableFragment on Site {\n  id\n  name\n  postalAddressTownCity\n  numberOfBeds\n  registrationDate\n  currentRatings\n  specialisms\n  serviceTypes\n  website\n}\n"
  }
};
})();

(node as any).hash = "a8f18a2fe5616181ba4f71523cffa561";

export default node;
