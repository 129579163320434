/**
 * @generated SignedSource<<78f0c8febbe0765b754e23cf45f783f7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ElderlyBedShortagesForecastChartSiteFragment$data = {
  readonly numberOfBeds: number | null | undefined;
  readonly registrationDate: any | null | undefined;
  readonly " $fragmentType": "ElderlyBedShortagesForecastChartSiteFragment";
};
export type ElderlyBedShortagesForecastChartSiteFragment$key = {
  readonly " $data"?: ElderlyBedShortagesForecastChartSiteFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ElderlyBedShortagesForecastChartSiteFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ElderlyBedShortagesForecastChartSiteFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registrationDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numberOfBeds",
      "storageKey": null
    }
  ],
  "type": "Site",
  "abstractKey": null
};

(node as any).hash = "180826b663be5aba147bbf9662965320";

export default node;
