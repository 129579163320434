import React from 'react'
import { useFragment, useLazyLoadQuery } from 'react-relay'

import { SitesByLadCodeQuery as SitesQueryType } from '../../graphql/queries/__generated__/SitesByLadCodeQuery.graphql'
import {
	StatXAttendanceAllowanceByAwardTimeSeriesQuery as AAQueryType,
	StatXAttendanceAllowanceByAwardTimeSeriesQuery$data as AAQueryDataType,
} from '../../graphql/queries/__generated__/StatXAttendanceAllowanceByAwardTimeSeriesQuery.graphql'
import { SitesByLadCodeQuery as SitesQueryRequest } from '../../graphql/queries/SitesByLadCode'
import { StatXAttendanceAllowanceByAwardTimeSeriesQuery as AAQueryRequest } from '../../graphql/queries/StatXAttendanceAllowanceByAwardTimeSeries'
import { LineChart } from '../../shared/charts/LineChart'
import { VerticalSpace } from '../../shared/layout/Space'
import { ParagraphSmallBold, TitleBox } from '../../shared/Text'
import {
	ElderlyBedShortagesForecastChartSiteFragment$data,
	ElderlyBedShortagesForecastChartSiteFragment$key,
} from './__generated__/ElderlyBedShortagesForecastChartSiteFragment.graphql'
import { ElderlyBedShortagesForecastChartSiteFragment } from './ElderlyBedShortagesForecastChartSiteFragment'

interface ChartProps {
	ladCode: string
	showTitleWrapper?: boolean
	yearRange?: string // e.g., '2019-2032'
}

const ElderlyBedShortagesForecastChart: React.FC<ChartProps> = ({
	ladCode,
	showTitleWrapper = true,
	yearRange = '2020-2032',
}) => {
	// Parse the year range
	const { start: displayStartYear, end: displayEndYear } = parseYearRange(yearRange)

	// Fetch data
	const aaData = useLazyLoadQuery<AAQueryType>(AAQueryRequest, {
		geography: ladCode,
	})

	const sitesData = useLazyLoadQuery<SitesQueryType>(SitesQueryRequest, {
		ladCode: ladCode,
	})

	const sites = sitesData.sitesByLadCode.map((site: ElderlyBedShortagesForecastChartSiteFragment$key) => {
		return useFragment(ElderlyBedShortagesForecastChartSiteFragment, site)
	})

	// Process data
	const { labels, datasets } = getBedSupplyAndDemandHistoricalForecast(aaData, sites, displayStartYear, displayEndYear)

	return (
		<>
			{showTitleWrapper && (
				<>
					<TitleBox>Elderly Bed Supply and Demand Forecast</TitleBox>
					<ParagraphSmallBold>
						Regression analysis to forecast based on historic demand (Attendance Allowance Rate) and histroic supply
						data.
					</ParagraphSmallBold>
					<VerticalSpace size="sm" />
				</>
			)}
			<LineChart
				model={{
					labels,
					datasets,
				}}
				aspectRatio={2}
				dataLabelDisplayOption="showForSelectedLabels"
				selectedLabels={['2020', '2024', 'E2028', 'E2032']}
			/>
		</>
	)
}

// Helper function to parse the yearRange prop
function parseYearRange(
	yearRange: string | undefined,
	defaultRange: string = '2019-2032',
): { start: number; end: number } {
	if (yearRange) {
		const parts = yearRange.split('-').map((part) => parseInt(part.trim(), 10))
		if (parts.length === 2 && !isNaN(parts[0]) && !isNaN(parts[1])) {
			return { start: parts[0], end: parts[1] }
		}
	}
	// Default
	const defaultParts = defaultRange.split('-').map((part) => parseInt(part.trim(), 10))
	return { start: defaultParts[0], end: defaultParts[1] }
}

function getBedSupplyAndDemandHistoricalForecast(
	aaData: AAQueryDataType,
	sitesData: ElderlyBedShortagesForecastChartSiteFragment$data[],
	displayStartYear: number,
	displayEndYear: number,
) {
	// Define historical and forecast years
	const bedSupplyHistoricalYears = [2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
	const bedSupplyForecastYears = [2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032]

	const bedDemandHistoricalYears = [2019, 2020, 2021, 2022, 2023, 2024]
	const bedDemandForecastYears = [2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032]

	// Process Bed Demand Data
	const bedDemandHistorical = processBedDemandData(aaData, bedDemandHistoricalYears)
	const bedDemandForecast = forecastData(bedDemandHistorical, bedDemandForecastYears)
	const bedDemandCombined = { ...bedDemandHistorical, ...bedDemandForecast }

	// Process Bed Supply Data
	const bedSupplyHistorical = processBedSupplyData(sitesData, bedSupplyHistoricalYears)
	const bedSupplyForecast = forecastData(bedSupplyHistorical, bedSupplyForecastYears)
	const bedSupplyCombined = { ...bedSupplyHistorical, ...bedSupplyForecast }

	// Combine all years from both datasets
	const allYearsSet = new Set<number>([
		...bedSupplyHistoricalYears,
		...bedSupplyForecastYears,
		...bedDemandHistoricalYears,
		...bedDemandForecastYears,
	])
	const allYears = Array.from(allYearsSet).sort((a, b) => a - b)

	// Calculate Bed Shortage (Supply - Demand)
	const bedShortageCombined: { [year: number]: number } = {}
	allYears.forEach((year) => {
		const supply = bedSupplyCombined[year] || 0
		const demand = bedDemandCombined[year] || 0
		bedShortageCombined[year] = supply - demand
	})

	// Determine which years are forecasted
	const forecastYearSet = new Set<number>([...bedSupplyForecastYears, ...bedDemandForecastYears])

	// Apply display range filter
	const filteredYears = allYears.filter((year) => year >= displayStartYear && year <= displayEndYear)

	// Prepare labels with 'E' prefix for forecasted years
	const labels = filteredYears.map((year) => (forecastYearSet.has(year) ? `E${year}` : `${year}`))

	// Prepare datasets
	const datasets = [
		{
			id: 'Bed Demand',
			values: filteredYears.map((year) => bedDemandCombined[year] || null),
			color: 'rgba(255, 99, 132, 1)', // Red
		},
		{
			id: 'Bed Supply',
			values: filteredYears.map((year) => bedSupplyCombined[year] || null),
			color: 'rgba(54, 162, 235, 1)', // Blue
		},
		{
			id: 'Bed Shortage',
			values: filteredYears.map((year) => bedShortageCombined[year] || null),
			color: 'rgba(255, 206, 86, 1)', // Yellow
		},
	]

	return {
		labels,
		datasets,
	}
}

function processBedDemandData(aaData: AAQueryDataType, yearsOfInterest: number[]): { [year: number]: number } {
	if (!aaData || !aaData.statx || !aaData.statx.rows || !aaData.statx.headers) {
		return {}
	}

	const headers = aaData.statx.headers
	const rows = aaData.statx.rows

	// Find indices
	const quarterIndex = headers.indexOf('Quarter')
	const awardTypeIndex = headers.indexOf('AA Award Type')
	const geographyIndex = headers.indexOf('National - Regional - LA - OAs')
	const valueIndex = headers.indexOf('AA (entitled) - 2011 Geographies')

	if (quarterIndex === -1 || awardTypeIndex === -1 || geographyIndex === -1 || valueIndex === -1) {
		console.error('Required headers not found in data.')
		return {}
	}

	// Filter for target geography and Higher Rate (Full-time)
	const targetGeography = rows[0][geographyIndex]
	const filteredRows = rows.filter(
		(row) => row[geographyIndex] === targetGeography && row[awardTypeIndex] === 'Higher Rate',
	)

	// Collect data for years of interest
	const yearValueMap: { [year: number]: number } = {}
	yearsOfInterest.forEach((year) => {
		yearValueMap[year] = 0
	})

	filteredRows.forEach((row) => {
		const quarter = row[quarterIndex] as string // e.g., "Feb-19"
		const year = parseInt('20' + quarter.split('-')[1], 10) // e.g., "2019" -> 2019
		const value = parseInt(row[valueIndex] as string, 10)

		if (yearsOfInterest.includes(year)) {
			// Sum values per year
			yearValueMap[year] += value
		}
	})

	return yearValueMap
}

function processBedSupplyData(
	sites: ElderlyBedShortagesForecastChartSiteFragment$data[],
	yearsOfInterest: number[],
): { [year: number]: number } {
	if (!sites || !Array.isArray(sites)) {
		return {}
	}

	// Initialize yearBedMap for years of interest
	const yearBedMap: { [year: number]: number } = {}
	yearsOfInterest.forEach((year) => {
		yearBedMap[year] = 0
	})

	// Filter and process sites
	sites.forEach((site) => {
		const registrationDate = site.registrationDate
		if (!registrationDate) {
			return
		}

		const registrationYear = new Date(registrationDate).getFullYear()

		// Add beds to each year from registration year onwards within years of interest
		yearsOfInterest.forEach((year) => {
			if (registrationYear <= year) {
				yearBedMap[year] += site.numberOfBeds || 0
			}
		})
	})

	return yearBedMap
}

function forecastData(historicalData: { [year: number]: number }, forecastYears: number[]): { [year: number]: number } {
	const years = Object.keys(historicalData).map((yearStr) => parseInt(yearStr, 10))
	const values = years.map((year) => historicalData[year])

	if (years.length === 0) {
		console.error('No historical data available for forecasting.')
		// Return zeros for forecast years
		const forecastData: { [year: number]: number } = {}
		forecastYears.forEach((year) => {
			forecastData[year] = 0
		})
		return forecastData
	}

	// Perform linear regression
	const regressionResult = linearRegression(years, values)

	// Predict values for forecast years
	const forecastData: { [year: number]: number } = {}
	forecastYears.forEach((year) => {
		forecastData[year] = Math.round(regressionResult.slope * year + regressionResult.intercept)
	})

	return forecastData
}

// Simple linear regression function
function linearRegression(x: number[], y: number[]) {
	const n = x.length
	const sumX = x.reduce((a, b) => a + b, 0)
	const sumY = y.reduce((a, b) => a + b, 0)
	const sumXY = x.reduce((sum, xi, idx) => sum + xi * y[idx], 0)
	const sumX2 = x.reduce((sum, xi) => sum + xi * xi, 0)

	const denominator = n * sumX2 - sumX * sumX
	if (denominator === 0) {
		throw new Error('Denominator in linear regression calculation is zero.')
	}

	const slope = (n * sumXY - sumX * sumY) / denominator
	const intercept = (sumY - slope * sumX) / n

	return { slope, intercept }
}

export { ElderlyBedShortagesForecastChart }
