import React from 'react'

import { LadGDHITimeSeriesChart } from '../../../../charts/GDHITimeSeries/LadGDHITimeSeries'
import { LadHourlyWagesChart } from '../../../../charts/HourlyWagesChart/LadHourlyWagesChart'
import { HouseholdsWithUniversalCreditChart } from '../../../../charts/HouseholdsWithUniversalCreditChart'
import { HouseholdsWithUniversalCreditWorkingPopulationRatio } from '../../../../charts/HouseholdsWithUniversalCreditWorkingPopulationRatio'
import { IndustryDistributionChart } from '../../../../charts/IndustryDistributionChart'
import { LadUnemploymentRateChart } from '../../../../charts/UnemploymentRateChart/LadUnemploymentRateChart'
import { LeftAligned } from '../../../../shared/layout/Alignment'
import { Col, Container, Row } from '../../../../shared/layout/Grid'
import { HorizontalSpace, VerticalSpace } from '../../../../shared/layout/Space'
import { Loading } from '../../../../shared/Loading'
import { Panel } from '../../../../shared/Panel'
import { SubtitleBox } from '../../../../shared/Text'
import { AIEconomicSummary } from './AIEconomicSummary'

interface EconomicProps {
	ladCode: string
}

const Economic: React.FC<EconomicProps> = ({ ladCode }) => {
	return (
		<Container>
			<VerticalSpace size="xl" />
			<Row>
				<LeftAligned>
					<HorizontalSpace size="md" />
					<Col count={7}>
						<SubtitleBox>Economic Report</SubtitleBox>
						<AIEconomicSummary ladCode={ladCode} />
					</Col>
				</LeftAligned>
			</Row>
			<VerticalSpace size="xl" />
			<Row>
				<Col count={12}>
					<Panel>
						<LadGDHITimeSeriesChart ladCode={ladCode} />
					</Panel>
				</Col>
			</Row>
			<VerticalSpace size="md" />
			<Row>
				<Col count={6}>
					<Panel>
						<LadHourlyWagesChart ladCode={ladCode} />
					</Panel>
				</Col>
				<Col count={6}>
					<Panel>
						<LadUnemploymentRateChart ladCode={ladCode} />
					</Panel>
				</Col>
			</Row>
			<VerticalSpace size="md" />
			<Row>
				<Col count={6}>
					<Panel>
						<HouseholdsWithUniversalCreditChart ladCode={ladCode} />
					</Panel>
				</Col>
				<Col count={6}>
					<Panel>
						<HouseholdsWithUniversalCreditWorkingPopulationRatio ladCode={ladCode} />
					</Panel>
				</Col>
			</Row>
			<VerticalSpace size="md" />
			<Row>
				<Col count={12}>
					<Panel>
						<IndustryDistributionChart ladCode={ladCode} />
					</Panel>
				</Col>
			</Row>
		</Container>
	)
}

const Suspended: React.FC<EconomicProps> = ({ ladCode }) => {
	return (
		<React.Suspense fallback={<Loading />}>
			<Economic ladCode={ladCode} />
		</React.Suspense>
	)
}

export { Suspended as Economic }
