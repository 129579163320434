import graphql from 'babel-plugin-relay/macro'

export const StatXAttendanceAllowanceCountTimeSeriesQuery = graphql`
	query StatXAttendanceAllowanceCountTimeSeriesQuery($geography: String!) {
		statx(
			query: {
				database: "AA_Entitled_New"
				table: "V_F_AA_Entitled_New"
				geography: $geography
				measures: [{ key: "count", value: "V_F_AA_Entitled_New" }]
				dimensions: [
					{
						field: "F_AA_QTR_New:DATE_NAME"
						values: [
							"C_AA_QTR_New:202002"
							"C_AA_QTR_New:202102"
							"C_AA_QTR_New:202202"
							"C_AA_QTR_New:202302"
							"C_AA_QTR_New:202402"
						]
						total: false
					}
				]
			}
		) {
			headers
			rows
			title
			url
		}
	}
`
