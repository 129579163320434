import graphql from 'babel-plugin-relay/macro'

export const NomisGDHITimeSeriesQuery = graphql`
	query NomisGDHITimeSeriesQuery($geography: String!) {
		nomis(
			query: {
				dataset: "NM_185_1"
				geography: $geography
				parents: true
				measures: "20100"
				time: "first,latest"
				query: "component_of_gdhi=0&measure=2"
				useLabels: false
			}
		) {
			metadata {
				id
				label
				type
			}
			rows {
				date
				dimensions
				geography
				measures
				value
			}
			title
			url
		}
	}
`
