import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { GeographiesByCodesQuery as GeographiesByCodesResponse } from '../../graphql/queries/__generated__/GeographiesByCodesQuery.graphql'
import {
	HourlyWagesByCaQuery as HourlyWagesResponse,
	RadiusValues,
} from '../../graphql/queries/__generated__/HourlyWagesByCaQuery.graphql'
import { GeographiesByCodesQuery as GeographiesByCodesRequest } from '../../graphql/queries/GeographiesByCodes'
import { HourlyWagesByCaQuery as HourlyWagesRequest } from '../../graphql/queries/HourlyWagesByCa'
import { Model } from '../../shared/charts/LineChart'
import { HourlyWagesChart } from './HourlyWagesChart'

interface CaHourlyWagesChartProps {
	oaCode?: string
	radius: RadiusValues
}

const CaHourlyWagesChart: React.FC<CaHourlyWagesChartProps> = ({ oaCode, radius }) => {
	if (!oaCode) {
		return null
	}

	const { hourlyWagesByCa: { labels = [], datasets = [] } = {} } = useLazyLoadQuery<HourlyWagesResponse>(
		HourlyWagesRequest,
		{ oaCode, radius },
	)

	const codes = datasets.map((d) => d.code)

	const { geographiesByCodes: geos = [] } = useLazyLoadQuery<GeographiesByCodesResponse>(GeographiesByCodesRequest, {
		codes,
	})

	const model: Model = {
		labels: [...labels],
		datasets: datasets.map((d) => ({
			id: geos.find((g) => g.code === d.code)?.name || 'Catchment Area',
			values: [...d.values],
		})),
	}

	return <HourlyWagesChart model={model} />
}

export { CaHourlyWagesChart }
