/**
 * @generated SignedSource<<b169b57716d11c568df493ba5871b08b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ElderlyBedsForecastSiteFragment$data = {
  readonly numberOfBeds: number | null | undefined;
  readonly registrationDate: any | null | undefined;
  readonly " $fragmentType": "ElderlyBedsForecastSiteFragment";
};
export type ElderlyBedsForecastSiteFragment$key = {
  readonly " $data"?: ElderlyBedsForecastSiteFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ElderlyBedsForecastSiteFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ElderlyBedsForecastSiteFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registrationDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numberOfBeds",
      "storageKey": null
    }
  ],
  "type": "Site",
  "abstractKey": null
};

(node as any).hash = "9204ec3f5e243244e23b98a6cb40a527";

export default node;
