import graphql from 'babel-plugin-relay/macro'

export const NomisTenureTypesQuery = graphql`
	query NomisTenureTypesQuery($geography: String!) {
		nomis(
			query: {
				dataset: "NM_2072_1"
				geography: $geography
				parents: true
				time: "latest"
				measures: "20301"
				useLabels: false
			}
		) {
			metadata {
				id
				label
				type
			}
			rows {
				date
				dimensions
				geography
				measures
				value
			}
			title
			url
		}
	}
`
