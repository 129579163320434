import graphql from 'babel-plugin-relay/macro'

export const MedianAgeByLadCodeQuery = graphql`
	query MedianAgeByLadCodeQuery($ladCode: String!, $years: Int!) {
		medianAgeByLadCode(ladCode: $ladCode, years: $years) {
			ladCode
			ladGrowthRate
			ladAge
			ladFutureAge
			nationalGrowthRate
			nationalAge
			nationalFutureAge
			currentYear
			futureYear
		}
	}
`
