import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import {
	PopulationBracketsByLadCodeQuery as PopulationQueryType,
	PopulationBracketsByLadCodeQuery$data as PopulationQueryDataType,
} from '../graphql/queries/__generated__/PopulationBracketsByLadCodeQuery.graphql'
import {
	StatXAttendanceAllowanceCountTimeSeriesQuery as AttendanceQueryType,
	StatXAttendanceAllowanceCountTimeSeriesQuery$data as AttendanceQueryDataType,
} from '../graphql/queries/__generated__/StatXAttendanceAllowanceCountTimeSeriesQuery.graphql'
import { PopulationBracketsByLadCodeQuery } from '../graphql/queries/PopulationBrackets'
import { StatXAttendanceAllowanceCountTimeSeriesQuery } from '../graphql/queries/StatXAttendanceAllowanceCountTimeSeries'
import { LineChart } from '../shared/charts/LineChart'
import { VerticalSpace } from '../shared/layout/Space'
import { Paragraph, Subtitle } from '../shared/Text'

interface ElderlyCareNeedPrevalenceRateChartProps {
	ladCode: string
}

const ElderlyCareNeedPrevalenceRateChart: React.FC<ElderlyCareNeedPrevalenceRateChartProps> = ({ ladCode }) => {
	const populationData = useLazyLoadQuery<PopulationQueryType>(PopulationBracketsByLadCodeQuery, {
		ladCode: ladCode,
		bracket: '65-1000',
		yearsPast: 5,
		yearsFuture: 0,
	})

	const attendanceData = useLazyLoadQuery<AttendanceQueryType>(StatXAttendanceAllowanceCountTimeSeriesQuery, {
		geography: ladCode,
	})

	const { labels, datasets } = transformElderlyCareNeedPrevalenceRateData(populationData, attendanceData)

	return (
		<>
			<Subtitle>Care Need Prevalence Rate</Subtitle>
			<Paragraph>Share of elderly with severe disabilities that need care assistance at least once per day</Paragraph>
			<VerticalSpace size="sm" />
			<LineChart
				model={{
					labels,
					datasets,
				}}
				prefix=""
				suffix="%"
				dataLabelDisplayOption="showAll"
				aspectRatio={1}
			/>
		</>
	)
}

function transformElderlyCareNeedPrevalenceRateData(
	populationData: PopulationQueryDataType,
	attendanceData: AttendanceQueryDataType,
): { labels: string[]; datasets: { id: string; values: (number | null)[] }[] } {
	// ... [Validation code]

	// Extract the necessary headers and indices
	const attendanceHeaders = attendanceData.statx.headers
	const dateIndex = attendanceHeaders.indexOf('Quarter')
	const geographyNameIndex = attendanceHeaders.indexOf('National - Regional - LA - OAs')
	const valueIndex = attendanceHeaders.indexOf('AA (entitled) - 2011 Geographies')

	if (dateIndex === -1 || geographyNameIndex === -1 || valueIndex === -1) {
		console.error('Required headers not found in attendance data.')
		return { labels: [], datasets: [] }
	}

	// Define the years you're interested in
	const yearsRange = [2020, 2021, 2022, 2023, 2024]
	const yearsSet = new Set(yearsRange)

	// Prepare the attendance years set
	const attendanceYearsSet = new Set(
		attendanceData.statx.rows.map((row) => {
			const quarter = row[dateIndex]
			const year = 2000 + parseInt(quarter.split('-')[1])
			return year
		}),
	)

	// Determine the common years
	const commonYears = yearsRange.filter((year) => yearsSet.has(year) && attendanceYearsSet.has(year))

	if (commonYears.length === 0) {
		console.error('No common years in population and attendance data.')
		return { labels: [], datasets: [] }
	}

	// Build the population data map using geoName
	const populationDataMap: {
		[geoName: string]: { [year: number]: number }
	} = {}

	populationData.populationBracketsByLadCode.years.forEach((yearData) => {
		if (yearsSet.has(yearData.year)) {
			yearData.geographies.forEach((geo) => {
				const geoName = geo.geoName
				if (!populationDataMap[geoName]) {
					populationDataMap[geoName] = {}
				}
				populationDataMap[geoName][yearData.year] = geo.total
			})
		}
	})

	// Build the attendance data map using geoName
	const attendanceDataMap: {
		[geoName: string]: { [year: number]: number }
	} = {}

	attendanceData.statx.rows.forEach((row) => {
		const quarter = row[dateIndex]
		const year = 2000 + parseInt(quarter.split('-')[1])
		if (yearsSet.has(year)) {
			const geoName = row[geographyNameIndex]
			const value = parseFloat(row[valueIndex])
			if (!attendanceDataMap[geoName]) {
				attendanceDataMap[geoName] = {}
			}
			attendanceDataMap[geoName][year] = value
		}
	})

	// Get the list of geographies
	const geographies = Array.from(new Set([...Object.keys(populationDataMap), ...Object.keys(attendanceDataMap)]))

	// Compute the prevalence rates
	const datasets = geographies.map((geoName) => {
		const values = commonYears.map((year) => {
			const populationTotal = populationDataMap[geoName]?.[year]
			const attendanceTotal = attendanceDataMap[geoName]?.[year]
			if (populationTotal != null && attendanceTotal != null && populationTotal !== 0) {
				return parseFloat(((attendanceTotal / populationTotal) * 100).toFixed(2))
			} else {
				return null
			}
		})
		return { id: geoName, values }
	})

	const labels = commonYears.map((year) => year.toString())

	return { labels, datasets }
}

export { ElderlyCareNeedPrevalenceRateChart }
