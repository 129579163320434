import React from 'react'
import { useFragment, useLazyLoadQuery } from 'react-relay'

import {
	PopulationBracketsByLadCodeQuery as PopulationQueryType,
	PopulationBracketsByLadCodeQuery$data as PopulationQueryDataType,
} from '../../graphql/queries/__generated__/PopulationBracketsByLadCodeQuery.graphql'
import { SitesByLadCodeQuery as SitesQueryType } from '../../graphql/queries/__generated__/SitesByLadCodeQuery.graphql'
import {
	StatXAttendanceAllowanceByAwardTimeSeriesQuery as AAQueryType,
	StatXAttendanceAllowanceByAwardTimeSeriesQuery$data as AAQueryDataType,
} from '../../graphql/queries/__generated__/StatXAttendanceAllowanceByAwardTimeSeriesQuery.graphql'
import { PopulationBracketsByLadCodeQuery as PopulationQueryRequest } from '../../graphql/queries/PopulationBrackets'
import { SitesByLadCodeQuery as SitesQueryRequest } from '../../graphql/queries/SitesByLadCode'
import { StatXAttendanceAllowanceByAwardTimeSeriesQuery as AAQueryRequest } from '../../graphql/queries/StatXAttendanceAllowanceByAwardTimeSeries'
import { LineChart } from '../../shared/charts/LineChart'
import { VerticalSpace } from '../../shared/layout/Space'
import { ParagraphSmallBold, TitleBox } from '../../shared/Text'
import {
	ElderlyCareForecastSiteFragment$data,
	ElderlyCareForecastSiteFragment$key,
} from './__generated__/ElderlyCareForecastSiteFragment.graphql'
import { ElderlyCareForecastSiteFragment } from './ElderlyCareForecastSiteFragment'

interface ChartProps {
	ladCode: string
	showTitleWrapper?: boolean
	yearRange?: string // e.g., '2019-2032'
}

const ElderlyCareForecast: React.FC<ChartProps> = ({ ladCode, showTitleWrapper = true, yearRange = '2020-2032' }) => {
	// Parse the year range
	const { start: displayStartYear, end: displayEndYear } = parseYearRange(yearRange, '2020-2032')

	// Fetch data
	const aaData = useLazyLoadQuery<AAQueryType>(AAQueryRequest, {
		geography: ladCode,
	})

	const sitesData = useLazyLoadQuery<SitesQueryType>(SitesQueryRequest, {
		ladCode: ladCode,
	})

	const sites = sitesData.sitesByLadCode.map((site: ElderlyCareForecastSiteFragment$key) => {
		return useFragment(ElderlyCareForecastSiteFragment, site)
	})

	const populationData = useLazyLoadQuery<PopulationQueryType>(PopulationQueryRequest, {
		ladCode: ladCode,
		bracket: '65-1000',
		yearsPast: 10,
		yearsFuture: 10,
	})

	// Process data
	const { labels, datasets } = getBedSupplyAndDemandHistoricalForecast(
		aaData,
		sites,
		populationData,
		displayStartYear,
		displayEndYear,
	)

	return (
		<>
			{showTitleWrapper && (
				<>
					<TitleBox>Elderly Bed Supply and Demand Forecast</TitleBox>
					<ParagraphSmallBold>
						Regression-analysis based forecasting factoring in the predicted elderly growth rates from the Census Data.
						Our predicitve algorithm calculates historic ratios of demand (Attendance Allowance Higher Rate) versus the
						elderly population and forecasts future ratios using a function derived from the regression analysis. On the
						supply side we observe historic growth of bed supply and use the historic function to suggest potential
						future supply.
					</ParagraphSmallBold>
					<VerticalSpace size="sm" />
				</>
			)}
			<LineChart
				model={{
					labels,
					datasets,
				}}
				aspectRatio={1.5}
				dataLabelDisplayOption="showForSelectedLabels"
				selectedLabels={['2020', '2024', 'E2028', 'E2032']}
			/>
		</>
	)
}

// Helper function to parse the yearRange prop
function parseYearRange(
	yearRange: string | undefined,
	defaultRange: string = '2019-2032',
): { start: number; end: number } {
	if (yearRange) {
		const parts = yearRange.split('-').map((part) => parseInt(part.trim(), 10))
		if (parts.length === 2 && !isNaN(parts[0]) && !isNaN(parts[1])) {
			return { start: parts[0], end: parts[1] }
		}
	}
	// Default
	const defaultParts = defaultRange.split('-').map((part) => parseInt(part.trim(), 10))
	return { start: defaultParts[0], end: defaultParts[1] }
}

function getBedSupplyAndDemandHistoricalForecast(
	aaData: AAQueryDataType,
	sitesData: ElderlyCareForecastSiteFragment$data[],
	populationData: PopulationQueryDataType,
	displayStartYear: number,
	displayEndYear: number,
) {
	// Define historical and forecast years
	const bedSupplyHistoricalYears = [2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]
	const bedSupplyForecastYears = [2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032]

	const bedDemandHistoricalYears = [2019, 2020, 2021, 2022, 2023, 2024]
	const bedDemandForecastYears = [2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032]

	// Process Bed Demand Data (Direct Method)
	const bedDemandHistorical = processBedDemandData(aaData, bedDemandHistoricalYears)

	const bedDemandForecast = forecastData(bedDemandHistorical, bedDemandForecastYears)

	const bedDemandCombined = { ...bedDemandHistorical, ...bedDemandForecast }

	// Process Bed Supply Data
	const bedSupplyHistorical = processBedSupplyData(sitesData, bedSupplyHistoricalYears)

	const bedSupplyForecast = forecastData(bedSupplyHistorical, bedSupplyForecastYears)

	const bedSupplyCombined = { ...bedSupplyHistorical, ...bedSupplyForecast }

	// Process Elderly Population Data
	const elderlyPopulation = transformElderlyPopulationData(populationData, 2019, 2032)

	const elderlyPopulationCombined = elderlyPopulation

	// Calculate Bed Shortage (Supply - Demand Direct)
	const bedShortageCombinedDirect: { [year: number]: number } = {}
	Object.keys(bedSupplyCombined).forEach((yearStr) => {
		const year = parseInt(yearStr, 10)
		const supply = bedSupplyCombined[year] || 0
		const demand = bedDemandCombined[year] || 0
		bedShortageCombinedDirect[year] = supply - demand
	})

	// Calculate Bed Demand Ratio (Bed Demand / Elderly Population) for Historical Years
	const bedDemandRatioHistorical = calculateBedDemandRatio(
		bedDemandHistorical,
		elderlyPopulationCombined,
		bedDemandHistoricalYears,
	)

	// Forecast Bed Demand Ratio for Forecast Years
	const bedDemandRatioForecast = forecastData(bedDemandRatioHistorical, bedDemandForecastYears)

	const bedDemandRatioCombined = { ...bedDemandRatioHistorical, ...bedDemandRatioForecast }

	// Forecast Bed Demand based on Ratio and Forecasted Elderly Population
	const bedDemandRatioBasedForecast: { [year: number]: number } = {}

	// **Include Historical Years**: Set Bed Demand Ratio-Based Bed Demand same as Bed Demand Direct
	bedDemandHistoricalYears.forEach((year) => {
		bedDemandRatioBasedForecast[year] = bedDemandCombined[year]
	})

	// **Forecast Future Years**: Calculate based on forecasted ratio and population
	bedDemandForecastYears.forEach((year) => {
		const ratio = bedDemandRatioCombined[year]
		const population = elderlyPopulationCombined[year]
		if (ratio && population) {
			bedDemandRatioBasedForecast[year] = Math.round(ratio * population)
		} else {
			bedDemandRatioBasedForecast[year] = 0
			if (!ratio) {
				console.warn(`Ratio for year ${year} is zero or undefined.`)
			}
			if (!population) {
				console.warn(`Population for year ${year} is zero or undefined.`)
			}
		}
	})

	// Calculate Bed Shortage (Supply - Demand Ratio-Based)
	const bedShortageCombinedRatio: { [year: number]: number } = {}
	Object.keys(bedSupplyCombined).forEach((yearStr) => {
		const year = parseInt(yearStr, 10)
		const supply = bedSupplyCombined[year] || 0
		const demandRatioBased = bedDemandRatioBasedForecast[year] || 0
		bedShortageCombinedRatio[year] = supply - demandRatioBased
	})

	// Combine all years from Bed Supply, Bed Demand, and Population
	const allYearsSet = new Set<number>([
		...bedSupplyHistoricalYears,
		...bedSupplyForecastYears,
		...bedDemandHistoricalYears,
		...bedDemandForecastYears,
		...Object.keys(elderlyPopulationCombined).map((yearStr) => parseInt(yearStr, 10)),
	])
	const allYears = Array.from(allYearsSet).sort((a, b) => a - b)

	// Apply display range filter
	const filteredYears = allYears.filter((year) => year >= displayStartYear && year <= displayEndYear)

	// Determine which years are forecasted
	const forecastYearSet = new Set<number>(bedSupplyForecastYears.concat(bedDemandForecastYears))

	// Prepare labels with 'E' prefix for forecasted years
	const labels = filteredYears.map((year) => (forecastYearSet.has(year) ? `E${year}` : `${year}`))

	// Prepare datasets
	const datasets = [
		{
			id: 'Bed Demand (Direct)',
			values: filteredYears.map((year) => {
				const value = bedDemandCombined[year] || null
				if (value === null) {
					console.warn(`Bed Demand (Direct) for year ${year} is null.`)
				}
				return value
			}),
			color: 'rgba(255, 99, 132, 1)', // Red
		},
		{
			id: 'Bed Demand (Ratio-Based)',
			values: filteredYears.map((year) => {
				const value = bedDemandRatioBasedForecast[year] || null
				if (value === null || value === 0) {
					console.warn(`Bed Demand (Ratio-Based) for year ${year} is null or zero.`)
				}
				return value
			}),
			color: 'rgba(255, 159, 64, 1)', // Orange
		},
		{
			id: 'Bed Supply',
			values: filteredYears.map((year) => {
				const value = bedSupplyCombined[year] || null
				if (value === null) {
					console.warn(`Bed Supply for year ${year} is null.`)
				}
				return value
			}),
			color: 'rgba(54, 162, 235, 1)', // Blue
		},
		{
			id: 'Bed Shortage (Direct)',
			values: filteredYears.map((year) => {
				const value = bedShortageCombinedDirect[year] || null
				if (value === null) {
					console.warn(`Bed Shortage (Direct) for year ${year} is null.`)
				}
				return value
			}),
			color: 'rgba(75, 192, 192, 1)', // Green
		},
		{
			id: 'Bed Shortage (Ratio-Based)',
			values: filteredYears.map((year) => {
				const value = bedShortageCombinedRatio[year] || null
				if (value === null) {
					console.warn(`Bed Shortage (Ratio-Based) for year ${year} is null.`)
				}
				return value
			}),
			color: 'rgba(153, 102, 255, 1)', // Purple
		},
	]

	return {
		labels,
		datasets: datasets.map((dataset) => ({
			...dataset,
			values: dataset.values.map((value) => (value !== null ? Math.round(value) : value)),
		})),
	}
}

function processBedDemandData(aaData: AAQueryDataType, yearsOfInterest: number[]): { [year: number]: number } {
	if (!aaData || !aaData.statx || !aaData.statx.rows || !aaData.statx.headers) {
		return {}
	}

	const headers = aaData.statx.headers
	const rows = aaData.statx.rows

	// Find indices
	const quarterIndex = headers.indexOf('Quarter')
	const awardTypeIndex = headers.indexOf('AA Award Type')
	const geographyIndex = headers.indexOf('National - Regional - LA - OAs')
	const valueIndex = headers.indexOf('AA (entitled) - 2011 Geographies')

	if (quarterIndex === -1 || awardTypeIndex === -1 || geographyIndex === -1 || valueIndex === -1) {
		console.error('Required headers not found in data.')
		return {}
	}

	// Filter for target geography and Higher Rate (Full-time)
	const targetGeography = rows[0][geographyIndex]
	const filteredRows = rows.filter(
		(row) => row[geographyIndex] === targetGeography && row[awardTypeIndex] === 'Higher Rate',
	)

	// Collect data for years of interest
	const yearValueMap: { [year: number]: number } = {}
	yearsOfInterest.forEach((year) => {
		yearValueMap[year] = 0
	})

	filteredRows.forEach((row) => {
		const quarter = row[quarterIndex] as string // e.g., "Feb-19"
		const yearSuffix = quarter.split('-')[1]
		const year = parseInt('20' + yearSuffix, 10) // e.g., "19" -> 2019
		const value = parseInt(row[valueIndex] as string, 10)

		if (yearsOfInterest.includes(year)) {
			// Sum values per year
			yearValueMap[year] += value
		}
	})

	return yearValueMap
}

function processBedSupplyData(
	sites: ElderlyCareForecastSiteFragment$data[],
	yearsOfInterest: number[],
): { [year: number]: number } {
	if (!sites || !Array.isArray(sites)) {
		return {}
	}

	// Initialize yearBedMap for years of interest
	const yearBedMap: { [year: number]: number } = {}
	yearsOfInterest.forEach((year) => {
		yearBedMap[year] = 0
	})

	// Filter and process sites
	sites.forEach((site) => {
		const registrationDate = site.registrationDate
		if (!registrationDate) {
			return
		}

		const registrationYear = new Date(registrationDate).getFullYear()

		// Add beds to each year from registration year onwards within years of interest
		yearsOfInterest.forEach((year) => {
			if (registrationYear <= year) {
				yearBedMap[year] += site.numberOfBeds || 0
			}
		})
	})

	return yearBedMap
}

function transformElderlyPopulationData(
	data: PopulationQueryDataType,
	startYear: number,
	endYear: number,
): { [year: number]: number } {
	if (!data || !data.populationBracketsByLadCode) {
		return {}
	}

	const yearsData = data.populationBracketsByLadCode.years

	if (!yearsData || yearsData.length === 0) {
		console.error('No years data available.')
		return {}
	}

	const yearValueMap: { [year: number]: number } = {}

	// Populate yearValueMap with all years from startYear to endYear
	for (let year = startYear; year <= endYear; year++) {
		const yearData = yearsData.find((y) => y.year === year)
		if (yearData) {
			const geoData = yearData.geographies.find((g) => g.geoCode === data.populationBracketsByLadCode.ladCode)
			if (geoData) {
				yearValueMap[year] = geoData.total
			} else {
				console.warn(
					`No population data found for geography code: ${data.populationBracketsByLadCode.ladCode} in year ${year}`,
				)
				yearValueMap[year] = 0
			}
		} else {
			console.warn(`No population data found for year: ${year}`)
			yearValueMap[year] = 0
		}
	}

	return yearValueMap
}

function calculateBedDemandRatio(
	bedDemand: { [year: number]: number },
	elderlyPopulation: { [year: number]: number },
	yearsOfInterest: number[],
): { [year: number]: number } {
	const ratioMap: { [year: number]: number } = {}
	yearsOfInterest.forEach((year) => {
		const demand = bedDemand[year]
		const population = elderlyPopulation[year]
		if (demand > 0 && population > 0) {
			ratioMap[year] = demand / population
		} else {
			ratioMap[year] = 0
			if (demand === 0) {
				console.warn(`Bed Demand for year ${year} is zero.`)
			}
			if (population === 0) {
				console.warn(`Elderly Population for year ${year} is zero.`)
			}
		}
	})
	return ratioMap
}

function forecastData(historicalData: { [year: number]: number }, forecastYears: number[]): { [year: number]: number } {
	const years = Object.keys(historicalData).map((yearStr) => parseInt(yearStr, 10))
	const values = years.map((year) => historicalData[year])

	if (years.length === 0) {
		console.error('No historical data available for forecasting.')
		// Return zeros for forecast years
		const forecastDataResult: { [year: number]: number } = {}
		forecastYears.forEach((year) => {
			forecastDataResult[year] = 0
		})
		return forecastDataResult
	}

	// Check if all historical values are zero
	const allZero = values.every((val) => val === 0)
	if (allZero) {
		console.warn('All historical data values are zero. Forecasted values will also be zero.')
		const forecastDataResult: { [year: number]: number } = {}
		forecastYears.forEach((year) => {
			forecastDataResult[year] = 0
		})
		return forecastDataResult
	}

	// Perform linear regression
	let regressionResult: { slope: number; intercept: number } = { slope: 0, intercept: 0 }
	try {
		regressionResult = linearRegression(years, values)
	} catch (error) {
		console.error('Error during linear regression:', error)
		// Return zeros for forecast years
		const forecastDataResult: { [year: number]: number } = {}
		forecastYears.forEach((year) => {
			forecastDataResult[year] = 0
		})
		return forecastDataResult
	}

	// Predict values for forecast years
	const forecastDataResult: { [year: number]: number } = {}
	forecastYears.forEach((year) => {
		const predictedValue = regressionResult.slope * year + regressionResult.intercept
		if (isNaN(predictedValue) || predictedValue < 0) {
			console.warn(`Predicted value for year ${year} is invalid (${predictedValue}). Setting to 0.`)
			forecastDataResult[year] = 0
		} else {
			forecastDataResult[year] = predictedValue
		}
	})

	return forecastDataResult
}

// Simple linear regression function
function linearRegression(x: number[], y: number[]) {
	const n = x.length
	const sumX = x.reduce((a, b) => a + b, 0)
	const sumY = y.reduce((a, b) => a + b, 0)
	const sumXY = x.reduce((sum, xi, idx) => sum + xi * y[idx], 0)
	const sumX2 = x.reduce((sum, xi) => sum + xi * xi, 0)

	const denominator = n * sumX2 - sumX * sumX
	if (denominator === 0) {
		throw new Error('Denominator in linear regression calculation is zero.')
	}

	const slope = (n * sumXY - sumX * sumY) / denominator
	const intercept = (sumY - slope * sumX) / n

	return { slope, intercept }
}

export { ElderlyCareForecast }
