import graphql from 'babel-plugin-relay/macro'

export const NomisSocialGradeQuery = graphql`
	query NomisSocialGradeQuery($geography: String!) {
		nomis(query: { dataset: "NM_2312_1", geography: $geography, parents: true, measures: "20301", useLabels: false }) {
			metadata {
				id
				label
				type
			}
			rows {
				date
				dimensions
				geography
				measures
				value
			}
			title
			url
		}
	}
`
