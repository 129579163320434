import graphql from 'babel-plugin-relay/macro'

export const OperatorBySiteIdQuery = graphql`
	query OperatorBySiteIdQuery($id: ID!) {
		operatorBySiteId(id: $id) {
			id
			alias
			brandId
			brandName
			charityNumber
			companiesHouseNumber
			constituency
			contacts
			cqcProviderId
			createdAt
			deregistrationDate
			emails
			employees
			inspectionDirectorate
			inspectionAreas
			inspectionCategories
			localAuthority
			mainPhoneNumber
			name
			odsCode
			onspdIcbCode
			onspdIcbName
			onspdLatitude
			onspdLongitude
			linkedinId
			location
			organisationType
			ownershipType
			phoneNumber
			postalAddressCounty
			postalAddressLine1
			postalAddressLine2
			postalAddressTownCity
			postalCode
			region
			registrationDate
			registrationStatus
			relationships
			servicesProviders
			sector
			serviceTypes
			source
			type
			updatedAt
			uprn
			website
		}
	}
`
