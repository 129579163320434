/**
 * @generated SignedSource<<1c6671f72d78a6e2e0976fe399b15e6b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LocalAuthoritiesQuery$variables = Record<PropertyKey, never>;
export type LocalAuthoritiesQuery$data = {
  readonly localAuthorities: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"LaSelectFragment" | "LaTableFragment">;
  }>;
};
export type LocalAuthoritiesQuery = {
  response: LocalAuthoritiesQuery$data;
  variables: LocalAuthoritiesQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LocalAuthoritiesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LocalAuthorityListItem",
        "kind": "LinkedField",
        "name": "localAuthorities",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "LaSelectFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "LaTableFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "LocalAuthoritiesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LocalAuthorityListItem",
        "kind": "LinkedField",
        "name": "localAuthorities",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ladCode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "regionName",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ce58e5bdb02259b04bb3e69b601c39d2",
    "id": null,
    "metadata": {},
    "name": "LocalAuthoritiesQuery",
    "operationKind": "query",
    "text": "query LocalAuthoritiesQuery {\n  localAuthorities {\n    ...LaSelectFragment\n    ...LaTableFragment\n  }\n}\n\nfragment LaSelectFragment on LocalAuthorityListItem {\n  ladCode\n  name\n}\n\nfragment LaTableFragment on LocalAuthorityListItem {\n  ladCode\n  name\n  regionName\n}\n"
  }
};

(node as any).hash = "a40f84a7d50e71aeb2860c4a45776e0b";

export default node;
