import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import {
	HousePriceIndexByLadCodeQuery as HousePriceIndexByLadCodeQueryType,
	HousePriceIndexByLadCodeQuery$data,
} from '../graphql/queries/__generated__/HousePriceIndexByLadCodeQuery.graphql'
import { LocalAuthorityByLadCodeQuery as LaQueryResponseType } from '../graphql/queries/__generated__/LocalAuthorityByLadCodeQuery.graphql'
import { HousePriceIndexByLadCodeQuery } from '../graphql/queries/HousePriceIndexByLad'
import { LocalAuthorityByLadCodeQuery as LaQueryRequestType } from '../graphql/queries/LocalAuthorityByLad'
import { LineChart } from '../shared/charts/LineChart'
import { VerticalSpace } from '../shared/layout/Space'
import { Paragraph, Subtitle } from '../shared/Text'

interface HousePricesLineChartProps {
	ladCode: string
}

const HousePricesLineChart: React.FC<HousePricesLineChartProps> = ({ ladCode }) => {
	const data = useLazyLoadQuery<HousePriceIndexByLadCodeQueryType>(HousePriceIndexByLadCodeQuery, {
		ladCode: ladCode,
	})

	const { localAuthorityByLadCode: la } = useLazyLoadQuery<LaQueryResponseType>(LaQueryRequestType, { ladCode })

	const model = processHousePriceIndexData(data, la.name)

	return (
		<>
			<Subtitle>House Price Index</Subtitle>
			<Paragraph>Lorem ipsum dolor sit amet, consetetur sadipscing elitr.</Paragraph>
			<VerticalSpace size="md" />
			<LineChart model={model} dataLabelDisplayOption="showOnlyLast" aspectRatio={2} />
		</>
	)
}

function processHousePriceIndexData(data: HousePriceIndexByLadCodeQuery$data, laName: string) {
	if (!data || !data.housePriceIndexByLadCode) {
		return { labels: [], datasets: [] }
	}

	const ladData = data.housePriceIndexByLadCode
	const { ladYears = [], nationalYears = [] } = ladData

	// Extract the labels (years)
	const labelsSet = new Set()
	ladYears.forEach((item) => labelsSet.add(item.year))
	nationalYears.forEach((item) => labelsSet.add(item.year))

	// Convert labelsSet to sorted array
	const labels = Array.from(labelsSet as Set<number>)
		.sort((a, b) => a - b)
		.map((year) => year.toString())

	// Prepare datasets
	const datasets = []

	// Prepare LAD dataset
	const ladValuesMap = new Map()
	ladYears.forEach((item) => {
		ladValuesMap.set(item.year, item.averagePrice)
	})

	const ladValues = labels.map((year) => {
		const value = ladValuesMap.get(parseInt(year))
		return value !== undefined ? value : null
	})

	datasets.push({
		id: laName,
		values: ladValues,
	})

	// Prepare National dataset
	const nationalValuesMap = new Map()
	nationalYears.forEach((item) => {
		nationalValuesMap.set(item.year, item.averagePrice)
	})

	const nationalValues = labels.map((year) => {
		const value = nationalValuesMap.get(parseInt(year))
		return value !== undefined ? value : null
	})

	datasets.push({
		id: 'England',
		values: nationalValues,
	})

	return { labels, datasets }
}

export { HousePricesLineChart }
