import React, { startTransition, useEffect, useState } from 'react'
import { ChevronLeft, ChevronRight } from 'react-feather'
import { useFragment, useLazyLoadQuery } from 'react-relay'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Cell } from 'react-table'

import { SitesQuery as SitesQueryType } from '../../graphql/queries/__generated__/SitesQuery.graphql'
import { SitesQuery } from '../../graphql/queries/Sites'
import { SegmentedButton } from '../../shared/Button'
import { DataTable, RowData } from '../../shared/DataTable'
import { SpaceBetween } from '../../shared/layout/Alignment'
import { Container, Row } from '../../shared/layout/Grid'
import { HorizontalSpace, VerticalSpace } from '../../shared/layout/Space'
import { Loading } from '../../shared/Loading'
import { Panel } from '../../shared/Panel'
import { PageTitle, Paragraph } from '../../shared/Text'
import { SitesSiteFragment$key } from './__generated__/SitesSiteFragment.graphql'
import { SitesSiteFragment } from './SitesSiteFragment'

const Sites: React.FC = () => {
	const limit = 100 // Example limit
	const [offset, setOffset] = useState(0)
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const pageParam = searchParams.get('page')

	// Parse the current page from the URL (default to 0)
	useEffect(() => {
		const page = pageParam ? parseInt(pageParam, 10) : 1 // default to page 1
		setOffset((page - 1) * limit)
	}, [pageParam])

	const data = useLazyLoadQuery<SitesQueryType>(SitesQuery, { limit, offset })

	const tableData = React.useMemo(() => {
		return data.sites || []
	}, [data])

	const sites = tableData.sites.map((site: SitesSiteFragment$key) => {
		return useFragment(SitesSiteFragment, site)
	})

	const columns = React.useMemo(
		() => [
			{ Header: 'Site Name', accessor: 'name' },
			{ Header: 'Beds', accessor: 'numberOfBeds' },
			{ Header: 'Lat', accessor: 'onspdLatitude' },
			{ Header: 'Long', accessor: 'onspdLongitude' },
			{ Header: 'Phone', accessor: 'mainPhoneNumber' },
		],
		[],
	)

	const handleNextPage = () => {
		if (tableData.hasNextPage) {
			startTransition(() => {
				setOffset((prevOffset) => prevOffset + limit) // Increment offset
				navigate(`?page=${offset / limit + 2}`) // Update URL with new page number
			})
		}
	}

	const handlePrevPage = () => {
		if (offset > 0) {
			startTransition(() => {
				setOffset((prevOffset) => Math.max(prevOffset - limit, 0)) // Decrement offset
				navigate(`?page=${offset / limit}`) // Update URL with new page number
			})
		}
	}

	const buttonOpts = [
		{
			children: <ChevronLeft />,
			value: 'prev',
			disabled: offset === 0,
		},
		{
			children: <ChevronRight />,
			value: 'next',
			disabled: !tableData.hasNextPage,
		},
	]

	const handleRowClick = (id: string) => {
		navigate(`/sites/${id}`) // Navigate to the site detail page using the row's ID
	}

	const onCell = (cell: Cell<RowData>) => {
		const { key, ...cellProps } = cell.getCellProps()
		return (
			<td {...cellProps} key={key}>
				<Paragraph>{cell.render('Cell')}</Paragraph>
			</td>
		)
	}

	return (
		<Container>
			<Row>
				<div className="col-12">
					<PageTitle>Sites</PageTitle>
				</div>
			</Row>
			<VerticalSpace size="lg" />
			<Row>
				<div className="col-12">
					<Panel>
						{tableData.sites.length > 0 ? (
							<>
								<SpaceBetween className="pagination">
									<Paragraph>
										Showing {offset + 1} to {offset + tableData.sites.length}
									</Paragraph>
									<SegmentedButton
										options={buttonOpts}
										onClick={(value) => {
											if (value === 'prev') handlePrevPage()
											else if (value === 'next') handleNextPage()
										}}
									/>
								</SpaceBetween>
								<HorizontalSpace size="sm" />
								<DataTable columns={columns} data={sites} onCell={onCell} onRowClick={handleRowClick} />
								<SpaceBetween className="pagination">
									<Paragraph>
										Showing {offset + 1} to {offset + tableData.sites.length}
									</Paragraph>
									<SegmentedButton
										options={buttonOpts}
										onClick={(value) => {
											if (value === 'prev') handlePrevPage()
											else if (value === 'next') handleNextPage()
										}}
									/>
								</SpaceBetween>
							</>
						) : (
							<p>No sites found.</p>
						)}
					</Panel>
				</div>
			</Row>
		</Container>
	)
}

const Suspended: React.FC = () => {
	return (
		<React.Suspense fallback={<Loading height="80vh" />}>
			<Sites />
		</React.Suspense>
	)
}

export { Suspended as Sites }
