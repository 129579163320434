import React, { useEffect, useState } from 'react'

import { RadiusValues } from '../graphql/queries/__generated__/HourlyWagesByCaQuery.graphql'
import { SecondaryButton } from './Button'

interface LatLongRadiusProps {
	lat: string
	long: string
	radius: RadiusValues
	onGoClick: (lat: string, long: string, radius: RadiusValues) => void
}

const LatLongRadius: React.FC<LatLongRadiusProps> = ({ lat, long, radius, onGoClick }) => {
	const [localState, setLocalState] = useState({
		lat,
		long,
		radius,
	})

	useEffect(() => {
		// Update local state if initial values change
		setLocalState({
			lat,
			long,
			radius,
		})
	}, [lat, long, radius])

	const handleLatChange = (lat: string) => {
		setLocalState({ ...localState, lat })
	}

	const handleLongChange = (long: string) => {
		setLocalState({ ...localState, long })
	}

	const handleRadiusChange = (radius: RadiusValues) => {
		setLocalState({ ...localState, radius })
	}

	const handleGoClick = () => {
		onGoClick(localState.lat, localState.long, localState.radius)
	}

	return (
		<div className="input-group mb-1 d-flex">
			<span className="input-group-text" id="lat">
				Lat
			</span>
			<input
				type="text"
				className="form-control"
				id="lat"
				aria-describedby="lat"
				value={localState.lat}
				onChange={(e) => handleLatChange(e.target.value)}
			/>
			<span className="input-group-text" id="lng">
				Long
			</span>
			<input
				type="text"
				className="form-control"
				id="lng"
				aria-describedby="lng"
				value={localState.long}
				onChange={(e) => handleLongChange(e.target.value)}
			/>
			<span className="input-group-text" id="radius">
				Radius
			</span>
			<select
				className="form-control"
				id="radius"
				aria-describedby="radius"
				value={localState.radius}
				onChange={(e) => handleRadiusChange(e.target.value as RadiusValues)}
			>
				<option value="ONE">1</option>
				<option value="THREE">3</option>
				<option value="FIVE">5</option>
				<option value="TEN">10</option>
			</select>
			<SecondaryButton onClick={handleGoClick}>Go</SecondaryButton>
		</div>
	)
}

export { LatLongRadius }
