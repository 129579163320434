import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import {
	PopulationBracketsByLadCodeQuery as QueryResponseType,
	PopulationBracketsByLadCodeQuery$data as QueryResponseType$Data,
} from '../graphql/queries/__generated__/PopulationBracketsByLadCodeQuery.graphql'
import { PopulationBracketsByLadCodeQuery as QueryRequestType } from '../graphql/queries/PopulationBrackets'
import { BarChart, Model } from '../shared/charts/BarChart'
import { VerticalSpace } from '../shared/layout/Space'
import { ParagraphSmallBold, TitleBox } from '../shared/Text'

interface FutureElderlyPopulationChartProps {
	ladCode: string
	showTitleWrapper?: boolean
}

const ElderlyPopulationIn10YearsChart: React.FC<FutureElderlyPopulationChartProps> = ({
	ladCode,
	showTitleWrapper = true,
}) => {
	const data = useLazyLoadQuery<QueryResponseType>(QueryRequestType, {
		ladCode: ladCode,
		bracket: '65-1000',
		yearsPast: 4,
		yearsFuture: 10,
	})

	const { labels, datasets } = transformElderlyPopulationData(data)

	return (
		<>
			{showTitleWrapper && (
				<>
					<TitleBox>Growth of Elderly Population</TitleBox>
					<ParagraphSmallBold>10 year forecast based on 2021 census data</ParagraphSmallBold>
					<VerticalSpace size="sm" />
				</>
			)}
			<BarChart
				horizontal={false}
				stacked={false}
				model={{
					labels,
					datasets,
				}}
				showLabels={true}
				alpha={1}
				aspectRatio={2}
			/>
		</>
	)
}

function transformElderlyPopulationData(data: QueryResponseType$Data): Model {
	if (!data || !data.populationBracketsByLadCode) {
		return { labels: [], datasets: [] }
	}

	const ladCode = data.populationBracketsByLadCode.ladCode
	const yearsData = data.populationBracketsByLadCode.years

	if (!yearsData || yearsData.length === 0) {
		console.error('No years data available.')
		return { labels: [], datasets: [] }
	}

	// Define the years we want to extract: earliest year, current year (2024), and latest year
	const sortedYears = yearsData.map((y) => y.year).sort((a, b) => a - b)
	const earliestYear = sortedYears[0]
	const currentYear = 2024
	const latestYear = sortedYears[sortedYears.length - 1]

	// Function to get total for a specific year and ladCode
	function getTotalForYear(year: number): { year: string; total: number; geoName: string } | null {
		const yearData = yearsData.find((y) => y.year === year)
		if (yearData) {
			const geoData = yearData.geographies.find((g) => g.geoCode === ladCode)
			if (geoData) {
				return { year: year.toString(), total: geoData.total, geoName: geoData.geoName }
			}
		}
		return null
	}

	const earliestData = getTotalForYear(earliestYear)
	const currentData = getTotalForYear(currentYear)
	const latestData = getTotalForYear(latestYear)

	// Handle cases where data might be missing
	const labels = []
	const values = []

	if (earliestData) {
		labels.push(earliestData.year)
		values.push(earliestData.total)
	} else {
		console.warn(`Data for the earliest year (${earliestYear}) is missing.`)
	}

	if (currentData) {
		labels.push(currentData.year)
		values.push(currentData.total)
	} else {
		console.warn(`Data for the current year (${currentYear}) is missing.`)
	}

	if (latestData) {
		labels.push(latestData.year)
		values.push(latestData.total)
	} else {
		console.warn(`Data for the latest year (${latestYear}) is missing.`)
	}

	if (labels.length === 0 || values.length === 0) {
		console.error('No valid data found for the specified years.')
		return { labels: [], datasets: [] }
	}

	const datasets = [
		{
			id: earliestData?.geoName || currentData?.geoName || latestData?.geoName || ladCode,
			values: values,
		},
	]

	return { labels, datasets }
}

export { ElderlyPopulationIn10YearsChart }
