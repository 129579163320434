import React from 'react'
import { ChevronLeft } from 'react-feather'
import { useNavigate, useParams } from 'react-router-dom'

import { SecondaryOutlineButton } from '../../shared/Button'
import { CenterAligned } from '../../shared/layout/Alignment'
import { Col, Container, Row } from '../../shared/layout/Grid'
import { VerticalSpace } from '../../shared/layout/Space'
import { Link, LinkBar } from '../../shared/LinkBar'
import { Loading } from '../../shared/Loading'
import { Panel } from '../../shared/Panel'
import { PageTitle } from '../../shared/Text'
import { CareFunding } from './tabs/CareFunding'
import { CompetitiveAnalysis } from './tabs/CompetitiveAnalysis'
import { DemandSupply } from './tabs/DemandSupply'
import { DemandTrends } from './tabs/DemandTrends'
import { Staffing } from './tabs/Staffing'
import { Summary } from './tabs/Summary'
import { SupplyAnalysis } from './tabs/SupplyAnalysis'

type RouteParams = Record<string, string | undefined>

const Report: React.FC = () => {
	const navigate = useNavigate()
	const { ladCode = 'E06000057', tab = 'summary' } = useParams<RouteParams>() // Get lad from the route

	const links: Link[] = [
		{
			label: 'Summary',
			key: 'summary',
		},
		{
			label: 'Demand & Supply',
			key: 'demand-supply',
		},
		{
			label: 'Demand Trends',
			key: 'demand-trends',
		},
		{
			label: 'Supply Analysis',
			key: 'supply-analysis',
		},
		{
			label: 'Competitive Analysis',
			key: 'competitive-analysis',
		},
		{
			label: 'Staffing Market',
			key: 'staffing',
			disabled: true,
		},
		{
			label: 'Care Funding',
			key: 'care-funding',
			disabled: true,
		},
		{
			label: 'Business Analysis',
			key: 'business-analysis',
			disabled: true,
		},
	]

	links.forEach((link) => {
		link.active = link.key === tab
	})

	const handleButtonClick = (key: string) => {
		console.log('Button Clicked', key)
		navigate(`/report/${ladCode}/${key}`)
	}

	const handleBackToExplorer = () => {
		console.log('Back to explorer')
		navigate(`/lads/${ladCode}/explorer`)
	}

	return (
		<Container>
			<div style={{ position: 'fixed', left: 0, top: 70, right: 0, zIndex: 1000 }}>
				<Container>
					<Row>
						<Col count={12}>
							<Panel padding={8} height="auto">
								<Row>
									<Col count={1}>
										<SecondaryOutlineButton
											icon={<ChevronLeft color="black" size="1rem" />}
											onClick={handleBackToExplorer}
										></SecondaryOutlineButton>
									</Col>
									<Col count={10}>
										<CenterAligned>
											<LinkBar links={links} onClick={handleButtonClick} />
										</CenterAligned>
									</Col>
								</Row>
							</Panel>
						</Col>
					</Row>
				</Container>
			</div>

			<VerticalSpace size="xxl" />

			<Row>
				<Col count={12}>
					<CenterAligned>
						<PageTitle>Elderly Care Market Analysis</PageTitle>
					</CenterAligned>
				</Col>
			</Row>

			<VerticalSpace size="lg" />

			{tab === 'summary' && <Summary ladCode={ladCode} />}
			{tab === 'demand-supply' && <DemandSupply ladCode={ladCode} />}
			{tab === 'demand-trends' && <DemandTrends ladCode={ladCode} />}
			{tab === 'supply-analysis' && <SupplyAnalysis ladCode={ladCode} />}
			{tab === 'competitive-analysis' && <CompetitiveAnalysis ladCode={ladCode} />}
			{tab === 'staffing' && <Staffing ladCode={ladCode} />}
			{tab === 'care-funding' && <CareFunding ladCode={ladCode} />}
		</Container>
	)
}

const Suspended: React.FC = () => {
	return (
		<React.Suspense fallback={<Loading height="80vh" />}>
			<Report />
		</React.Suspense>
	)
}

export { Suspended as Report }
