/**
 * @generated SignedSource<<d4195669b5c78968c8b8c0428cfcc1c9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type OpenAiVersions = "V3" | "V4" | "%future added value";
export type PromptQuery$variables = {
  content: string;
  key: string;
  version: OpenAiVersions;
};
export type PromptQuery$data = {
  readonly prompt: {
    readonly response: string;
    readonly version: string;
  };
};
export type PromptQuery = {
  response: PromptQuery$data;
  variables: PromptQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "content"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "key"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "version"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "content",
        "variableName": "content"
      },
      {
        "kind": "Variable",
        "name": "key",
        "variableName": "key"
      },
      {
        "kind": "Variable",
        "name": "version",
        "variableName": "version"
      }
    ],
    "concreteType": "OpenAiChatResponse",
    "kind": "LinkedField",
    "name": "prompt",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "response",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "version",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PromptQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PromptQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4acea83122f2d1922a0df850c0abd1d7",
    "id": null,
    "metadata": {},
    "name": "PromptQuery",
    "operationKind": "query",
    "text": "query PromptQuery(\n  $content: String!\n  $key: String!\n  $version: OpenAiVersions!\n) {\n  prompt(content: $content, key: $key, version: $version) {\n    response\n    version\n  }\n}\n"
  }
};
})();

(node as any).hash = "2efd9c7d70580434548b43dc018c7d4c";

export default node;
