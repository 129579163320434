import graphql from 'babel-plugin-relay/macro'

export const ElderlyCareStatsByLadCodeQuery = graphql`
	query ElderlyCareStatsByLadCodeQuery($ladCode: String!) {
		elderlyCareStatsByLadCode(ladCode: $ladCode) {
			ladCode
			populationOver65
			numberOfBeds
		}
	}
`
