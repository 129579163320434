import React from 'react'
import { useFragment, useLazyLoadQuery } from 'react-relay'
import { useNavigate } from 'react-router-dom'
import { Cell } from 'react-table'

import { SitesByLadCodeQuery as SitesResponse } from '../../graphql/queries/__generated__/SitesByLadCodeQuery.graphql'
import { SitesByLadCodeQuery as SitesRequest } from '../../graphql/queries/SitesByLadCode'
import { DataTable, RowData } from '../DataTable'
import { Paragraph } from '../Text'
import { SitesTableFragment$key } from './__generated__/SitesTableFragment.graphql'
import { SitesTableFragment } from './SitesTableFragment'

interface SitesTableProps {
	ladCode: string
}

const SitesTable: React.FC<SitesTableProps> = ({ ladCode }) => {
	const navigate = useNavigate()

	const data = useLazyLoadQuery<SitesResponse>(SitesRequest, { ladCode })

	const sitesData = React.useMemo(() => {
		return data.sitesByLadCode || []
	}, [data])

	// Use useFragment on each item in the array
	const sites = sitesData.map((site: SitesTableFragment$key) => {
		return useFragment(SitesTableFragment, site)
	})

	const columns = React.useMemo(
		() => [
			{ Header: 'Site Name', accessor: 'name' },
			{ Header: 'City', accessor: 'postalAddressTownCity' },
			{ Header: 'Beds', accessor: 'numberOfBeds' },
			{ Header: 'Registration', accessor: 'registrationDate' },
			{ Header: 'Ratings', accessor: 'currentRatings' },
			{ Header: 'Specialisms', accessor: 'specialisms' },
			{ Header: 'Service types', accessor: 'serviceTypes' },
			{ Header: 'Website', accessor: 'website' },
		],
		[],
	)

	const formattedData = sites.map((site) => {
		const { specialisms, serviceTypes, registrationDate } = site
		return {
			...site,
			currentRatings: site.currentRatings ? site.currentRatings.overall.rating : null,
			specialisms: specialisms ? specialisms.map((s: any) => s.name).join(', ') : null,
			serviceTypes: serviceTypes ? serviceTypes.replace(',', ', ') : null,
			registrationDate: registrationDate ? new Date(site.registrationDate).getFullYear().toString() : null,
		}
	})

	const handleRowClick = (id: string) => {
		navigate(`/sites/${id}`) // Navigate to the site detail page using the row's ID
	}

	const onCell = (cell: Cell<RowData>) => {
		const { key, ...cellProps } = cell.getCellProps()

		if (!cell.value) {
			return (
				<td {...cellProps} key={key}>
					<Paragraph>-</Paragraph>
				</td>
			)
		}

		return (
			<td {...cellProps} key={key}>
				<Paragraph>{cell.render('Cell')}</Paragraph>
			</td>
		)
	}

	return <DataTable columns={columns} data={formattedData} onCell={onCell} onRowClick={handleRowClick} />
}

export { SitesTable }
