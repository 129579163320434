/**
 * @generated SignedSource<<7254622ea161f354a048255aecba36d1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ElderlyCareStatsFragment$data = {
  readonly ladCode: string;
  readonly numberOfBeds: number;
  readonly populationOver65: number;
  readonly " $fragmentType": "ElderlyCareStatsFragment";
};
export type ElderlyCareStatsFragment$key = {
  readonly " $data"?: ElderlyCareStatsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ElderlyCareStatsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ElderlyCareStatsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ladCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "populationOver65",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numberOfBeds",
      "storageKey": null
    }
  ],
  "type": "ElderlyCareStats",
  "abstractKey": null
};

(node as any).hash = "45661edfc626314bdaccb70263627772";

export default node;
