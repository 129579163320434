/**
 * @generated SignedSource<<ce97e95ee044b3317f064404a620fdb4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SitesSiteFragment$data = {
  readonly id: any;
  readonly ladCode: string | null | undefined;
  readonly mainPhoneNumber: string | null | undefined;
  readonly name: string;
  readonly numberOfBeds: number | null | undefined;
  readonly onspdLatitude: number | null | undefined;
  readonly onspdLongitude: number | null | undefined;
  readonly " $fragmentType": "SitesSiteFragment";
};
export type SitesSiteFragment$key = {
  readonly " $data"?: SitesSiteFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SitesSiteFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SitesSiteFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ladCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mainPhoneNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numberOfBeds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "onspdLatitude",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "onspdLongitude",
      "storageKey": null
    }
  ],
  "type": "Site",
  "abstractKey": null
};

(node as any).hash = "15bb3ac178effb513a78c2def8103b6d";

export default node;
