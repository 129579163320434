import graphql from 'babel-plugin-relay/macro'

export const PopulationBracketsByLadCodeQuery = graphql`
	query PopulationBracketsByLadCodeQuery($ladCode: String!, $bracket: String!, $yearsPast: Int, $yearsFuture: Int) {
		populationBracketsByLadCode(
			ladCode: $ladCode
			bracket: $bracket
			yearsPast: $yearsPast
			yearsFuture: $yearsFuture
		) {
			ladCode
			years {
				year
				geographies {
					geoCode
					geoName
					total
				}
			}
		}
	}
`
