/**
 * @generated SignedSource<<abcc7a838f9d1dbcdcf114513444696c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type LocalAuthorityByLadCodeQuery$variables = {
  ladCode: string;
};
export type LocalAuthorityByLadCodeQuery$data = {
  readonly localAuthorityByLadCode: {
    readonly countryCode: string;
    readonly countryName: string;
    readonly geometry: string;
    readonly ladCode: string;
    readonly lat: number;
    readonly long: number;
    readonly name: string;
    readonly regionCode: string;
    readonly regionName: string;
  };
};
export type LocalAuthorityByLadCodeQuery = {
  response: LocalAuthorityByLadCodeQuery$data;
  variables: LocalAuthorityByLadCodeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ladCode"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "ladCode",
        "variableName": "ladCode"
      }
    ],
    "concreteType": "LocalAuthority",
    "kind": "LinkedField",
    "name": "localAuthorityByLadCode",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ladCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lat",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "long",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "geometry",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "regionName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "regionCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "countryName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "countryCode",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LocalAuthorityByLadCodeQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LocalAuthorityByLadCodeQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4084a6bcf617505b9453b744128b7f71",
    "id": null,
    "metadata": {},
    "name": "LocalAuthorityByLadCodeQuery",
    "operationKind": "query",
    "text": "query LocalAuthorityByLadCodeQuery(\n  $ladCode: String!\n) {\n  localAuthorityByLadCode(ladCode: $ladCode) {\n    ladCode\n    lat\n    long\n    name\n    geometry\n    regionName\n    regionCode\n    countryName\n    countryCode\n  }\n}\n"
  }
};
})();

(node as any).hash = "ef9df79a823dcf242152aafcd1f24866";

export default node;
