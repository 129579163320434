import graphql from 'babel-plugin-relay/macro'

export const LocalAuthoritiesQuery = graphql`
	query LocalAuthoritiesQuery {
		localAuthorities {
			...LaSelectFragment
			...LaTableFragment
		}
	}
`
