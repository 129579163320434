import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { LocalAuthoritiesByLadsQuery as LaQueryResponseType } from '../../graphql/queries/__generated__/LocalAuthoritiesByLadsQuery.graphql'
import { localAuthoritiesByLadsQuery as LaQueryRequestType } from '../../graphql/queries/LocalAuthoritiesByLads'
import { Col, Row } from '../../shared/layout/Grid'
import { PageTitle, Paragraph } from '../../shared/Text'

interface HeaderProps {
	ladCodes: string[]
}

const Header: React.FC<HeaderProps> = ({ ladCodes }) => {
	const { localAuthoritiesByLadCodes: las } = useLazyLoadQuery<LaQueryResponseType>(LaQueryRequestType, {
		ladCodes,
	})

	const name = las.map((la) => la.name).join(', ')

	const title = name || 'Output Areas'
	const subtitle = ladCodes.join(', ') || 'Lookup by mile radius'
	return (
		<Row>
			<Col>
				<PageTitle>{title}</PageTitle>
				<Paragraph>{subtitle}</Paragraph>
			</Col>
		</Row>
	)
}

export { Header }
