import { NomisTenureTypesQuery$data } from '../graphql/queries/__generated__/NomisTenureTypesQuery.graphql'
import { Model } from '../shared/charts/PieChart'

type NomisType = NomisTenureTypesQuery$data['nomis']

function nomisDataToPieChart(nomis: NomisType, dimensionKey: string, allowedDimensions?: string[]): Model {
	const init: Model = {
		labels: [],
		datasets: [],
	}

	const { rows, metadata } = nomis

	const model = rows.reduce<Model>((acc, r) => {
		// set labels
		const labelIdx = JSON.parse(r.dimensions)[dimensionKey]
		const labelItem = metadata.find((m) => m.id === labelIdx)
		const label = labelItem?.label

		if (!label || (allowedDimensions && !allowedDimensions.includes(label)) || label === 'Total') {
			return acc
		} else if (!acc.labels.includes(label)) {
			acc.labels.push(label)
		}

		// set datasets
		const geo = r.geography
		const value = r.value || 0
		const existingGeoDataset = acc.datasets.find((d) => d.id === geo)
		if (existingGeoDataset) {
			existingGeoDataset.values.push(value)
		} else {
			acc.datasets.push({
				id: geo,
				values: [value],
			})
		}

		return acc
	}, init)

	return model
}

export { nomisDataToPieChart }
