import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import {
	NomisIndustriesBroadWorkforceQuery as NomisIndustriesBroadWorkforceQueryType,
	NomisIndustriesBroadWorkforceQuery$data,
} from '../graphql/queries/__generated__/NomisIndustriesBroadWorkforceQuery.graphql'
import { NomisIndustriesBroadWorkforceQuery } from '../graphql/queries/NomisIndustriesBroadWorkforce'
import { BarChart, Dataset, Model } from '../shared/charts/BarChart'
import { VerticalSpace } from '../shared/layout/Space'
import { Paragraph, Subtitle } from '../shared/Text'

interface IndustryDistributionChartProps {
	ladCode: string
}

const IndustryDistributionChart: React.FC<IndustryDistributionChartProps> = ({ ladCode }) => {
	const data = useLazyLoadQuery<NomisIndustriesBroadWorkforceQueryType>(NomisIndustriesBroadWorkforceQuery, {
		geography: ladCode,
	})

	if (!data || !data.nomis) {
		return null
	}

	const { labels, datasets } = transformNomis(data)

	return (
		<>
			<Subtitle>Industry Distribution</Subtitle>
			<Paragraph>
				Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
				dolore magna aliquyam erat, sed diam voluptua.
			</Paragraph>
			<VerticalSpace size="md" />
			<BarChart
				horizontal={true}
				stacked={false}
				model={{
					labels,
					datasets,
				}}
				prefix=""
				suffix="%"
				showLabels={true}
				alpha={0.9}
			/>
		</>
	)
}

function transformNomis(data: NomisIndustriesBroadWorkforceQuery$data): Model {
	const { rows, metadata } = data.nomis

	const datasetMap = new Map<string, Dataset>()
	const labelsMap = new Map<string, string>()

	const re = new RegExp(/^\d+\s*:\s*|\s*\(.*\)$/g)

	const mutableRows = [...rows]

	// Sort so the Popular Industries are at the top
	mutableRows.sort((a, b) => {
		if (a.geography === b.geography) {
			return (b.value ?? 0) - (a.value ?? 0) // Ascending order for `value`
		}
		return a.geography.localeCompare(b.geography)
	})

	mutableRows.forEach((row) => {
		const { geography: geographyId, dimensions, value = null } = row
		const geographyLabel = metadata.find((m) => m.id === geographyId)?.label || geographyId
		if (!datasetMap.has(geographyId)) {
			datasetMap.set(geographyId, {
				id: geographyLabel,
				values: [],
			})
		}
		datasetMap.get(geographyId)?.values.push(value)

		const { industry: industryId } = JSON.parse(dimensions)
		const industryLabel = metadata.find((m) => m.id === industryId)?.label || industryId
		const industryLabelCleaned = industryLabel.replace(re, '')
		labelsMap.set(industryId, industryLabelCleaned)
	})

	const datasets = Array.from(datasetMap.values())
	const labels = Array.from(labelsMap.values())

	return { labels, datasets }
}

export { IndustryDistributionChart }
