import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { PrimaryOutlineButton } from '../../shared/Button'
import { SpaceBetween } from '../../shared/layout/Alignment'
import { Col, Container, Row } from '../../shared/layout/Grid'
import { VerticalSpace } from '../../shared/layout/Space'
import { Link, LinkBar } from '../../shared/LinkBar'
import { Panel } from '../../shared/Panel'
import { CareHomes } from './tabs/CareHomes'
import { Dementia } from './tabs/Dementia'
import { Economic } from './tabs/economy/Economic'
import { Elderly } from './tabs/Elderly'
import { Explorer } from './tabs/explorer/Explorer'
import { Housing } from './tabs/Housing'
import { Population } from './tabs/Population'

type RouteParams = Record<string, string | undefined>

const Lad: React.FC = () => {
	const { ladCode = 'E08000008', tab = 'explorer' } = useParams<RouteParams>() // Get lad from the route
	const navigate = useNavigate()

	const links: Link[] = [
		{
			label: 'Explorer',
			key: 'explorer',
		},
		{
			label: 'Care Homes',
			key: 'care-homes',
		},
		{
			label: 'Economy',
			key: 'economic',
		},
		{
			label: 'Population',
			key: 'population',
		},
		{
			label: 'Housing',
			key: 'housing',
		},
		{
			label: 'Elderly',
			key: 'elderly',
		},
		{
			label: 'Dementia',
			key: 'dementia',
		},
		{
			label: 'Learning Disabilities',
			key: 'learning',
			disabled: true,
		},
		{
			label: 'Physical Disabilities',
			key: 'physical',
			disabled: true,
		},
	]

	links.forEach((link) => {
		link.active = link.key === tab
	})

	const handleButtonClick = (key: string) => {
		console.log('Button Clicked', key)
		navigate(`/lads/${ladCode}/${key}`)
	}

	const handleGenerateReport = () => {
		console.log('Generate Report')
		navigate(`/report/${ladCode}`)
	}

	return (
		<Container>
			<div style={{ position: 'fixed', left: 0, top: 70, right: 0, zIndex: 1000 }}>
				<Container>
					<Row>
						<Col count={12}>
							<Panel padding={8} height="auto">
								<SpaceBetween>
									<LinkBar links={links} onClick={handleButtonClick} />
									<PrimaryOutlineButton onClick={handleGenerateReport}>Generate Report</PrimaryOutlineButton>
								</SpaceBetween>
							</Panel>
						</Col>
					</Row>
				</Container>
			</div>
			<VerticalSpace size="lg" />
			{tab === 'explorer' && <Explorer ladCode={ladCode} />}
			{tab === 'care-homes' && <CareHomes ladCode={ladCode} />}
			{tab === 'economic' && <Economic ladCode={ladCode} />}
			{tab === 'population' && <Population ladCode={ladCode} />}
			{tab === 'housing' && <Housing ladCode={ladCode} />}
			{tab === 'elderly' && <Elderly ladCode={ladCode} />}
			{tab === 'dementia' && <Dementia ladCode={ladCode} />}
		</Container>
	)
}

export { Lad }
