import React from 'react'

import { CenterAligned } from './layout/Alignment'

interface LoadingProps {
	height?: string | number
}

export const Loading: React.FC<LoadingProps> = ({ height = '50vh' }) => (
	<div style={{ height }}>
		<CenterAligned>
			<div className="spinner-border text-primary" role="status" data-testid="loading-spinner">
				<span className="visually-hidden">Loading...</span>
			</div>
		</CenterAligned>
	</div>
)
