import React from 'react'

import { theme } from '../Theme'

interface PillsProps {
	ids: string[]
	colors?: string[]
}

interface PillProps {
	id: string
	color: string
}

const colorPalette = theme.colors.colorPalette

/**
 * A row of Pills
 */
const Pills: React.FC<PillsProps> = ({ ids, colors = colorPalette }) => {
	return (
		<div style={{ display: 'flex', flexWrap: 'wrap', margin: '12px 0' }}>
			{ids.map((id, i) => {
				const borderColor = colors[i % colors.length]

				return <Pill key={id} id={id} color={borderColor} />
			})}
		</div>
	)
}

/**
 * A single Pill
 */
const Pill: React.FC<PillProps> = ({ id, color }) => {
	return (
		<div key={id} style={{ display: 'flex', alignItems: 'center', margin: '4px' }}>
			<div
				className="badge"
				style={{
					backgroundColor: color,
				}}
			>
				{id} {/* Only the id is displayed here */}
			</div>
		</div>
	)
}

export { Pill, Pills }
