/**
 * @generated SignedSource<<c55f04c83f4c4db35d17a548ae228af8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LaTableElderlyCareStatsFragment$data = {
  readonly ladCode: string;
  readonly numberOfBeds: number;
  readonly populationOver65: number;
  readonly " $fragmentType": "LaTableElderlyCareStatsFragment";
};
export type LaTableElderlyCareStatsFragment$key = {
  readonly " $data"?: LaTableElderlyCareStatsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"LaTableElderlyCareStatsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LaTableElderlyCareStatsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ladCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "populationOver65",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numberOfBeds",
      "storageKey": null
    }
  ],
  "type": "ElderlyCareStats",
  "abstractKey": null
};

(node as any).hash = "b99f50da27d586f8e23b9b17c92211d2";

export default node;
