/**
 * @generated SignedSource<<0659b238856178b33f9d0fdab41f814b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type PopulationByLadCodeQuery$variables = {
  ladCode: string;
  rangeSize: number;
};
export type PopulationByLadCodeQuery$data = {
  readonly populationByLadCode: {
    readonly female: ReadonlyArray<{
      readonly ranges: ReadonlyArray<{
        readonly ages: string;
        readonly total: number;
      }>;
      readonly year: number;
    }>;
    readonly male: ReadonlyArray<{
      readonly ranges: ReadonlyArray<{
        readonly ages: string;
        readonly total: number;
      }>;
      readonly year: number;
    }>;
  };
};
export type PopulationByLadCodeQuery = {
  response: PopulationByLadCodeQuery$data;
  variables: PopulationByLadCodeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ladCode"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "rangeSize"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "year",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PopulationRange",
    "kind": "LinkedField",
    "name": "ranges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ages",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "total",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "ladCode",
        "variableName": "ladCode"
      },
      {
        "kind": "Variable",
        "name": "rangeSize",
        "variableName": "rangeSize"
      }
    ],
    "concreteType": "Population",
    "kind": "LinkedField",
    "name": "populationByLadCode",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PopulationYear",
        "kind": "LinkedField",
        "name": "male",
        "plural": true,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "PopulationYear",
        "kind": "LinkedField",
        "name": "female",
        "plural": true,
        "selections": (v1/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PopulationByLadCodeQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PopulationByLadCodeQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "64b25ef833e28658a767042afa16fb0e",
    "id": null,
    "metadata": {},
    "name": "PopulationByLadCodeQuery",
    "operationKind": "query",
    "text": "query PopulationByLadCodeQuery(\n  $ladCode: String!\n  $rangeSize: Int!\n) {\n  populationByLadCode(ladCode: $ladCode, rangeSize: $rangeSize) {\n    male {\n      year\n      ranges {\n        ages\n        total\n      }\n    }\n    female {\n      year\n      ranges {\n        ages\n        total\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a29bbe646746b3d0052cbd0b8b5a2697";

export default node;
