import React, { useState } from 'react'
import { useFragment, useLazyLoadQuery } from 'react-relay'

import { CaHourlyWagesChart } from '../../charts/HourlyWagesChart/CaHourlyWagesChart'
import { RadiusValues } from '../../graphql/queries/__generated__/HourlyWagesByCaQuery.graphql'
import { OutputAreaByLatLongQuery as OaQueryResponseType } from '../../graphql/queries/__generated__/OutputAreaByLatLongQuery.graphql'
import { OutputAreasByMileRadiusQuery as AreaQueryResponseType } from '../../graphql/queries/__generated__/OutputAreasByMileRadiusQuery.graphql'
import { OutputAreaByLatLongQuery as OaQueryRequestType } from '../../graphql/queries/OutputAreaByLatLong'
import { OutputAreasByMileRadiusQuery as AreaQueryRequestType } from '../../graphql/queries/OutputAreasByMileRadius'
import { LatLongRadius } from '../../shared/LatLongRadius'
import { Col, Container, Row } from '../../shared/layout/Grid'
import { VerticalSpace } from '../../shared/layout/Space'
import { Loading } from '../../shared/Loading'
import { Panel } from '../../shared/Panel'
import { Switch } from '../../shared/Switch'
import { CaptureAreaFragment$key } from './__generated__/CaptureAreaFragment.graphql'
import { SelectedOaFragment$data, SelectedOaFragment$key } from './__generated__/SelectedOaFragment.graphql'
import { CaptureAreaFragment } from './CaptureAreaFragment'
import { Header } from './Header'
import { Map } from './Map'
import { SelectedOaFragment } from './SelectedOaFragment'

const Oa: React.FC = () => {
	const [showingLads, setShowingLads] = useState<boolean>(false)
	const [queryState, setQueryState] = useState<{
		lat: string
		long: string
		radius: RadiusValues
	}>({
		lat: '53.04565306',
		long: '-2.1854948',
		radius: 'ONE',
	})

	const { outputAreaByLatLong } = useLazyLoadQuery<OaQueryResponseType>(OaQueryRequestType, { ...queryState })
	const selectedOa: SelectedOaFragment$data | null = useFragment<SelectedOaFragment$key>(
		SelectedOaFragment,
		outputAreaByLatLong,
	)

	const { outputAreasByMileRadius } = useLazyLoadQuery<AreaQueryResponseType>(AreaQueryRequestType, { ...queryState })
	const ca = useFragment<CaptureAreaFragment$key>(CaptureAreaFragment, outputAreasByMileRadius)

	const handleGoClick = (lat: string, long: string, radius: RadiusValues) => {
		setQueryState({ lat, long, radius }) // Only update parent state when Go is clicked
	}
	const handleToggle = (checked: boolean) => {
		setShowingLads(checked)
	}

	return (
		<Container size="xl">
			<Header ladCodes={[...ca.ladCodes]} />
			<VerticalSpace size="md" />
			<Row>
				<div className="col  d-flex align-items-center">
					<Switch on={showingLads} onClick={handleToggle} label="Show Local Authorities" />
				</div>
				<div className="col d-flex align-items-end">
					<LatLongRadius {...queryState} onGoClick={handleGoClick} />
				</div>
			</Row>
			<VerticalSpace size="md" />
			<Row>
				<Col count={12}>
					<Panel padding={8} height="auto">
						<Map showingLads={showingLads} {...queryState} />
					</Panel>
				</Col>
			</Row>
			<VerticalSpace size="md" />
			<Row>
				<Col count={6}>
					<Panel>
						<CaHourlyWagesChart oaCode={selectedOa?.oaCode} radius={queryState.radius} />
					</Panel>
				</Col>
			</Row>
		</Container>
	)
}

const Suspended: React.FC = () => {
	return (
		<React.Suspense fallback={<Loading height="80vh" />}>
			<Oa />
		</React.Suspense>
	)
}

export { Suspended as Oa }
