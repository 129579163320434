import { GoogleMap, useJsApiLoader } from '@react-google-maps/api'
import { Feature, Point } from 'geojson'
import React, { FC, useCallback, useEffect, useState } from 'react'

import { getGoogleMapsParams } from '../../Config'
import { drawFeatures, drawMarkers, fitToFeatures, fitToLocations } from './utils'

export interface Marker {
	readonly location: Point
	readonly name: string
	readonly id: string
}

// Define the props interface
interface MapSelectorProps {
	features: Feature[] // Change `any` to the correct type if possible
	locations?: Marker[]
	width?: string
	height?: string
}

const initialMapOptions = {
	zoom: 7,
	center: { lat: 53.48, lng: -2.24 },
}

const options = {
	scrollwheel: false, // Disable scroll wheel zoom
	draggable: true, // Optional: Disable dragging
}

const gm = getGoogleMapsParams()

export const MapFeatures: FC<MapSelectorProps> = ({ features, locations = [], height = '70vh', width = '100%' }) => {
	const containerStyle = {
		width,
		height,
	}

	const [map, setMap] = useState<google.maps.Map | undefined>(undefined)

	// Load the Google Maps API
	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: gm.googleMapsApiKey,
		libraries: gm.libraries,
		mapIds: ['356b90f562f78e1a'],
	})

	useEffect(() => {
		if (map && features) {
			drawFeatures(map, features)
			if (features.length > 0) {
				fitToFeatures(map, features)
			}
		}
	}, [map, features])

	useEffect(() => {
		if (map && locations.length > 0) {
			drawMarkers(map, locations)
			if (features.length <= 0 && locations.length > 0) {
				fitToLocations(map, locations)
			}
		}
	}, [map, locations])

	const onLoad = useCallback((map: google.maps.Map) => {
		setMap(map)
	}, [])

	const onUnmount = useCallback(() => {
		setMap(undefined)
	}, [])

	return (
		<div style={containerStyle}>
			{isLoaded ? (
				<GoogleMap
					mapContainerStyle={containerStyle}
					center={initialMapOptions.center}
					zoom={initialMapOptions.zoom}
					onLoad={onLoad}
					onUnmount={onUnmount}
					options={options}
				>
					{/* Additional map elements can be added here */}
				</GoogleMap>
			) : (
				<></>
			)}
		</div>
	)
}
