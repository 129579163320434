// AuthGuard.tsx
import { useAuth0 } from '@auth0/auth0-react'
import React, { useEffect } from 'react'

import { getAuthorizationParams } from '../Config'
import { Loading } from '../shared/Loading'

const AuthGuard: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0()

	useEffect(() => {
		// Only redirect if not authenticated and not loading
		if (!isLoading && !isAuthenticated) {
			loginWithRedirect({
				authorizationParams: getAuthorizationParams(),
				appState: {
					targetUrl: window.location.pathname + window.location.search, // Preserve current URL
				},
			})
		}
	}, [isAuthenticated, isLoading, loginWithRedirect])

	// If loading, do not render anything yet
	if (isLoading) {
		return <Loading /> // or a spinner/placeholder
	}

	// If authenticated, render children
	return <>{isAuthenticated ? children : null}</>
}

export { AuthGuard }
