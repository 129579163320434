import graphql from 'babel-plugin-relay/macro'

export const HousePriceIndexByLadCodeQuery = graphql`
	query HousePriceIndexByLadCodeQuery($ladCode: String!) {
		housePriceIndexByLadCode(ladCode: $ladCode) {
			ladCode
			ladYears {
				year
				averagePrice
			}
			ladGrowthRate
			nationalYears {
				year
				averagePrice
			}
			nationalGrowthRate
		}
	}
`
