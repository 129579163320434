/**
 * @generated SignedSource<<a7503adb36104b92201f26cc802bcbc1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type StatXAttendanceAllowanceByAwardTimeSeriesQuery$variables = {
  geography: string;
};
export type StatXAttendanceAllowanceByAwardTimeSeriesQuery$data = {
  readonly statx: {
    readonly headers: ReadonlyArray<string>;
    readonly rows: ReadonlyArray<ReadonlyArray<string>>;
    readonly title: string;
    readonly url: string;
  };
};
export type StatXAttendanceAllowanceByAwardTimeSeriesQuery = {
  response: StatXAttendanceAllowanceByAwardTimeSeriesQuery$data;
  variables: StatXAttendanceAllowanceByAwardTimeSeriesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "geography"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Literal",
            "name": "database",
            "value": "AA_Entitled_New"
          },
          {
            "kind": "Literal",
            "name": "dimensions",
            "value": [
              {
                "field": "F_AA_QTR_New:DATE_NAME",
                "total": false,
                "values": [
                  "C_AA_QTR_New:201902",
                  "C_AA_QTR_New:202002",
                  "C_AA_QTR_New:202102",
                  "C_AA_QTR_New:202202",
                  "C_AA_QTR_New:202302",
                  "C_AA_QTR_New:202402"
                ]
              },
              {
                "field": "V_F_AA_Entitled_New:AAAWD",
                "total": false,
                "values": [
                  "C_AA_AAAWD:1",
                  "C_AA_AAAWD:2"
                ]
              }
            ]
          },
          {
            "kind": "Variable",
            "name": "geography",
            "variableName": "geography"
          },
          {
            "kind": "Literal",
            "name": "measures",
            "value": [
              {
                "key": "count",
                "value": "V_F_AA_Entitled_New"
              }
            ]
          },
          {
            "kind": "Literal",
            "name": "table",
            "value": "V_F_AA_Entitled_New"
          }
        ],
        "kind": "ObjectValue",
        "name": "query"
      }
    ],
    "concreteType": "StatxQueryResponse",
    "kind": "LinkedField",
    "name": "statx",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "headers",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rows",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "StatXAttendanceAllowanceByAwardTimeSeriesQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "StatXAttendanceAllowanceByAwardTimeSeriesQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "63ce5996bd950fd94a89b3a62268ffa8",
    "id": null,
    "metadata": {},
    "name": "StatXAttendanceAllowanceByAwardTimeSeriesQuery",
    "operationKind": "query",
    "text": "query StatXAttendanceAllowanceByAwardTimeSeriesQuery(\n  $geography: String!\n) {\n  statx(query: {database: \"AA_Entitled_New\", table: \"V_F_AA_Entitled_New\", geography: $geography, measures: [{key: \"count\", value: \"V_F_AA_Entitled_New\"}], dimensions: [{field: \"F_AA_QTR_New:DATE_NAME\", values: [\"C_AA_QTR_New:201902\", \"C_AA_QTR_New:202002\", \"C_AA_QTR_New:202102\", \"C_AA_QTR_New:202202\", \"C_AA_QTR_New:202302\", \"C_AA_QTR_New:202402\"], total: false}, {field: \"V_F_AA_Entitled_New:AAAWD\", values: [\"C_AA_AAAWD:1\", \"C_AA_AAAWD:2\"], total: false}]}) {\n    headers\n    rows\n    title\n    url\n  }\n}\n"
  }
};
})();

(node as any).hash = "b95329d18b1811e22f4ffa976538e96c";

export default node;
