/*
This is a query from our database, which we actually need to refine -> see also github task:
 https://github.com/orgs/SH-Proptech/projects/2/views/5?filterQuery=-status%3ABacklog%2CDone+pop&pane=issue&itemId=80941963)

 We will eventually need that for different charts and statBoxes, like timeseries graphs as well as horizontal bar chart showing different age groups in age p-yramid for the current year, etc
*/

import graphql from 'babel-plugin-relay/macro'

export const PopulationByLadCodeQuery = graphql`
	query PopulationByLadCodeQuery($ladCode: String!, $rangeSize: Int!) {
		populationByLadCode(ladCode: $ladCode, rangeSize: $rangeSize) {
			male {
				year
				ranges {
					ages
					total
				}
			}
			female {
				year
				ranges {
					ages
					total
				}
			}
		}
	}
`
