import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { PopulationBracketsByLadCodeQuery as PopResponseQuery } from '../graphql/queries/__generated__/PopulationBracketsByLadCodeQuery.graphql'
import { PopulationBracketsByLadCodeQuery as PopRequestQuery } from '../graphql/queries/PopulationBrackets'
import { theme } from '../Theme'
import { Card } from './Card'
import { CenterAligned } from './layout/Alignment'
import { Container, Row } from './layout/Grid'
import { HorizontalSpace } from './layout/Space'
import { Pill } from './Pills'
import { ParagraphBold, TitleBox } from './Text'

interface DemandTrendQuickFactsProps {
	ladCode: string
}

const DemandTrendQuickFacts: React.FC<DemandTrendQuickFactsProps> = ({ ladCode }) => {
	// Fetch population data inside the component
	const { populationBracketsByLadCode: populationData } = useLazyLoadQuery<PopResponseQuery>(PopRequestQuery, {
		ladCode,
		bracket: '65-1000',
		yearsFuture: 10,
		yearsPast: 0,
	})

	// Calculate growth rates using the fetched data
	const growthRates = calculateElderlyGrowthRates(populationData)

	// Handle cases where growthRates might be empty
	if (!growthRates || growthRates.length === 0) {
		console.error('Not enough data to display growth rates')
		return null // Or render a fallback UI
	}

	return (
		<Container>
			<Row>
				<DemandTrendQuickFact
					category="Elderly Population Growth"
					valuePrimary={growthRates[0].growthRate}
					valueSecondary={growthRates[1]?.growthRate}
					colorValue={growthRates[0].color}
					valuation={growthRates[0].valuation}
				/>
			</Row>
		</Container>
	)
}

interface DemandTrendQuickFactProps {
	category: string
	valuePrimary: number | string
	valueSecondary: number | string
	colorValue: string
	valuation: string
}

const DemandTrendQuickFact: React.FC<DemandTrendQuickFactProps> = ({
	category,
	valuePrimary,
	valueSecondary,
	colorValue,
	valuation,
}) => (
	<Card>
		<CenterAligned>
			<CenterAligned flexDirection="column">
				<Pill id={valuation} color={colorValue} />
				<ParagraphBold color={theme.colors.darkText}>{category}</ParagraphBold>
				<CenterAligned>
					<TitleBox color={colorValue}>{valuePrimary}%</TitleBox>
					<HorizontalSpace size="xxs" />
					<ParagraphBold>{valueSecondary}%</ParagraphBold>
				</CenterAligned>
			</CenterAligned>
		</CenterAligned>
	</Card>
)

function calculateElderlyGrowthRates(populationData: PopResponseQuery['response']['populationBracketsByLadCode']) {
	if (!populationData || !populationData.years) {
		console.error('Invalid population data')
		return []
	}

	const yearsData = populationData.years

	if (yearsData.length < 2) {
		console.error('Not enough data to calculate growth rates')
		return []
	}

	// Assuming yearsData is sorted from earliest to latest
	const earliestYearData = yearsData[yearsData.length - 1]
	const latestYearData = yearsData[0]

	const latestYearGeography: { [key: string]: number } = {}
	latestYearData.geographies.forEach((geo) => {
		latestYearGeography[geo.geoName] = parseInt(geo.total.toString(), 10)
	})

	const earliestYearGeography: { [key: string]: number } = {}
	earliestYearData.geographies.forEach((geo) => {
		earliestYearGeography[geo.geoName] = parseInt(geo.total.toString(), 10)
	})

	const geographies = Object.keys(latestYearGeography).filter((geo) =>
		Object.prototype.hasOwnProperty.call(earliestYearGeography, geo),
	)

	const growthRates = geographies.map((geo) => {
		const latestYearPopulation = latestYearGeography[geo]
		const earliestYearPopulation = earliestYearGeography[geo]

		if (earliestYearPopulation === 0) {
			console.warn(`Earliest year population for ${geo} is zero, cannot calculate growth rate.`)
			return {
				geo,
				growthRate: 'N/A',
				valuation: 'Undefined',
				color: '#A1A1A1',
			}
		}

		const growthRate = ((latestYearPopulation - earliestYearPopulation) / earliestYearPopulation) * 100
		const roundedGrowthRate = Math.round(growthRate * 100) / 100

		let valuation = ''
		let color = ''

		if (roundedGrowthRate < 0) {
			valuation = 'Very Low'
			color = '#06AA5B'
		} else if (roundedGrowthRate >= 0 && roundedGrowthRate < 5) {
			valuation = 'Low'
			color = 'rgba(6, 170, 91, 0.6)' // #06AA5B with 0.6 opacity
		} else if (roundedGrowthRate >= 5 && roundedGrowthRate < 10) {
			valuation = 'Modest'
			color = '#A1A1A1'
		} else if (roundedGrowthRate >= 10 && roundedGrowthRate < 20) {
			valuation = 'High'
			color = '#F7B501'
		} else if (roundedGrowthRate >= 20) {
			valuation = 'Alarming'
			color = '#DC2626'
		}

		return {
			geo,
			growthRate: roundedGrowthRate,
			valuation,
			color,
		}
	})

	return growthRates
}

export { DemandTrendQuickFacts }
