import { useAuth0 } from '@auth0/auth0-react' // Import the Auth0 hook
import React, { Suspense } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { getStage } from '../../Config'
import { LaSelect } from '../../shared/LaSelect/LaSelect'
import { LeftAligned, RightAligned } from '../../shared/layout/Alignment'
import { Pill } from '../../shared/Pills'
import { theme } from '../../Theme'

interface NavbarProps {
	ladCode?: string
}

const Navbar: React.FC<NavbarProps> = ({ ladCode }) => {
	const navigate = useNavigate()
	const { user, isAuthenticated, loginWithRedirect } = useAuth0() // Destructure user and auth functions

	const stage = getStage()

	React.useEffect(() => {
		const handleClickOutside = () => {
			const navbarToggler = document.querySelector('.navbar-toggler') as HTMLElement
			if (navbarToggler && navbarToggler.getAttribute('aria-expanded') === 'true') {
				setTimeout(() => {
					navbarToggler.click()
				}, 300)
			}
		}

		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])

	const handleSelectChange = (selectedValue: string) => {
		const pagePath = window.location.pathname.split('/')[1]
		const page = ['report'].includes(pagePath) ? pagePath : 'lads'

		navigate(`/${page}/${selectedValue}`)
	}

	const selectedLadCode = ladCode

	return (
		<nav
			className="navbar fixed-top navbar-light"
			style={{ backgroundColor: 'rgb(248, 249, 250)', borderBottom: '1px solid #eee' }}
		>
			<div className="container-fluid">
				<div style={{ width: '20vw' }}>
					<LeftAligned>
						<a href="/">
							<img src={`${process.env.PUBLIC_URL}/propmatch.svg`} alt="Propmatch" height={30} />
						</a>
						{['dev', 'dev-staging'].includes(stage) && <Pill id={'Development'} color={theme.colors.colorPalette[1]} />}
						{stage === 'stg' && <Pill id={'Staging'} color={theme.colors.colorPalette[2]} />}
					</LeftAligned>
				</div>

				<Suspense>
					<LaSelect onChange={handleSelectChange} value={selectedLadCode} />
				</Suspense>

				<div style={{ width: '20vw' }}>
					<RightAligned>
						<button
							className="navbar-toggler"
							type="button"
							data-bs-toggle="collapse"
							data-bs-target="#navbarNav"
							aria-controls="navbarNav"
							aria-expanded="false"
							aria-label="Toggle navigation"
						>
							<span className="navbar-toggler-icon"></span>
						</button>

						<div className="collapse navbar-collapse justify-content-end" id="navbarNav">
							<ul className="navbar-nav">
								<li className="nav-item">
									<Link className="nav-link" to="/">
										Local Authority Explorer
									</Link>
								</li>
								<li className="nav-item">
									<Link className="nav-link" to="/oa">
										Mile Radius Explorer
									</Link>
								</li>
								<li className="nav-item">
									<Link className="nav-link" to="/lads">
										Local Authority List
									</Link>
								</li>
								<li className="nav-item">
									<Link className="nav-link" to="/sites">
										Care Home List
									</Link>
								</li>
							</ul>
							{isAuthenticated ? (
								<ul className="navbar-nav">
									<li className="nav-item">
										<Link className="nav-link" to="/me">
											<img
												src={user?.picture}
												alt="Profile"
												style={{
													borderRadius: '50%',
													width: '40px',
													height: '40px',
													cursor: 'pointer',
													transition: 'transform 0.2s',
												}}
												onMouseOver={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
												onMouseOut={(e) => (e.currentTarget.style.transform = 'scale(1)')}
											/>
										</Link>
									</li>
								</ul>
							) : (
								<button className="btn btn-outline-primary" onClick={() => loginWithRedirect()}>
									Login
								</button>
							)}
						</div>
					</RightAligned>
				</div>
			</div>
		</nav>
	)
}

export { Navbar }
