import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import {
	StatXUniversalCreditHouseholdsQuery as QueryResponseType,
	StatXUniversalCreditHouseholdsQuery$data as QueryResponseType$Data,
} from '../graphql/queries/__generated__/StatXUniversalCreditHouseholdsQuery.graphql'
import { StatXUniversalCreditHouseholdsQuery as QueryRequestType } from '../graphql/queries/StatXUniversalCreditHouseholds'
import { BarChart, Model } from '../shared/charts/BarChart'
import { VerticalSpace } from '../shared/layout/Space'
import { Paragraph, Subtitle } from '../shared/Text'

interface HouseholdsWithUniversalCreditChartProps {
	ladCode: string
}

const HouseholdsWithUniversalCreditChart: React.FC<HouseholdsWithUniversalCreditChartProps> = ({ ladCode }) => {
	const data = useLazyLoadQuery<QueryResponseType>(QueryRequestType, {
		geography: ladCode,
	})

	const { labels, datasets } = transformStatx(data)

	return (
		<>
			<Subtitle>Households on Universal Credit</Subtitle>
			<Paragraph>The number of households receiving Universal Credit over time.</Paragraph>
			<VerticalSpace size="sm" />
			<BarChart
				horizontal={false}
				stacked={true}
				model={{
					labels,
					datasets,
				}}
				showLabels={true}
				alpha={0.9}
				aspectRatio={1}
			/>
		</>
	)
}

function transformStatx(data: QueryResponseType$Data): Model {
	if (!data || !data.statx || !data.statx.rows || !data.statx.headers) {
		return { labels: [], datasets: [] }
	}

	const headers = data.statx.headers
	const rows = data.statx.rows

	// Adjusted header names to match your data
	const dateIndex = headers.indexOf('Month') // Changed from 'Date' to 'Month'
	const geographyIndex = headers.indexOf('National - Regional - LA - OAs')
	const valueIndex = headers.indexOf('Households on Universal Credit')

	if (dateIndex === -1 || geographyIndex === -1 || valueIndex === -1) {
		return { labels: [], datasets: [] }
	}

	// Assume the first geography in the data is the target geography
	const targetGeography = rows[0][geographyIndex]

	// Filter data for the target geography
	const filteredRows = rows.filter((row) => row[geographyIndex] === targetGeography)

	if (filteredRows.length === 0) {
		console.warn(`No data found for geography: ${targetGeography}`)
		return { labels: [], datasets: [] }
	}

	// Collect data
	const labelsSet = new Set<string>()
	const dataMap: { [geoName: string]: { [year: string]: number } } = {}

	filteredRows.forEach((row) => {
		const dateStr = row[dateIndex]
		const value = parseInt(row[valueIndex], 10)

		// Extract year from dateStr (e.g., 'November 2023' -> '2023')
		let label: string
		if (dateStr.includes(' ')) {
			// Assuming format 'Month YYYY'
			const parts = dateStr.split(' ')
			if (parts.length === 2) {
				const yearPart = parts[1] // '2023'
				label = yearPart
			} else {
				// Unexpected format
				label = dateStr
			}
		} else {
			// Handle other date formats if necessary
			label = dateStr.slice(-4) // Assuming the last 4 characters are the year
		}

		labelsSet.add(label)

		if (!dataMap[targetGeography]) {
			dataMap[targetGeography] = {}
		}
		if (!dataMap[targetGeography][label]) {
			dataMap[targetGeography][label] = 0
		}
		dataMap[targetGeography][label] += value // Accumulate values per year
	})

	// Prepare labels sorted in ascending order
	const labels: string[] = Array.from(labelsSet).sort()

	// Prepare datasets
	const datasets = [
		{
			id: targetGeography,
			values: labels.map((label) => {
				const value = dataMap[targetGeography][label]
				return value !== undefined ? value : null
			}),
		},
	]

	return { labels, datasets }
}

export { HouseholdsWithUniversalCreditChart }
