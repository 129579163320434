import React from 'react'

import { ElderlyBedsForecast } from '../../../charts/ElderlyBedsForecast/ElderlyBedsForecast'
import { ElderlyBedsTimeSeriesChart } from '../../../charts/ElderlyBedsTimeSeriesChart/ElderlyBedsTimeSeriesChart'
import { ElderlySitesChart } from '../../../charts/ElderlySitesChart/ElderlySitesChart'
import { Col, Row } from '../../../shared/layout/Grid'
import { VerticalSpace } from '../../../shared/layout/Space'
import { Loading } from '../../../shared/Loading'
import { Panel } from '../../../shared/Panel'
import { ParagraphSmallBold, SubtitleBox, TitleBox } from '../../../shared/Text'

interface SupplyAnalysisProps {
	ladCode: string
}

const DemandTrends: React.FC<SupplyAnalysisProps> = ({ ladCode }) => {
	return (
		<>
			<Row>
				<div className="col-12">
					<Panel>
						<Row>
							<Col count={6}>
								<SubtitleBox>Supply Analysis</SubtitleBox>
								<TitleBox>Urgent Strategies Needed for Rising Demand in Elderly Care</TitleBox>
								<ParagraphSmallBold>
									Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
									labore et dolore magna aliquyam erat, sed diam voluptua. Lorem ipsum dolor sit amet, consetetur
									sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed
									diam voluptua.
								</ParagraphSmallBold>
							</Col>
							<div className="col-1"></div>
						</Row>
					</Panel>
				</div>
			</Row>
			<VerticalSpace size="sm" />
			<Row>
				<div className="col-12">
					<Panel>
						<VerticalSpace size="sm" />
						<ElderlyBedsTimeSeriesChart ladCode={ladCode} />
						<VerticalSpace size="sm" />
					</Panel>
				</div>
			</Row>
			<VerticalSpace size="sm" />
			<Row>
				<div className="col-12">
					{/* <Panel padding={16}> */}
					{/* <MapFeatures features={features} locations={locations} /> */}
					{/* </Panel> */}
				</div>
			</Row>
			<VerticalSpace size="sm" />
			<Row>
				<Col count={6}>
					<Panel>
						<ElderlySitesChart ladCode={ladCode} />
					</Panel>
				</Col>
				<Col count={6}>
					<Panel>
						<ElderlyBedsForecast ladCode={ladCode} />
					</Panel>
				</Col>
			</Row>
		</>
	)
}

const Suspended: React.FC<SupplyAnalysisProps> = ({ ladCode }) => {
	return (
		<React.Suspense fallback={<Loading />}>
			<DemandTrends ladCode={ladCode} />
		</React.Suspense>
	)
}

export { Suspended as SupplyAnalysis }
