/**
 * @generated SignedSource<<82206223083f45efb2c2075bad8f6710>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type StatXUniversalCreditHouseholdsQuery$variables = {
  geography: string;
};
export type StatXUniversalCreditHouseholdsQuery$data = {
  readonly statx: {
    readonly headers: ReadonlyArray<string>;
    readonly rows: ReadonlyArray<ReadonlyArray<string>>;
    readonly title: string;
    readonly url: string;
  };
};
export type StatXUniversalCreditHouseholdsQuery = {
  response: StatXUniversalCreditHouseholdsQuery$data;
  variables: StatXUniversalCreditHouseholdsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "geography"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Literal",
            "name": "database",
            "value": "UC_Households"
          },
          {
            "kind": "Literal",
            "name": "dimensions",
            "value": [
              {
                "field": "F_UC_HH_DATE:DATE_NAME",
                "total": false,
                "values": [
                  "C_UC_HH_DATE:202311",
                  "C_UC_HH_DATE:202212",
                  "C_UC_HH_DATE:202112",
                  "C_UC_HH_DATE:202012",
                  "C_UC_HH_DATE:201912"
                ]
              }
            ]
          },
          {
            "kind": "Variable",
            "name": "geography",
            "variableName": "geography"
          },
          {
            "kind": "Literal",
            "name": "measures",
            "value": [
              {
                "key": "count",
                "value": "V_F_UC_HOUSEHOLDS"
              }
            ]
          },
          {
            "kind": "Literal",
            "name": "table",
            "value": "V_F_UC_HOUSEHOLDS"
          }
        ],
        "kind": "ObjectValue",
        "name": "query"
      }
    ],
    "concreteType": "StatxQueryResponse",
    "kind": "LinkedField",
    "name": "statx",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "headers",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rows",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "StatXUniversalCreditHouseholdsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "StatXUniversalCreditHouseholdsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1456d0e15335745b6797755ee44d7152",
    "id": null,
    "metadata": {},
    "name": "StatXUniversalCreditHouseholdsQuery",
    "operationKind": "query",
    "text": "query StatXUniversalCreditHouseholdsQuery(\n  $geography: String!\n) {\n  statx(query: {database: \"UC_Households\", table: \"V_F_UC_HOUSEHOLDS\", geography: $geography, measures: [{key: \"count\", value: \"V_F_UC_HOUSEHOLDS\"}], dimensions: [{field: \"F_UC_HH_DATE:DATE_NAME\", values: [\"C_UC_HH_DATE:202311\", \"C_UC_HH_DATE:202212\", \"C_UC_HH_DATE:202112\", \"C_UC_HH_DATE:202012\", \"C_UC_HH_DATE:201912\"], total: false}]}) {\n    headers\n    rows\n    title\n    url\n  }\n}\n"
  }
};
})();

(node as any).hash = "28d056c43a9b43f8ad24ddc35a4b7a46";

export default node;
