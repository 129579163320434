import { AppState, Auth0Provider } from '@auth0/auth0-react'
import React, { useEffect } from 'react'

import { getAuth0Config } from './Config'
import { AuthGuard } from './relay/AuthGuard'
import { RelayProviderWithAuth } from './relay/RelayProviderWithAuth'
import { AppRoutes } from './routes/AppRoutes'

const App: React.FC = () => {
	useEffect(() => {
		document.title = 'PropMatch'
	}, [])

	const c = getAuth0Config()

	const onRedirectCallback = (appState?: AppState) => {
		if (appState && appState.targetUrl) {
			window.history.replaceState({}, document.title, appState.targetUrl)
		}
	}

	return (
		<Auth0Provider
			domain={c.domain}
			clientId={c.clientId}
			authorizationParams={{
				audience: c.audience,
				redirect_uri: c.redirectUri, // Correct way to specify the redirect URI
			}}
			onRedirectCallback={onRedirectCallback}
		>
			<AuthGuard>
				<RelayProviderWithAuth>
					<AppRoutes />
				</RelayProviderWithAuth>
			</AuthGuard>
		</Auth0Provider>
	)
}

export { App }
