import React from 'react'

import { CenterAligned } from '../../shared/layout/Alignment'
import { Container } from '../../shared/layout/Grid'

const Footer: React.FC = () => {
	return (
		<footer>
			<Container>
				<CenterAligned>
					<p>&copy; {new Date().getFullYear()} Sh Proptech. All rights reserved.</p>
				</CenterAligned>
			</Container>
		</footer>
	)
}

export { Footer }
