/**
 * @generated SignedSource<<d767b3b00bd49145b537e123cf97b2f7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SelectedOaFragment$data = {
  readonly geometry: string;
  readonly ladCode: string;
  readonly oaCode: string;
  readonly " $fragmentType": "SelectedOaFragment";
};
export type SelectedOaFragment$key = {
  readonly " $data"?: SelectedOaFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SelectedOaFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SelectedOaFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ladCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "oaCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "geometry",
      "storageKey": null
    }
  ],
  "type": "OutputArea",
  "abstractKey": null
};

(node as any).hash = "2c246324a915de137ca5408f5b1cbaba";

export default node;
