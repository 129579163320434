import React, { useState } from 'react'

interface ButtonProps {
	onClick: () => void
	disabled?: boolean
	children?: React.ReactNode
	icon?: React.ReactNode
}

const PrimaryButton: React.FC<ButtonProps> = ({ icon, onClick, disabled, children }) => (
	<BaseButton icon={icon} onClick={onClick} disabled={disabled} className="btn-primary">
		{children}
	</BaseButton>
)

const PrimaryOutlineButton: React.FC<ButtonProps> = ({ icon, onClick, disabled, children }) => (
	<BaseButton icon={icon} onClick={onClick} disabled={disabled} className="btn-outline-primary">
		{children}
	</BaseButton>
)

const SecondaryButton: React.FC<ButtonProps> = ({ icon, onClick, disabled, children }) => (
	<BaseButton icon={icon} onClick={onClick} disabled={disabled} className="btn-secondary align-middle">
		{children}
	</BaseButton>
)

const SecondaryOutlineButton: React.FC<ButtonProps> = ({ icon, onClick, disabled, children }) => (
	<BaseButton icon={icon} onClick={onClick} disabled={disabled} className="btn-outline-secondary">
		{children}
	</BaseButton>
)

const LinkButton: React.FC<ButtonProps> = ({ icon, onClick, disabled, children }) => (
	<BaseButton icon={icon} onClick={onClick} disabled={disabled} className="btn-link">
		{children}
	</BaseButton>
)

interface BaseButtonProps extends ButtonProps {
	className?: string
}

const BaseButton: React.FC<BaseButtonProps> = ({ icon, onClick, disabled, children, className }) => {
	const classes = `btn ${className}`
	const spanClassName = children ? '' : 'me-2'
	const iconStyle: React.CSSProperties = {
		position: 'relative',
		height: 20,
		color: 'white',
	}

	return (
		<button onClick={onClick} disabled={disabled} className={classes}>
			{icon && (
				<span style={iconStyle} className={spanClassName}>
					{icon}
				</span>
			)}
			{children}
		</button>
	)
}

const TextButton: React.FC<ButtonProps> = ({ onClick, disabled, children }) => (
	<button onClick={onClick} disabled={disabled} className="btn btn-link fw-bold fs-6">
		{children}
	</button>
)

interface SegmentButtonProps {
	children: React.ReactNode
	value: string
	disabled: boolean
}

interface SegmentedButtonProps {
	options: SegmentButtonProps[]
	onClick: (value: string) => void // Callback for when a button is selected
}

const SegmentedButton: React.FC<SegmentedButtonProps> = ({ options, onClick }) => {
	const [, setSelectedValue] = useState<string | null>(null)

	const handleButtonClick = (value: string) => {
		setSelectedValue(value)
		onClick(value)
	}

	const style = {
		paddingLeft: '1rem',
		paddingRight: '1rem',
		paddingTop: '0.15rem',
		paddingBottom: '0.15rem',
		fontSize: '.25rem',
		height: 30,
	}

	return (
		<div className="btn-group">
			{options.map((option) => (
				<button
					type="button"
					className="btn btn-secondary"
					style={style}
					key={option.value}
					onClick={() => handleButtonClick(option.value)}
					disabled={option.disabled} // Set this to true if you need to disable
				>
					{option.children}
				</button>
			))}
		</div>
	)
}

export {
	LinkButton,
	PrimaryButton,
	PrimaryOutlineButton,
	SecondaryButton,
	SecondaryOutlineButton,
	SegmentedButton,
	TextButton,
}
