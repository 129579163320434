/**
 * @generated SignedSource<<cd4825ef6cb7f473dea37ae1c5b848a4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type OperatorBySiteIdQuery$variables = {
  id: string;
};
export type OperatorBySiteIdQuery$data = {
  readonly operatorBySiteId: {
    readonly alias: string | null | undefined;
    readonly brandId: string | null | undefined;
    readonly brandName: string | null | undefined;
    readonly charityNumber: string | null | undefined;
    readonly companiesHouseNumber: string | null | undefined;
    readonly constituency: string;
    readonly contacts: string | null | undefined;
    readonly cqcProviderId: string;
    readonly createdAt: any;
    readonly deregistrationDate: any | null | undefined;
    readonly emails: string | null | undefined;
    readonly employees: string | null | undefined;
    readonly id: any;
    readonly inspectionAreas: string | null | undefined;
    readonly inspectionCategories: string | null | undefined;
    readonly inspectionDirectorate: string | null | undefined;
    readonly linkedinId: string | null | undefined;
    readonly localAuthority: string | null | undefined;
    readonly location: string | null | undefined;
    readonly mainPhoneNumber: string | null | undefined;
    readonly name: string;
    readonly odsCode: string | null | undefined;
    readonly onspdIcbCode: string | null | undefined;
    readonly onspdIcbName: string | null | undefined;
    readonly onspdLatitude: number | null | undefined;
    readonly onspdLongitude: number | null | undefined;
    readonly organisationType: string;
    readonly ownershipType: string;
    readonly phoneNumber: string | null | undefined;
    readonly postalAddressCounty: string | null | undefined;
    readonly postalAddressLine1: string | null | undefined;
    readonly postalAddressLine2: string | null | undefined;
    readonly postalAddressTownCity: string;
    readonly postalCode: string;
    readonly region: string | null | undefined;
    readonly registrationDate: any | null | undefined;
    readonly registrationStatus: string;
    readonly relationships: string | null | undefined;
    readonly sector: string | null | undefined;
    readonly serviceTypes: string | null | undefined;
    readonly servicesProviders: string | null | undefined;
    readonly source: string;
    readonly type: string;
    readonly updatedAt: any;
    readonly uprn: string | null | undefined;
    readonly website: string | null | undefined;
  };
};
export type OperatorBySiteIdQuery = {
  response: OperatorBySiteIdQuery$data;
  variables: OperatorBySiteIdQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "Operator",
    "kind": "LinkedField",
    "name": "operatorBySiteId",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "alias",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "brandId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "brandName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "charityNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "companiesHouseNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "constituency",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "contacts",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cqcProviderId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deregistrationDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "emails",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "employees",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "inspectionDirectorate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "inspectionAreas",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "inspectionCategories",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "localAuthority",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mainPhoneNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "odsCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "onspdIcbCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "onspdIcbName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "onspdLatitude",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "onspdLongitude",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "linkedinId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "location",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "organisationType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ownershipType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "phoneNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "postalAddressCounty",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "postalAddressLine1",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "postalAddressLine2",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "postalAddressTownCity",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "postalCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "region",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "registrationDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "registrationStatus",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "relationships",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "servicesProviders",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "sector",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "serviceTypes",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "source",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "updatedAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "uprn",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "website",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OperatorBySiteIdQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OperatorBySiteIdQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "fd9e4a3f11af5c8eafabc5fc1dc8993e",
    "id": null,
    "metadata": {},
    "name": "OperatorBySiteIdQuery",
    "operationKind": "query",
    "text": "query OperatorBySiteIdQuery(\n  $id: ID!\n) {\n  operatorBySiteId(id: $id) {\n    id\n    alias\n    brandId\n    brandName\n    charityNumber\n    companiesHouseNumber\n    constituency\n    contacts\n    cqcProviderId\n    createdAt\n    deregistrationDate\n    emails\n    employees\n    inspectionDirectorate\n    inspectionAreas\n    inspectionCategories\n    localAuthority\n    mainPhoneNumber\n    name\n    odsCode\n    onspdIcbCode\n    onspdIcbName\n    onspdLatitude\n    onspdLongitude\n    linkedinId\n    location\n    organisationType\n    ownershipType\n    phoneNumber\n    postalAddressCounty\n    postalAddressLine1\n    postalAddressLine2\n    postalAddressTownCity\n    postalCode\n    region\n    registrationDate\n    registrationStatus\n    relationships\n    servicesProviders\n    sector\n    serviceTypes\n    source\n    type\n    updatedAt\n    uprn\n    website\n  }\n}\n"
  }
};
})();

(node as any).hash = "d380a4b7cb7b12b3841d25115b9130bb";

export default node;
