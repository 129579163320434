import React from 'react'

import { HousePricesLineChart } from '../../../charts/HousePricesLineChart'
import { HousingYearsToBuyChart } from '../../../charts/HousingYearsToBuyChart'
import { TenureTypePieChart } from '../../../charts/TenureTypePieChart'
import { LeftAligned } from '../../../shared/layout/Alignment'
import { Col, Container, Row } from '../../../shared/layout/Grid'
import { HorizontalSpace, VerticalSpace } from '../../../shared/layout/Space'
import { Loading } from '../../../shared/Loading'
import { Panel } from '../../../shared/Panel'
import { PageTitle, Paragraph } from '../../../shared/Text'

interface HousingProps {
	ladCode: string
}

const Housing: React.FC<HousingProps> = ({ ladCode }) => {
	return (
		<Container>
			<VerticalSpace size="xl" />
			<Row>
				<LeftAligned>
					<HorizontalSpace size="md" />
					<Col count={7}>
						<PageTitle>Housing</PageTitle>
						<Paragraph>
							Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
							et dolore magna aliquyam erat, sed diam voluptua. Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
							sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
						</Paragraph>
					</Col>
				</LeftAligned>
			</Row>
			<VerticalSpace size="xl" />
			<Row>
				<Col count={6}>
					<Panel>
						<HousePricesLineChart ladCode={ladCode} />
					</Panel>
				</Col>
				<Col count={6}>
					<Panel>
						<HousingYearsToBuyChart ladCode={ladCode} />
					</Panel>
				</Col>
			</Row>
			<VerticalSpace size="md" />
			<Row>
				<Col count={6}>
					<Panel>
						<TenureTypePieChart ladCode={ladCode} />
					</Panel>
				</Col>
			</Row>
		</Container>
	)
}

const Suspended: React.FC<HousingProps> = ({ ladCode }) => {
	return (
		<React.Suspense fallback={<Loading />}>
			<Housing ladCode={ladCode} />
		</React.Suspense>
	)
}

export { Suspended as Housing }
