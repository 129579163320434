/*
Its weird. If - for Amber Valley ("E07000032") - I exclude the value "C_AA_QTR_New:201902" for data dimensions it does not work while  weirdly does work for Stoke ("E06000021")
*/

import graphql from 'babel-plugin-relay/macro'

export const StatXAttendanceAllowanceByAwardTimeSeriesQuery = graphql`
	query StatXAttendanceAllowanceByAwardTimeSeriesQuery($geography: String!) {
		statx(
			query: {
				database: "AA_Entitled_New"
				table: "V_F_AA_Entitled_New"
				geography: $geography
				measures: [{ key: "count", value: "V_F_AA_Entitled_New" }]
				dimensions: [
					{
						field: "F_AA_QTR_New:DATE_NAME"
						values: [
							"C_AA_QTR_New:201902"
							"C_AA_QTR_New:202002"
							"C_AA_QTR_New:202102"
							"C_AA_QTR_New:202202"
							"C_AA_QTR_New:202302"
							"C_AA_QTR_New:202402"
						]
						total: false
					}
					{ field: "V_F_AA_Entitled_New:AAAWD", values: ["C_AA_AAAWD:1", "C_AA_AAAWD:2"], total: false }
				]
			}
		) {
			headers
			rows
			title
			url
		}
	}
`
