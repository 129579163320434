import graphql from 'babel-plugin-relay/macro'

export const NomisUnemploymentRateTimeSeriesQuery = graphql`
	query NomisUnemploymentRateTimeSeriesQuery($geography: String!) {
		nomis(
			query: {
				dataset: "NM_127_1"
				geography: $geography
				date: "2023-12, 2022-12, 2021-12, 2020-12, 2019-12, 2018-12, 2017-12, 2016-12, 2015-12, 2014-12, 2013-12"
				measures: "20100"
				parents: true
				query: "item=2"
			}
		) {
			metadata {
				id
				label
				type
			}
			rows {
				date
				dimensions
				geography
				measures
				value
			}
			title
			url
			geographies
		}
	}
`
