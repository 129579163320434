import './AppRoutes.css'

import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import { Lad } from '../pages/lad/Lad'
import { Lads } from '../pages/lads/Lads'
import { Me } from '../pages/me/Me'
import { Oa } from '../pages/oa/Oa'
import { Report } from '../pages/report/Report'
import { Site } from '../pages/site/Site'
import { Sites } from '../pages/sites/Sites'
import { MainLayout } from './MainLayout' // Import the new layout

const AppRoutes: React.FC = () => (
	<Router>
		<Routes>
			<Route element={<MainLayout />}>
				<Route path="/" element={<Lad />} />
				<Route path="/lads" element={<Lads />} />
				<Route path="/lads/:ladCode" element={<Lad />} />
				<Route path="/lads/:ladCode/:tab" element={<Lad />} />
				<Route path="/oa" element={<Oa />} />
				<Route path="/sites" element={<Sites />} />
				<Route path="/sites/:id" element={<Site />} />
				<Route path="/report/:ladCode" element={<Report />} />
				<Route path="/report/:ladCode/:tab" element={<Report />} />
				<Route path="/me" element={<Me />} />
			</Route>
		</Routes>
	</Router>
)

export { AppRoutes }
