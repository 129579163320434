/**
 * @generated SignedSource<<516c1cc0e9bfe27c4149cea3a1cbf958>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type GeographyVersions = "bgc" | "bsc" | "buc" | "%future added value";
export type LocalAuthorityFeaturesQuery$variables = {
  version: GeographyVersions;
};
export type LocalAuthorityFeaturesQuery$data = {
  readonly localAuthorityFeatures: ReadonlyArray<{
    readonly geometry: {
      readonly coordinates: any;
      readonly type: string;
    };
    readonly properties: {
      readonly id: string;
      readonly lat: number;
      readonly long: number;
      readonly name: string;
    };
    readonly type: string;
  }>;
};
export type LocalAuthorityFeaturesQuery = {
  response: LocalAuthorityFeaturesQuery$data;
  variables: LocalAuthorityFeaturesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "version"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "version",
        "variableName": "version"
      }
    ],
    "concreteType": "GeoJsonFeature",
    "kind": "LinkedField",
    "name": "localAuthorityFeatures",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "GeoJsonGeometry",
        "kind": "LinkedField",
        "name": "geometry",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "coordinates",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "GeoJsonProperties",
        "kind": "LinkedField",
        "name": "properties",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lat",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "long",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LocalAuthorityFeaturesQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LocalAuthorityFeaturesQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "8c4df12a6ea812dae86164f9315c4dfc",
    "id": null,
    "metadata": {},
    "name": "LocalAuthorityFeaturesQuery",
    "operationKind": "query",
    "text": "query LocalAuthorityFeaturesQuery(\n  $version: GeographyVersions!\n) {\n  localAuthorityFeatures(version: $version) {\n    type\n    geometry {\n      type\n      coordinates\n    }\n    properties {\n      id\n      name\n      lat\n      long\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ab17dac2209ef9131929d9da0c46cff1";

export default node;
