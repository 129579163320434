import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import {
	PopulationByLadCodeQuery as QueryResponseType,
	PopulationByLadCodeQuery$data as QueryResponseType$Data,
} from '../graphql/queries/__generated__/PopulationByLadCodeQuery.graphql'
import { PopulationByLadCodeQuery as QueryRequestType } from '../graphql/queries/Population'
import { BarChart, Model } from '../shared/charts/BarChart'
import { VerticalSpace } from '../shared/layout/Space'
import { Paragraph, Subtitle } from '../shared/Text'

interface PopulationGrowthByAgeGroupChartProps {
	ladCode: string
}

const PopulationGrowthByAgeGroupChart: React.FC<PopulationGrowthByAgeGroupChartProps> = ({ ladCode }) => {
	const data = useLazyLoadQuery<QueryResponseType>(QueryRequestType, {
		ladCode: ladCode,
		rangeSize: 20, // or any appropriate value for rangeSize
	})

	const { labels, datasets } = transformPopulationData(data)

	return (
		<>
			<Subtitle>Population Growth by Age Groups</Subtitle>
			<Paragraph>
				Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
				dolore magna aliquyam erat, sed diam voluptua.
			</Paragraph>
			<VerticalSpace size="sm" />
			<BarChart
				horizontal={true}
				stacked={false}
				model={{
					labels,
					datasets,
				}}
				showLabels={true}
				alpha={1}
			/>
		</>
	)
}
function transformPopulationData(data: QueryResponseType$Data): Model {
	if (!data || !data.populationByLadCode) {
		console.error('Invalid data from PopulationByLadCodeQuery.')
		return { labels: [], datasets: [] }
	}

	const populationData = data.populationByLadCode

	const maleData = populationData.male || []
	const femaleData = populationData.female || []

	// Function to get combined data for a specific year
	const getCombinedDataForYear = (year: number) => {
		const maleYearData = maleData.find((entry) => entry.year === year)
		const femaleYearData = femaleData.find((entry) => entry.year === year)

		if (!maleYearData && !femaleYearData) return null

		const combinedData: { ages: string; population: number }[] = []
		const ageRanges = ['0-19', '20-39', '40-59', '60-79', '80-99']

		ageRanges.forEach((ageRange) => {
			const maleRange = maleYearData ? maleYearData.ranges.find((range) => range.ages === ageRange) : null
			const femaleRange = femaleYearData ? femaleYearData.ranges.find((range) => range.ages === ageRange) : null
			const maleTotal = maleRange ? maleRange.total : 0
			const femaleTotal = femaleRange ? femaleRange.total : 0
			combinedData.push({
				ages: ageRange,
				population: maleTotal + femaleTotal,
			})
		})

		return combinedData as { ages: string; population: number }[]
	}

	const data2024 = getCombinedDataForYear(2024)
	const data2029 = getCombinedDataForYear(2029)

	if (!data2024 || !data2029) {
		console.error('Data for 2024 or 2029 is missing.')
		return { labels: [], datasets: [] }
	}

	// The labels are the age ranges
	const labels = data2024.map((item) => item.ages)

	// Prepare datasets
	const datasets = [
		{
			id: '2024',
			values: data2024.map((item) => item.population),
		},
		{
			id: '2029',
			values: data2029.map((item) => item.population),
		},
	]

	return { labels, datasets }
}

export { PopulationGrowthByAgeGroupChart }
