/**
 * @generated SignedSource<<b8331def0605a7963572d2b4088e47d7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type PopulationBracketsByLadCodeQuery$variables = {
  bracket: string;
  ladCode: string;
  yearsFuture?: number | null | undefined;
  yearsPast?: number | null | undefined;
};
export type PopulationBracketsByLadCodeQuery$data = {
  readonly populationBracketsByLadCode: {
    readonly ladCode: string;
    readonly years: ReadonlyArray<{
      readonly geographies: ReadonlyArray<{
        readonly geoCode: string;
        readonly geoName: string;
        readonly total: number;
      }>;
      readonly year: number;
    }>;
  };
};
export type PopulationBracketsByLadCodeQuery = {
  response: PopulationBracketsByLadCodeQuery$data;
  variables: PopulationBracketsByLadCodeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "bracket"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ladCode"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "yearsFuture"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "yearsPast"
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "bracket",
        "variableName": "bracket"
      },
      {
        "kind": "Variable",
        "name": "ladCode",
        "variableName": "ladCode"
      },
      {
        "kind": "Variable",
        "name": "yearsFuture",
        "variableName": "yearsFuture"
      },
      {
        "kind": "Variable",
        "name": "yearsPast",
        "variableName": "yearsPast"
      }
    ],
    "concreteType": "PopulationBracketsResponse",
    "kind": "LinkedField",
    "name": "populationBracketsByLadCode",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ladCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "PopulationBracketYears",
        "kind": "LinkedField",
        "name": "years",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "year",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PopulationGeographyBrackets",
            "kind": "LinkedField",
            "name": "geographies",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "geoCode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "geoName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "total",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PopulationBracketsByLadCodeQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "PopulationBracketsByLadCodeQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "74f65c33d3dd84865f7e932f47c8cd23",
    "id": null,
    "metadata": {},
    "name": "PopulationBracketsByLadCodeQuery",
    "operationKind": "query",
    "text": "query PopulationBracketsByLadCodeQuery(\n  $ladCode: String!\n  $bracket: String!\n  $yearsPast: Int\n  $yearsFuture: Int\n) {\n  populationBracketsByLadCode(ladCode: $ladCode, bracket: $bracket, yearsPast: $yearsPast, yearsFuture: $yearsFuture) {\n    ladCode\n    years {\n      year\n      geographies {\n        geoCode\n        geoName\n        total\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e31183af88e811ca3491b3e4b695f60b";

export default node;
