import { Libraries } from '@react-google-maps/api'

function getStage(): string {
	const stage = process.env.REACT_APP_STAGE
	if (!stage) {
		throw new Error('STAGE must be provided')
	}
	return stage
}

interface Auth0Config {
	domain: string
	clientId: string
	audience: string
	redirectUri: string
}

function getAuth0Config(): Auth0Config {
	const {
		REACT_APP_AUTH0_DOMAIN: domain,
		REACT_APP_AUTH0_CLIENT_ID: clientId,
		REACT_APP_AUTH0_AUDIENCE: audience,
		REACT_APP_AUTH0_REDIRECT_URI: redirectUri,
	} = process.env
	if (!domain || !clientId || !audience || !redirectUri) {
		throw new Error('Auth0 domain and client ID must be provided')
	}
	return { domain, clientId, audience, redirectUri }
}

function getAuthorizationParams() {
	const { REACT_APP_AUTH0_AUDIENCE: audience, REACT_APP_AUTH0_SCOPE: scope } = process.env
	if (!audience || !scope) {
		throw new Error('Auth0 audience and scope must be provided')
	}
	return { scope, audience, redirect_uri: window.location.origin }
}

function getApiUrl(): string {
	const apiUrl = process.env.REACT_APP_API_URL
	if (!apiUrl) {
		throw new Error('API URL must be provided')
	}
	return apiUrl
}

function getGoogleMapsParams() {
	const { REACT_APP_GOOGLE_MAPS_API_KEY: googleMapsApiKey, REACT_APP_GOOGLE_MAPS_MAP_ID: mapIdsStr } = process.env
	if (!googleMapsApiKey || !mapIdsStr) {
		throw new Error('Google Maps API key and map ID must be provided')
	}
	const libraries: Libraries = ['marker']
	// const libraries: Libraries = []
	const mapIds = mapIdsStr.split(',')

	const gm = {
		id: 'google-map-script',
		googleMapsApiKey,
		mapIds,
		libraries,
	}

	return gm
}

export { getApiUrl, getAuth0Config, getAuthorizationParams, getGoogleMapsParams, getStage }
