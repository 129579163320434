import graphql from 'babel-plugin-relay/macro'

export const GeographiesByCodesQuery = graphql`
	query GeographiesByCodesQuery($codes: [String!]!) {
		geographiesByCodes(codes: $codes) {
			code
			parentCode
			name
			type
		}
	}
`
