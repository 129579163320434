import React from 'react'
import { useFragment, useLazyLoadQuery } from 'react-relay'

import { SitesByLadCodeQuery as SitesByLadCodeQueryResponseType } from '../../graphql/queries/__generated__/SitesByLadCodeQuery.graphql'
import { SitesByLadCodeQuery as SitesByLadCodeQueryRequestType } from '../../graphql/queries/SitesByLadCode'
import { LineChart } from '../../shared/charts/LineChart'
import { VerticalSpace } from '../../shared/layout/Space'
import { ParagraphSmallBold, TitleBox } from '../../shared/Text'
import {
	ElderlyBedsForecastSiteFragment$data,
	ElderlyBedsForecastSiteFragment$key,
} from './__generated__/ElderlyBedsForecastSiteFragment.graphql'
import { ElderlyBedsForecastSiteFragment } from './ElderlyBedsForecastSiteFragment'

interface ElderlyBedsForecastProps {
	ladCode: string
	showTitleWrapper?: boolean
}

const ElderlyBedsForecast: React.FC<ElderlyBedsForecastProps> = ({ ladCode, showTitleWrapper = true }) => {
	const sitesData = useLazyLoadQuery<SitesByLadCodeQueryResponseType>(SitesByLadCodeQueryRequestType, {
		ladCode: ladCode,
	})

	const sites = sitesData.sitesByLadCode.map((site: ElderlyBedsForecastSiteFragment$key) => {
		return useFragment(ElderlyBedsForecastSiteFragment, site)
	})

	const { labels, datasets } = getElderlyBedsForecast(sites)

	return (
		<>
			{showTitleWrapper && (
				<>
					<TitleBox>Elderly Bed Forecast</TitleBox>
					<ParagraphSmallBold>Predicted Beds in Care Homes for +65 age band</ParagraphSmallBold>
					<VerticalSpace size="sm" />
				</>
			)}
			<LineChart
				model={{
					labels,
					datasets,
				}}
				aspectRatio={2}
			/>
		</>
	)
}

function getElderlyBedsForecast(data: ElderlyBedsForecastSiteFragment$data[]) {
	const sites = data

	// Check if data is available
	if (!sites || !Array.isArray(sites)) {
		return { labels: [], datasets: [] }
	}

	// Define the range of years for historical data
	const startYear = 2010
	const endYear = 2024

	// Initialize an object to store the results for each year
	const yearData: { [year: number]: { totalBeds: number } } = {}

	// Initialize yearData for each year
	for (let year = startYear; year <= endYear; year++) {
		yearData[year] = {
			totalBeds: 0,
		}
	}

	// Iterate through each site, filtering for those that specialize in care homes for the elderly
	sites.forEach((site) => {
		const registrationDate = site.registrationDate
		if (!registrationDate) {
			return
		}

		const registrationYear = new Date(registrationDate).getFullYear()

		// Only process if registrationYear is valid and within the range
		if (registrationYear && registrationYear <= endYear) {
			for (let year = registrationYear; year <= endYear; year++) {
				// Increment total number of beds for that year
				yearData[year].totalBeds += site.numberOfBeds || 0
			}
		}
	})

	// Log the result for debugging

	// Prepare historical data for regression
	const historicalYears: number[] = []
	const historicalBeds: number[] = []

	for (let year = startYear; year <= endYear; year++) {
		historicalYears.push(year)
		historicalBeds.push(yearData[year].totalBeds)
	}

	// Perform linear regression
	const regressionResult = linearRegression(historicalYears, historicalBeds)

	// Use regression to predict future beds up to 2034
	const forecastYears = [2016, 2024, 2032]
	const forecastBeds: number[] = forecastYears.map((year) => {
		return Math.round(regressionResult.slope * year + regressionResult.intercept)
	})

	// Prepare labels and datasets for the chart
	const labels: string[] = forecastYears.map((year) => year.toString())
	const datasets = [
		{
			id: 'Predicted Beds',
			values: forecastBeds,
		},
	]

	return {
		labels: labels,
		datasets: datasets,
	}
}

// Simple linear regression function
function linearRegression(x: number[], y: number[]) {
	const n = x.length
	const sumX = x.reduce((a, b) => a + b, 0)
	const sumY = y.reduce((a, b) => a + b, 0)
	const sumXY = x.reduce((sum, xi, idx) => sum + xi * y[idx], 0)
	const sumX2 = x.reduce((sum, xi) => sum + xi * xi, 0)

	const denominator = n * sumX2 - sumX * sumX
	if (denominator === 0) {
		throw new Error('Denominator in linear regression calculation is zero.')
	}

	const slope = (n * sumXY - sumX * sumY) / denominator
	const intercept = (sumY - slope * sumX) / n

	return { slope, intercept }
}

export { ElderlyBedsForecast }
