/**
 * @generated SignedSource<<5a15eb770c18c1c053f8d3f2151e5c86>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OutputAreaByLatLongQuery$variables = {
  lat: string;
  long: string;
};
export type OutputAreaByLatLongQuery$data = {
  readonly outputAreaByLatLong: {
    readonly " $fragmentSpreads": FragmentRefs<"SelectedOaFragment">;
  };
};
export type OutputAreaByLatLongQuery = {
  response: OutputAreaByLatLongQuery$data;
  variables: OutputAreaByLatLongQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "lat"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "long"
},
v2 = [
  {
    "kind": "Variable",
    "name": "lat",
    "variableName": "lat"
  },
  {
    "kind": "Variable",
    "name": "long",
    "variableName": "long"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "OutputAreaByLatLongQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "OutputArea",
        "kind": "LinkedField",
        "name": "outputAreaByLatLong",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SelectedOaFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "OutputAreaByLatLongQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "OutputArea",
        "kind": "LinkedField",
        "name": "outputAreaByLatLong",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ladCode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "oaCode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "geometry",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9e68afe1f2ce1857823221089c50a3ef",
    "id": null,
    "metadata": {},
    "name": "OutputAreaByLatLongQuery",
    "operationKind": "query",
    "text": "query OutputAreaByLatLongQuery(\n  $long: String!\n  $lat: String!\n) {\n  outputAreaByLatLong(long: $long, lat: $lat) {\n    ...SelectedOaFragment\n  }\n}\n\nfragment SelectedOaFragment on OutputArea {\n  ladCode\n  oaCode\n  geometry\n}\n"
  }
};
})();

(node as any).hash = "4e02c38f0193d5639767c4b30013b44d";

export default node;
