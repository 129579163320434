import React from 'react'

import { LaTable } from '../../shared/LaTable/LaTable'
import { SpaceBetween } from '../../shared/layout/Alignment'
import { Container } from '../../shared/layout/Grid'
import { VerticalSpace } from '../../shared/layout/Space'
import { Loading } from '../../shared/Loading'
import { Panel } from '../../shared/Panel'
import { PageTitle } from '../../shared/Text'

const Lads: React.FC = () => {
	return (
		<>
			<Container>
				<SpaceBetween>
					<PageTitle>Local Authorities</PageTitle>
				</SpaceBetween>
				<VerticalSpace size="lg" />

				<Panel>
					<LaTable />
				</Panel>
			</Container>
		</>
	)
}

const Suspended: React.FC = () => {
	return (
		<React.Suspense fallback={<Loading height="80vh" />}>
			<Lads />
		</React.Suspense>
	)
}

export { Suspended as Lads }
