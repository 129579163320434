import { useAuth0 } from '@auth0/auth0-react' // Import the Auth0 hook
import React from 'react'

import { SpaceBetween } from '../../shared/layout/Alignment'
import { Container } from '../../shared/layout/Grid'
import { VerticalSpace } from '../../shared/layout/Space'
import { Loading } from '../../shared/Loading'
import { Panel } from '../../shared/Panel'
import { PageTitle, Paragraph } from '../../shared/Text'

const Me: React.FC = () => {
	const { user, isAuthenticated, isLoading, logout } = useAuth0() // Access the user, auth status, and loading state

	if (isLoading) {
		return <div>Loading...</div> // Show a loading state while user data is being fetched
	}

	if (!isAuthenticated) {
		return <div>Please log in to view your profile.</div> // Prompt the user to log in if not authenticated
	}

	return (
		<Container>
			<Panel>
				<SpaceBetween>
					<PageTitle>{user?.name}</PageTitle>

					<img src={user?.picture} alt="Profile" style={{ borderRadius: '50%', width: '100px', height: '100px' }} />
				</SpaceBetween>
				<Paragraph>
					<strong>Sub:</strong> {user?.sub}
				</Paragraph>
				<Paragraph>
					<strong>Email:</strong> {user?.email}
				</Paragraph>
				<Paragraph>
					<strong>Nickname:</strong> {user?.nickname}
				</Paragraph>
				<VerticalSpace size="lg" />
				<button
					className="btn btn-outline-secondary"
					onClick={() =>
						logout({
							logoutParams: { returnTo: window.location.origin },
						})
					}
				>
					Logout
				</button>
			</Panel>
		</Container>
	)
}

const Suspended: React.FC = () => {
	return (
		<React.Suspense fallback={<Loading height="80vh" />}>
			<Me />
		</React.Suspense>
	)
}

export { Suspended as Me }
