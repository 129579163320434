/**
 * @generated SignedSource<<a4e432ca203e29e4998b8d2a5d19a49e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type HouseholdIncomeByLadCodeQuery$variables = {
  ladCode: string;
};
export type HouseholdIncomeByLadCodeQuery$data = {
  readonly householdIncomeByLadCode: {
    readonly ladCode: string;
    readonly years: ReadonlyArray<{
      readonly index: number;
      readonly nationalMedianPerHead: number;
      readonly nationalMedianTotalInMillions: number;
      readonly perHead: number;
      readonly population: number;
      readonly totalInMillions: number;
      readonly year: number;
    }>;
  };
};
export type HouseholdIncomeByLadCodeQuery = {
  response: HouseholdIncomeByLadCodeQuery$data;
  variables: HouseholdIncomeByLadCodeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ladCode"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "ladCode",
        "variableName": "ladCode"
      }
    ],
    "concreteType": "HouseholdIncome",
    "kind": "LinkedField",
    "name": "householdIncomeByLadCode",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ladCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "HouseholdIncomeData",
        "kind": "LinkedField",
        "name": "years",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "year",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalInMillions",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "population",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "perHead",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "index",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nationalMedianTotalInMillions",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nationalMedianPerHead",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "HouseholdIncomeByLadCodeQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "HouseholdIncomeByLadCodeQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "fac2024939adb09c7bc1d4e2db57f656",
    "id": null,
    "metadata": {},
    "name": "HouseholdIncomeByLadCodeQuery",
    "operationKind": "query",
    "text": "query HouseholdIncomeByLadCodeQuery(\n  $ladCode: String!\n) {\n  householdIncomeByLadCode(ladCode: $ladCode) {\n    ladCode\n    years {\n      year\n      totalInMillions\n      population\n      perHead\n      index\n      nationalMedianTotalInMillions\n      nationalMedianPerHead\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "61a24ce2ead8b05743598f9451c58b97";

export default node;
