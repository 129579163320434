import React from 'react'

import { LeftAligned } from '../../../shared/layout/Alignment'
import { Col, Container, Row } from '../../../shared/layout/Grid'
import { HorizontalSpace, VerticalSpace } from '../../../shared/layout/Space'
import { Loading } from '../../../shared/Loading'
import { Panel } from '../../../shared/Panel'
import { SitesTable } from '../../../shared/sitesTable/SitesTable'
import { PageTitle } from '../../../shared/Text'

interface CareHomesProps {
	ladCode: string
}

const CareHomes: React.FC<CareHomesProps> = ({ ladCode }) => {
	return (
		<Container>
			<VerticalSpace size="xl" />
			<Row>
				<LeftAligned>
					<HorizontalSpace size="md" />
					<Col count={7}>
						<PageTitle>Care Homes</PageTitle>
					</Col>
					<HorizontalSpace size="md" />
				</LeftAligned>
			</Row>
			<Row>
				<Col count={12}>
					<Panel>
						<SitesTable ladCode={ladCode} />
					</Panel>
				</Col>
			</Row>
		</Container>
	)
}

const Suspended: React.FC<CareHomesProps> = ({ ladCode }) => {
	return (
		<React.Suspense fallback={<Loading />}>
			<CareHomes ladCode={ladCode} />
		</React.Suspense>
	)
}

export { Suspended as CareHomes }
