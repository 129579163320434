/**
 * @generated SignedSource<<39c47881521e258812925ed2144dd51d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type NomisTenureTypesQuery$variables = {
  geography: string;
};
export type NomisTenureTypesQuery$data = {
  readonly nomis: {
    readonly metadata: ReadonlyArray<{
      readonly id: string;
      readonly label: string;
      readonly type: string;
    }>;
    readonly rows: ReadonlyArray<{
      readonly date: string;
      readonly dimensions: any;
      readonly geography: string;
      readonly measures: string;
      readonly value: number | null | undefined;
    }>;
    readonly title: string;
    readonly url: string;
  };
};
export type NomisTenureTypesQuery = {
  response: NomisTenureTypesQuery$data;
  variables: NomisTenureTypesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "geography"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Literal",
            "name": "dataset",
            "value": "NM_2072_1"
          },
          {
            "kind": "Variable",
            "name": "geography",
            "variableName": "geography"
          },
          {
            "kind": "Literal",
            "name": "measures",
            "value": "20301"
          },
          {
            "kind": "Literal",
            "name": "parents",
            "value": true
          },
          {
            "kind": "Literal",
            "name": "time",
            "value": "latest"
          },
          {
            "kind": "Literal",
            "name": "useLabels",
            "value": false
          }
        ],
        "kind": "ObjectValue",
        "name": "query"
      }
    ],
    "concreteType": "NomisResponse",
    "kind": "LinkedField",
    "name": "nomis",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "NomisMetadata",
        "kind": "LinkedField",
        "name": "metadata",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "label",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "NomisRow",
        "kind": "LinkedField",
        "name": "rows",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "date",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dimensions",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "geography",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "measures",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NomisTenureTypesQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NomisTenureTypesQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "026189ab5f15d1ca91ad53edaa85b514",
    "id": null,
    "metadata": {},
    "name": "NomisTenureTypesQuery",
    "operationKind": "query",
    "text": "query NomisTenureTypesQuery(\n  $geography: String!\n) {\n  nomis(query: {dataset: \"NM_2072_1\", geography: $geography, parents: true, time: \"latest\", measures: \"20301\", useLabels: false}) {\n    metadata {\n      id\n      label\n      type\n    }\n    rows {\n      date\n      dimensions\n      geography\n      measures\n      value\n    }\n    title\n    url\n  }\n}\n"
  }
};
})();

(node as any).hash = "0faf403fdcb6b5596ec98c3d9377b185";

export default node;
