import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import {
	PopulationBracketsByLadCodeQuery as PopulationQueryType,
	PopulationBracketsByLadCodeQuery$data as PopulationQueryDataType,
} from '../graphql/queries/__generated__/PopulationBracketsByLadCodeQuery.graphql'
import {
	StatXDementiaAACountQuery as DementiaQueryType,
	StatXDementiaAACountQuery$data as DementiaQueryDataType,
} from '../graphql/queries/__generated__/StatXDementiaAACountQuery.graphql'
import { PopulationBracketsByLadCodeQuery } from '../graphql/queries/PopulationBrackets'
import { StatXDementiaAACountQuery } from '../graphql/queries/StatXDementiaAACount'
import { LineChart } from '../shared/charts/LineChart'
import { VerticalSpace } from '../shared/layout/Space'
import { ParagraphSmallBold, TitleBox } from '../shared/Text'

interface DementiaPrevalenceRateChartProps {
	ladCode: string
	showTitleWrapper?: boolean
}

const DementiaPrevalenceRateChart: React.FC<DementiaPrevalenceRateChartProps> = ({
	ladCode,
	showTitleWrapper = true,
}) => {
	// Fetch elderly population data
	const populationData = useLazyLoadQuery<PopulationQueryType>(PopulationBracketsByLadCodeQuery, {
		ladCode: ladCode,
		bracket: '65-1000',
		yearsPast: 5,
		yearsFuture: 0,
	})

	// Fetch dementia count data
	const dementiaData = useLazyLoadQuery<DementiaQueryType>(StatXDementiaAACountQuery, {
		geography: ladCode,
	})

	// Process data
	const { labels, datasets } = transformDementiaPrevalenceRateData(populationData, dementiaData)

	return (
		<>
			{showTitleWrapper && (
				<>
					<TitleBox>Dementia Prevalence Rate</TitleBox>
					<ParagraphSmallBold>
						Percentage of elderly population diagnosed with severe dementia over time.
					</ParagraphSmallBold>
					<VerticalSpace size="sm" />
				</>
			)}
			<LineChart
				model={{
					labels,
					datasets,
				}}
				prefix=""
				suffix="%"
				dataLabelDisplayOption="showAll"
				aspectRatio={0.75}
			/>
		</>
	)
}

function transformDementiaPrevalenceRateData(
	populationData: PopulationQueryDataType,
	dementiaData: DementiaQueryDataType,
): { labels: string[]; datasets: { id: string; values: (number | null)[] }[] } {
	// Validate population data
	if (
		!populationData ||
		!populationData.populationBracketsByLadCode ||
		!populationData.populationBracketsByLadCode.years
	) {
		console.error('Invalid population data structure.')
		return { labels: [], datasets: [] }
	}

	// Validate dementia data
	if (!dementiaData || !dementiaData.statx || !dementiaData.statx.rows || !dementiaData.statx.headers) {
		console.error('Invalid dementia data structure.')
		return { labels: [], datasets: [] }
	}

	const populationYears = populationData.populationBracketsByLadCode.years
	const dementiaRows = dementiaData.statx.rows

	// Build a map for elderly population data: { year: { geoName: total } }
	const elderlyPopulationMap: { [year: string]: { [geoName: string]: number } } = {}
	populationYears.forEach((yearEntry) => {
		const year = yearEntry.year.toString()
		if (!elderlyPopulationMap[year]) {
			elderlyPopulationMap[year] = {}
		}
		yearEntry.geographies.forEach((geo) => {
			elderlyPopulationMap[year][geo.geoName] = geo.total
		})
	})

	// Build a map for dementia counts: { year: { geoName: count } }
	const dementiaMap: { [year: string]: { [geoName: string]: number } } = {}

	// Find indices of required headers in dementia data
	const dementiaHeaders = dementiaData.statx.headers
	const quarterIndex = dementiaHeaders.indexOf('Quarter')
	const geographyIndex = dementiaHeaders.indexOf('National - Regional - LA - OAs')
	const valueIndex = dementiaHeaders.indexOf('AA (entitled) - 2011 Geographies')

	if (quarterIndex === -1 || geographyIndex === -1 || valueIndex === -1) {
		console.error('Required headers not found in dementia data.')
		return { labels: [], datasets: [] }
	}

	dementiaRows.forEach((row) => {
		const dateStr = row[quarterIndex] as string // e.g., "Feb-19"
		const geoName = row[geographyIndex] as string
		const countStr = row[valueIndex] as string // Dementia count

		// Convert "Feb-YY" to full year (e.g., "Feb-19" to "2019")
		const year = '20' + dateStr.slice(-2)

		if (!dementiaMap[year]) {
			dementiaMap[year] = {}
		}
		dementiaMap[year][geoName] = parseInt(countStr, 10)
	})

	// Collect all years and geographies
	const yearsSet = new Set<string>()
	const geographiesSet = new Set<string>()

	// Define the years to include
	const yearsRange = ['2020', '2021', '2022', '2023', '2024']
	yearsRange.forEach((year) => {
		yearsSet.add(year)
	})

	// Collect geographies from dementia data
	dementiaRows.forEach((row) => {
		const geoName = row[geographyIndex] as string
		geographiesSet.add(geoName)
	})

	// Convert sets to arrays and sort
	const labels = Array.from(yearsSet).sort()
	const geographies = Array.from(geographiesSet)

	// Build datasets
	const datasets: { id: string; values: (number | null)[] }[] = []

	geographies.forEach((geoName) => {
		// Collect values for each year
		const values = labels.map((year) => {
			const dementiaCount =
				dementiaMap[year] && dementiaMap[year][geoName] !== undefined ? dementiaMap[year][geoName] : null
			const elderlyPopulation =
				elderlyPopulationMap[year] && elderlyPopulationMap[year][geoName] !== undefined
					? elderlyPopulationMap[year][geoName]
					: null

			if (dementiaCount !== null && elderlyPopulation !== null && elderlyPopulation > 0) {
				const prevalenceRate = (dementiaCount / elderlyPopulation) * 100
				return parseFloat(prevalenceRate.toFixed(2)) // Round to two decimal places
			} else {
				return null
			}
		})

		// Only include datasets with at least one non-null value
		if (values.some((value) => value !== null)) {
			datasets.push({
				id: geoName,
				values: values,
			})
		}
	})

	return { labels, datasets }
}

export { DementiaPrevalenceRateChart }
