import React from 'react'
import { useFragment, useLazyLoadQuery } from 'react-relay'

import { SitesByLadCodeQuery } from '../../graphql/queries/__generated__/SitesByLadCodeQuery.graphql'
import { SitesByLadCodeQuery as QueryRequestType } from '../../graphql/queries/SitesByLadCode'
import { BarChart, Model } from '../../shared/charts/BarChart'
import { VerticalSpace } from '../../shared/layout/Space'
import { ParagraphSmallBold, TitleBox } from '../../shared/Text'
import {
	ElderlySitesChartSiteFragment$data,
	ElderlySitesChartSiteFragment$key,
} from './__generated__/ElderlySitesChartSiteFragment.graphql'
import { ElderlySitesChartSiteFragment } from './ElderlySitesChartSiteFragment'

interface ElderlySitesChartProps {
	ladCode: string
	showTitleWrapper?: boolean
}

const ElderlySitesChart: React.FC<ElderlySitesChartProps> = ({ ladCode, showTitleWrapper = true }) => {
	const sitesData = useLazyLoadQuery<SitesByLadCodeQuery>(QueryRequestType, {
		ladCode: ladCode,
	})

	const sites = sitesData.sitesByLadCode.map((site: ElderlySitesChartSiteFragment$key) => {
		return useFragment(ElderlySitesChartSiteFragment, site)
	})

	const { labels, datasets } = getElderlySites(sites)

	return (
		<>
			{showTitleWrapper && (
				<>
					<TitleBox>Elderly Care Home Sites</TitleBox>
					<ParagraphSmallBold>Count of Care Home Sites for Ageband 65+ in 2014 and 2024</ParagraphSmallBold>
					<VerticalSpace size="sm" />
				</>
			)}
			<BarChart
				horizontal={false}
				stacked={false}
				model={{
					labels,
					datasets,
				}}
				showLabels={true}
				alpha={1}
				aspectRatio={2}
			/>
		</>
	)
}

function getElderlySites(sites: ElderlySitesChartSiteFragment$data[]): Model {
	// Check if data is available
	if (!sites || !Array.isArray(sites)) {
		return { labels: [], datasets: [] }
	}

	// Define the years of interest
	const yearsOfInterest = [2014, 2024]

	// Initialize an object to store the results for each year
	const yearData: { [year: number]: { totalSites: number } } = {}

	// Initialize yearData for each year of interest
	yearsOfInterest.forEach((year) => {
		yearData[year] = {
			totalSites: 0,
		}
	})

	// Iterate through each site, filtering for those that specialize in care homes for the elderly
	sites.forEach((site) => {
		const registrationDate = site.registrationDate
		if (!registrationDate) {
			return
		}

		const registrationYear = new Date(registrationDate).getFullYear()

		// Process for each year of interest
		yearsOfInterest.forEach((year) => {
			if (registrationYear <= year) {
				// Increment total number of sites for that year
				yearData[year].totalSites += 1
			}
		})
	})

	// Prepare labels and datasets
	const labels: string[] = []
	const totalSitesValues: number[] = []

	yearsOfInterest.forEach((year) => {
		labels.push(year.toString())
		totalSitesValues.push(yearData[year].totalSites)
	})

	const datasets = [
		{
			id: 'Total Sites',
			values: totalSitesValues,
		},
	]

	return {
		labels: labels,
		datasets: datasets,
	}
}

export { ElderlySitesChart }
