/**
 * @generated SignedSource<<3a8e8fc339da81a6cd9964e515fd7ba1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ElderlyCareStatsQuery$variables = Record<PropertyKey, never>;
export type ElderlyCareStatsQuery$data = {
  readonly elderlyCareStats: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"ElderlyCareStatsFragment" | "LaTableElderlyCareStatsFragment">;
  }>;
};
export type ElderlyCareStatsQuery = {
  response: ElderlyCareStatsQuery$data;
  variables: ElderlyCareStatsQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ElderlyCareStatsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ElderlyCareStats",
        "kind": "LinkedField",
        "name": "elderlyCareStats",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ElderlyCareStatsFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "LaTableElderlyCareStatsFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ElderlyCareStatsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ElderlyCareStats",
        "kind": "LinkedField",
        "name": "elderlyCareStats",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ladCode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "populationOver65",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numberOfBeds",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a5301a1c0778218e17b1485703ccb4ff",
    "id": null,
    "metadata": {},
    "name": "ElderlyCareStatsQuery",
    "operationKind": "query",
    "text": "query ElderlyCareStatsQuery {\n  elderlyCareStats {\n    ...ElderlyCareStatsFragment\n    ...LaTableElderlyCareStatsFragment\n  }\n}\n\nfragment ElderlyCareStatsFragment on ElderlyCareStats {\n  ladCode\n  populationOver65\n  numberOfBeds\n}\n\nfragment LaTableElderlyCareStatsFragment on ElderlyCareStats {\n  ladCode\n  populationOver65\n  numberOfBeds\n}\n"
  }
};

(node as any).hash = "551a5cfc16c0f819b33193418a200138";

export default node;
