/**
 * @generated SignedSource<<b3a94d4079ec583f19e0424482e4fa1c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type StatXDementiaAACountQuery$variables = {
  geography: string;
};
export type StatXDementiaAACountQuery$data = {
  readonly statx: {
    readonly headers: ReadonlyArray<string>;
    readonly rows: ReadonlyArray<ReadonlyArray<string>>;
    readonly title: string;
    readonly url: string;
  };
};
export type StatXDementiaAACountQuery = {
  response: StatXDementiaAACountQuery$data;
  variables: StatXDementiaAACountQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "geography"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Literal",
            "name": "database",
            "value": "AA_Entitled_New"
          },
          {
            "kind": "Literal",
            "name": "dimensions",
            "value": [
              {
                "field": "F_AA_QTR_New:DATE_NAME",
                "total": false,
                "values": [
                  "C_AA_QTR_New:202002",
                  "C_AA_QTR_New:202102",
                  "C_AA_QTR_New:202202",
                  "C_AA_QTR_New:202302",
                  "C_AA_QTR_New:202402"
                ]
              },
              {
                "field": "V_F_AA_Entitled_New:DISABLED",
                "total": false,
                "values": [
                  "C_AA_DISABLED:28"
                ]
              }
            ]
          },
          {
            "kind": "Variable",
            "name": "geography",
            "variableName": "geography"
          },
          {
            "kind": "Literal",
            "name": "measures",
            "value": [
              {
                "key": "count",
                "value": "V_F_AA_Entitled_New"
              }
            ]
          },
          {
            "kind": "Literal",
            "name": "table",
            "value": "V_F_AA_Entitled_New"
          }
        ],
        "kind": "ObjectValue",
        "name": "query"
      }
    ],
    "concreteType": "StatxQueryResponse",
    "kind": "LinkedField",
    "name": "statx",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "headers",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rows",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "StatXDementiaAACountQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "StatXDementiaAACountQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "73e3b6784bf50c3fcc2efd066c4bbddf",
    "id": null,
    "metadata": {},
    "name": "StatXDementiaAACountQuery",
    "operationKind": "query",
    "text": "query StatXDementiaAACountQuery(\n  $geography: String!\n) {\n  statx(query: {database: \"AA_Entitled_New\", table: \"V_F_AA_Entitled_New\", geography: $geography, measures: [{key: \"count\", value: \"V_F_AA_Entitled_New\"}], dimensions: [{field: \"F_AA_QTR_New:DATE_NAME\", values: [\"C_AA_QTR_New:202002\", \"C_AA_QTR_New:202102\", \"C_AA_QTR_New:202202\", \"C_AA_QTR_New:202302\", \"C_AA_QTR_New:202402\"], total: false}, {field: \"V_F_AA_Entitled_New:DISABLED\", values: [\"C_AA_DISABLED:28\"], total: false}]}) {\n    headers\n    rows\n    title\n    url\n  }\n}\n"
  }
};
})();

(node as any).hash = "a488dcc04581453cd40b81752a5aeb8c";

export default node;
