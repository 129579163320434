// Space.tsx
import React from 'react'

interface SpaceProps {
	size: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'
}

const width = {
	xxs: '0.25rem',
	xs: '0.5rem',
	sm: '1rem',
	md: '1.5rem',
	lg: '2rem',
	xl: '3rem',
	xxl: '4rem',
}

const HorizontalSpace: React.FC<SpaceProps> = ({ size }) => {
	return <div style={{ margin: width[size] }} />
}

interface VerticalSpaceProps {
	size: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'
}

const height = {
	xxs: '0.25rem',
	xs: '0.5rem',
	sm: '1rem',
	md: '1.5rem',
	lg: '2rem',
	xl: '3rem',
	xxl: '4rem',
}

const VerticalSpace: React.FC<VerticalSpaceProps> = ({ size }) => {
	return <div style={{ height: height[size] }} />
}

export { HorizontalSpace, VerticalSpace }
