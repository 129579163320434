import React from 'react'

import { theme } from '../Theme'

type CardPadding = 8 | 16 | 24 | 32 | 40

interface CardProps {
	children: React.ReactNode
	padding?: string | CardPadding
	height?: string | number
}

const Card: React.FC<CardProps> = ({ children, padding = 40, height = '100%' }) => {
	const styles = {
		padding,
		backgroundColor: theme.colors.secondarySurface,
		borderRadius: 10,
		border: '1px solid #F2F2F2',
		height,
	}

	return (
		<div style={styles}>
			<div>{children}</div>
		</div>
	)
}

export { Card }
