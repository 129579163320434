import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import {
	StatXDementiaAACountQuery as QueryResponseType,
	StatXDementiaAACountQuery$data as QueryResponseType$Data,
} from '../graphql/queries/__generated__/StatXDementiaAACountQuery.graphql'
import { StatXDementiaAACountQuery as QueryRequestType } from '../graphql/queries/StatXDementiaAACount'
import { BarChart, Model } from '../shared/charts/BarChart'
import { VerticalSpace } from '../shared/layout/Space'
import { Paragraph, Subtitle } from '../shared/Text'

interface ElderlyWithSevereDementiaChartProps {
	ladCode: string
}

const ElderlyWithSevereDementiaChart: React.FC<ElderlyWithSevereDementiaChartProps> = ({ ladCode }) => {
	const data = useLazyLoadQuery<QueryResponseType>(QueryRequestType, {
		geography: ladCode,
	})

	const { labels, datasets } = transformStatx(data)

	return (
		<>
			<Subtitle>Elderly with Severe Dementia</Subtitle>
			<Paragraph>The number of elderly people with severe dementia over time.</Paragraph>
			<VerticalSpace size="sm" />
			<BarChart
				horizontal={false}
				stacked={false}
				model={{
					labels,
					datasets,
				}}
				showLabels={true}
				alpha={0.9}
				aspectRatio={1}
			/>
		</>
	)
}

function transformStatx(data: QueryResponseType$Data): Model {
	if (!data || !data.statx || !data.statx.rows || !data.statx.headers) {
		return { labels: [], datasets: [] }
	}

	const headers = data.statx.headers
	const rows = data.statx.rows

	// Find the indices of the headers we need
	const quarterIndex = headers.indexOf('Quarter')
	const conditionIndex = headers.indexOf('Main Disabling Condition')
	const geographyIndex = headers.indexOf('National - Regional - LA - OAs')
	const valueIndex = headers.indexOf('AA (entitled) - 2011 Geographies')

	if (quarterIndex === -1 || conditionIndex === -1 || geographyIndex === -1 || valueIndex === -1) {
		console.error('Required headers not found in data.')
		return { labels: [], datasets: [] }
	}

	// Assume the first geography in the data is the target geography
	const targetGeography = rows[0][geographyIndex]

	// Filter rows for the target geography and "Dementia" condition
	const filteredRows = rows.filter(
		(row) => row[geographyIndex] === targetGeography && row[conditionIndex] === 'Dementia',
	)

	if (filteredRows.length === 0) {
		console.warn(`No data found for geography: ${targetGeography}`)
		return { labels: [], datasets: [] }
	}

	// Collect data
	const labelsSet = new Set<string>()
	const dataMap: { [key: string]: { [key: string]: number } } = {}

	filteredRows.forEach((row) => {
		const quarter = row[quarterIndex]
		// Extract year from quarter (e.g., "Feb-19" -> "2019")
		const year = '20' + quarter.slice(-2)
		labelsSet.add(year)

		const value = parseInt(row[valueIndex])

		if (!dataMap['Dementia']) {
			dataMap['Dementia'] = {}
		}
		dataMap['Dementia'][year] = value
	})

	// Prepare labels sorted in ascending order
	const labels: string[] = Array.from(labelsSet).sort()

	// Prepare datasets
	const datasets = [
		{
			id: 'Dementia',
			values: labels.map((year) => {
				const value = dataMap['Dementia'][year]
				return value !== undefined ? value : null
			}),
		},
	]

	return { labels, datasets }
}

export { ElderlyWithSevereDementiaChart }
