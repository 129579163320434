import React from 'react'
import { ChevronDown, ChevronUp } from 'react-feather'
import { Cell, useSortBy, useTable } from 'react-table'

export interface RowData {
	id: string
}

interface DataTableProps {
	columns: any // Adjust the type according to your column structure
	data: ReadonlyArray<RowData>
	onRowClick: (id: string) => void // Add onRowClick prop
	onCell: (props: Cell<RowData>, i: number) => React.ReactNode // Add onCell prop
}

const DataTable: React.FC<DataTableProps> = ({ columns, data, onRowClick, onCell }) => {
	// Use the useTable and useSortBy hooks to create the table instance
	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
		{ columns, data },
		useSortBy, // Enable sorting
	)

	return (
		<div className="table-responsive">
			<table {...getTableProps()} className="table table-hover table-sm">
				<thead>
					{headerGroups.map((headerGroup) => (
						<tr {...headerGroup.getHeaderGroupProps()} key={'header'}>
							{headerGroup.headers.map((column: any) => {
								const { key, ...columnProps } = column.getHeaderProps(column.getSortByToggleProps()) // Enable sorting on header
								return (
									<th {...columnProps} key={key}>
										{column.render('Header')}
										<span>{column.isSorted ? column.isSortedDesc ? <ChevronDown /> : <ChevronUp /> : ''}</span>
									</th>
								)
							})}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{rows.map((row) => {
						prepareRow(row)
						return (
							<tr
								{...row.getRowProps()}
								key={row.id}
								onClick={() => onRowClick(row.original.id)}
								style={{ cursor: 'pointer' }}
							>
								{row.cells.map(onCell)}
							</tr>
						)
					})}
				</tbody>
			</table>
		</div>
	)
}

export { DataTable }
