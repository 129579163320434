/**
 * @generated SignedSource<<0203c9b81794419967f68d9c88c1dd37>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ElderlyBedsTimeSeriesChartSiteFragment$data = {
  readonly numberOfBeds: number | null | undefined;
  readonly registrationDate: any | null | undefined;
  readonly " $fragmentType": "ElderlyBedsTimeSeriesChartSiteFragment";
};
export type ElderlyBedsTimeSeriesChartSiteFragment$key = {
  readonly " $data"?: ElderlyBedsTimeSeriesChartSiteFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ElderlyBedsTimeSeriesChartSiteFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ElderlyBedsTimeSeriesChartSiteFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registrationDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numberOfBeds",
      "storageKey": null
    }
  ],
  "type": "Site",
  "abstractKey": null
};

(node as any).hash = "49af168829d57a7d9a873c5cbe62cc3c";

export default node;
