import graphql from 'babel-plugin-relay/macro'

export const SitesByLadCodeQuery = graphql`
	query SitesByLadCodeQuery($ladCode: String!) {
		sitesByLadCode(ladCode: $ladCode) {
			...ReportSitesByLadQueryFragment
			...SitesTableFragment
			...ElderlyBedShortagesForecastChartSiteFragment
			...ElderlyBedsForecastSiteFragment
			...ElderlySitesChartSiteFragment
			...ElderlyCareForecastSiteFragment
			...ElderlyBedsTimeSeriesChartSiteFragment
		}
	}
`
