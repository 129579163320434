import React from 'react'

import { PopulationGrowthByAgeGroupChart } from '../../../charts/PopulationGrowthByAgeGroupChart'
import { SocialGradeChart } from '../../../charts/SocialGradeChart'
import { LeftAligned } from '../../../shared/layout/Alignment'
import { Col, Container, Row } from '../../../shared/layout/Grid'
import { HorizontalSpace, VerticalSpace } from '../../../shared/layout/Space'
import { Loading } from '../../../shared/Loading'
import { Panel } from '../../../shared/Panel'
import { PageTitle, Paragraph } from '../../../shared/Text'

interface PopulationProps {
	ladCode: string
}

const Population: React.FC<PopulationProps> = ({ ladCode }) => {
	return (
		<Container>
			<VerticalSpace size="xl" />
			<Row>
				<LeftAligned>
					<HorizontalSpace size="md" />
					<Col count={7}>
						<PageTitle>Population</PageTitle>
						<Paragraph>
							Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
							et dolore magna aliquyam erat, sed diam voluptua. Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
							sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
						</Paragraph>
					</Col>
				</LeftAligned>
			</Row>
			<VerticalSpace size="xl" />
			<Row>
				<Col count={6}>
					<Panel>
						<PopulationGrowthByAgeGroupChart ladCode={ladCode} />
					</Panel>
				</Col>
				<Col count={6}>
					<Panel>
						<SocialGradeChart ladCode={ladCode} />
					</Panel>
				</Col>
			</Row>
		</Container>
	)
}

const Suspended: React.FC<PopulationProps> = ({ ladCode }) => {
	return (
		<React.Suspense fallback={<Loading />}>
			<Population ladCode={ladCode} />
		</React.Suspense>
	)
}

export { Suspended as Population }
