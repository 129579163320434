/**
 * @generated SignedSource<<31f4888dd83fd216eea84edf7be58a3c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ElderlyCareForecastSiteFragment$data = {
  readonly numberOfBeds: number | null | undefined;
  readonly registrationDate: any | null | undefined;
  readonly " $fragmentType": "ElderlyCareForecastSiteFragment";
};
export type ElderlyCareForecastSiteFragment$key = {
  readonly " $data"?: ElderlyCareForecastSiteFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ElderlyCareForecastSiteFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ElderlyCareForecastSiteFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registrationDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numberOfBeds",
      "storageKey": null
    }
  ],
  "type": "Site",
  "abstractKey": null
};

(node as any).hash = "bf2484cd46a86389e09c819c12b19abd";

export default node;
