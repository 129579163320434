import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import {
	StatXAttendanceAllowanceByAwardTimeSeriesQuery as AAQueryType,
	StatXAttendanceAllowanceByAwardTimeSeriesQuery$data as AAQueryDataType,
} from '../graphql/queries/__generated__/StatXAttendanceAllowanceByAwardTimeSeriesQuery.graphql'
import { StatXAttendanceAllowanceByAwardTimeSeriesQuery as AAQueryRequest } from '../graphql/queries/StatXAttendanceAllowanceByAwardTimeSeries'
import { BarChart } from '../shared/charts/BarChart'
import { VerticalSpace } from '../shared/layout/Space'
import { ParagraphSmallBold, TitleBox } from '../shared/Text'

interface ElderlyDemandForecastChartProps {
	ladCode: string
	showTitleWrapper?: boolean
}

const ElderlyDemandForecastChart: React.FC<ElderlyDemandForecastChartProps> = ({
	ladCode,
	showTitleWrapper = true,
}) => {
	// Fetch data
	const aaData = useLazyLoadQuery<AAQueryType>(AAQueryRequest, {
		geography: ladCode,
	})

	// Process data
	const { labels, datasets } = getBedSupplyAndDemandForecast(aaData)

	return (
		<>
			{showTitleWrapper && (
				<>
					<TitleBox>Forecast on Full-Time Care Demand</TitleBox>
					<ParagraphSmallBold>Elderly with Attendance Allowance in need for full-time care </ParagraphSmallBold>
					<VerticalSpace size="sm" />
				</>
			)}
			<BarChart
				model={{
					labels,
					datasets,
				}}
				aspectRatio={2}
				showLabels={true}
				alpha={1}
			/>
		</>
	)
}

function getBedSupplyAndDemandForecast(aaData: AAQueryDataType) {
	// Process Bed Demand Data (Attendance Allowance Higher Rate)
	const bedDemandData = processBedDemandData(aaData)

	// Define forecast years
	const forecastYears = [2020, 2024, 2028, 2032]

	// Prepare labels
	const labels = forecastYears.map((year) => year.toString())

	// Prepare datasets
	const datasets = [
		{
			id: 'Full-time Care Demand',
			values: forecastYears.map((year) => bedDemandData[year] || null),
		},
	]

	return {
		labels,
		datasets,
	}
}

function processBedDemandData(aaData: AAQueryDataType): { [year: number]: number } {
	if (!aaData || !aaData.statx || !aaData.statx.rows || !aaData.statx.headers) {
		return {}
	}

	const headers = aaData.statx.headers
	const rows = aaData.statx.rows

	// Find indices
	const quarterIndex = headers.indexOf('Quarter')
	const awardTypeIndex = headers.indexOf('AA Award Type')
	const geographyIndex = headers.indexOf('National - Regional - LA - OAs')
	const valueIndex = headers.indexOf('AA (entitled) - 2011 Geographies')

	if (quarterIndex === -1 || awardTypeIndex === -1 || geographyIndex === -1 || valueIndex === -1) {
		console.error('Required headers not found in data.')
		return {}
	}

	// Filter for target geography and Higher Rate (Full-time)
	const targetGeography = rows[0][geographyIndex]
	const filteredRows = rows.filter(
		(row) => row[geographyIndex] === targetGeography && row[awardTypeIndex] === 'Higher Rate',
	)

	// Collect historical data
	const yearValueMap: { [year: number]: number } = {}
	filteredRows.forEach((row) => {
		const quarter = row[quarterIndex] as string // e.g., "Feb-19"
		const year = parseInt('20' + quarter.split('-')[1], 10) // "2019"
		const value = parseInt(row[valueIndex] as string, 10)

		// Sum values per year
		if (!yearValueMap[year]) {
			yearValueMap[year] = 0
		}
		yearValueMap[year] += value
	})

	// Prepare data for regression
	const historicalYears = Object.keys(yearValueMap).map((yearStr) => parseInt(yearStr, 10))
	const historicalValues = historicalYears.map((year) => yearValueMap[year])

	// Perform linear regression
	const regressionResult = linearRegression(historicalYears, historicalValues)

	// Predict values for forecast years
	const forecastYears = [2020, 2024, 2028, 2032]
	const forecastData: { [year: number]: number } = {}
	forecastYears.forEach((year) => {
		forecastData[year] = Math.round(regressionResult.slope * year + regressionResult.intercept)
	})

	return forecastData
}

// Simple linear regression function
function linearRegression(x: number[], y: number[]) {
	const n = x.length
	const sumX = x.reduce((a, b) => a + b, 0)
	const sumY = y.reduce((a, b) => a + b, 0)
	const sumXY = x.reduce((sum, xi, idx) => sum + xi * y[idx], 0)
	const sumX2 = x.reduce((sum, xi) => sum + xi * xi, 0)

	const denominator = n * sumX2 - sumX * sumX
	if (denominator === 0) {
		throw new Error('Denominator in linear regression calculation is zero.')
	}

	const slope = (n * sumXY - sumX * sumY) / denominator
	const intercept = (sumY - slope * sumX) / n

	return { slope, intercept }
}

export { ElderlyDemandForecastChart }
