import React from 'react'

import { Switch } from '../../../../shared/Switch'

interface FilterProps {
	activeFilter: string // The currently selected filter
	showValues: boolean // To manage the switch state
	onShowValuesChange: (value: boolean) => void // Function to toggle show values
	onFilterChange: (value: string) => void // Function to handle filter changes
}

const Filter: React.FC<FilterProps> = ({ activeFilter, showValues, onShowValuesChange, onFilterChange }) => {
	const handleSwitchChange = () => {
		onShowValuesChange(!showValues) // Toggle the showValues state
	}

	const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		onFilterChange(event.target.value) // Call the onFilterChange prop with the selected value
	}

	return (
		<>
			<div className="btn-group">
				<button
					className="btn btn-secondary btn-sm dropdown-toggle"
					type="button"
					data-bs-toggle="dropdown"
					aria-expanded="false"
				>
					Filter Options
				</button>
				<div className="dropdown-menu dropdown-menu-end p-3">
					<div className="mb-3">
						<div className="form-check">
							<input
								type="radio"
								className="form-check-input"
								id="filterBeds"
								name="filterOptions"
								value="beds"
								checked={activeFilter === 'beds'} // Check if this option is active
								onChange={handleRadioChange} // Add onChange handler here
							/>
							<label className="form-check-label" htmlFor="filterBeds">
								Bed Supply Rate
							</label>
						</div>
						<div className="form-check">
							<input
								type="radio"
								className="form-check-input"
								id="filterDementia"
								name="filterOptions"
								value="dementia"
								disabled={true} // Keep it disabled if necessary
								checked={activeFilter === 'dementia'} // Check if this option is active
								onChange={handleRadioChange} // Add onChange handler here
							/>
							<label className="form-check-label" htmlFor="filterDementia">
								Dementia
							</label>
						</div>
						<div className="form-check">
							<input
								type="radio"
								className="form-check-input"
								id="filterNone"
								name="filterOptions"
								value=""
								checked={activeFilter === ''} // Check if this option is active
								onChange={handleRadioChange} // Add onChange handler here
							/>
							<label className="form-check-label" htmlFor="filterNone">
								None
							</label>
						</div>
					</div>

					<hr className="dropdown-divider" />

					<div className="d-flex align-items-center">
						<Switch on={showValues} label="Show values" onClick={handleSwitchChange} />
					</div>
				</div>
			</div>
		</>
	)
}

export { Filter }
