import React from 'react'

import { LineChart, Model } from '../../shared/charts/LineChart'
import { VerticalSpace } from '../../shared/layout/Space'
import { Paragraph, Subtitle } from '../../shared/Text'

interface UnemploymentRateChartProps {
	model: Model
}

const UnemploymentRateChart: React.FC<UnemploymentRateChartProps> = ({ model }) => (
	<>
		<Subtitle>Unemployment Rate</Subtitle>
		<Paragraph>
			Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
			dolore magna aliquyam erat, sed diam voluptua.
		</Paragraph>
		<VerticalSpace size="md" />
		<LineChart model={model} suffix="%" dataLabelDisplayOption="showOnlyLast" aspectRatio={1} />
	</>
)

export { UnemploymentRateChart }
