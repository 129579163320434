// Space.tsx
import React from 'react'

const style = {
	height: 1,
	backgroundColor: '#F2F2F2',
	marginTop: 20,
	marginBottom: 20,
}

const Line: React.FC = () => {
	return <div style={style} />
}

export { Line }
