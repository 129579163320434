import * as turf from '@turf/turf'
import { Feature, MultiPolygon, Polygon } from 'geojson'
import React from 'react'
import { useFragment, useLazyLoadQuery } from 'react-relay'

import { LocalAuthoritiesByLadsQuery as LaQueryResponseType } from '../../graphql/queries/__generated__/LocalAuthoritiesByLadsQuery.graphql'
import {
	OutputAreasByMileRadiusQuery as AreaQueryResponseType,
	RadiusValues,
} from '../../graphql/queries/__generated__/OutputAreasByMileRadiusQuery.graphql'
import { localAuthoritiesByLadsQuery as LaQueryRequestType } from '../../graphql/queries/LocalAuthoritiesByLads'
import { OutputAreasByMileRadiusQuery as AreaQueryRequestType } from '../../graphql/queries/OutputAreasByMileRadius'
import { MapFeatures, Marker } from '../../shared/map/MapFeatures'
import { theme } from '../../Theme'
import { CaptureAreaFragment$key } from './__generated__/CaptureAreaFragment.graphql'
import { CaptureAreaFragment } from './CaptureAreaFragment'

interface MapProps {
	lat: string
	long: string
	radius: RadiusValues
	showingLads: boolean
}

const Map: React.FC<MapProps> = ({ lat, long, radius, showingLads }) => {
	const { outputAreasByMileRadius } = useLazyLoadQuery<AreaQueryResponseType>(AreaQueryRequestType, {
		lat,
		long,
		radius,
	})
	const ca = useFragment<CaptureAreaFragment$key>(CaptureAreaFragment, outputAreasByMileRadius)

	const { localAuthoritiesByLadCodes: las } = useLazyLoadQuery<LaQueryResponseType>(LaQueryRequestType, {
		ladCodes: ca.ladCodes,
	})

	const oaFeatures: Feature<Polygon | MultiPolygon>[] = ca.outputAreas.map((o) => {
		const geometry = JSON.parse(o.geometry!)
		return {
			type: 'Feature',
			geometry,
			properties: {
				color: theme.colors.primary,
				alpha: 0,
			},
		}
	})

	const laFeatures: Feature<Polygon | MultiPolygon>[] = las.map((la, i) => {
		const geometry = JSON.parse(la.geometry!)
		return {
			type: 'Feature',
			geometry,
			properties: {
				color: theme.colors.colorPalette[i + 1],
				alpha: 0.2,
			},
		}
	})

	const features = showingLads ? laFeatures.concat(oaFeatures) : oaFeatures
	const featureCollection = turf.featureCollection(features).features

	const markers: Marker[] = [
		{
			location: {
				coordinates: [parseFloat(long), parseFloat(lat)],
				type: 'Point',
			},
			name: 'Output Area',
			id: '1',
		},
	]

	return <MapFeatures features={featureCollection} locations={markers} />
}

export { Map }
