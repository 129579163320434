import React from 'react'

import { AttendanceAllowanceChartByAwardTimeSeries } from '../../../charts/AttendanceAllowanceByAwardTimeSeries'
import { ElderlyCareNeedPrevalenceRateChart } from '../../../charts/ElderlyCareNeedPrevalenceRateChart'
import { ElderlyPopulationIn10YearsChart } from '../../../charts/ElderlyPopulationIn10YearsChart'
import { ElderlyPopulationShare } from '../../../charts/ElderlyPopulationShare'
import { LeftAligned } from '../../../shared/layout/Alignment'
import { Col, Container, Row } from '../../../shared/layout/Grid'
import { HorizontalSpace, VerticalSpace } from '../../../shared/layout/Space'
import { Loading } from '../../../shared/Loading'
import { Panel } from '../../../shared/Panel'
import { PageTitle, Paragraph } from '../../../shared/Text'

interface ElderlyProps {
	ladCode: string
}

const Elderly: React.FC<ElderlyProps> = ({ ladCode }) => {
	return (
		<Container>
			<VerticalSpace size="xl" />
			<Row>
				<LeftAligned>
					<HorizontalSpace size="md" />
					<Col count={7}>
						<PageTitle>Elderly Demographics</PageTitle>
						<Paragraph>
							Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
							et dolore magna aliquyam erat, sed diam voluptua. Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
							sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
						</Paragraph>
					</Col>
				</LeftAligned>
			</Row>
			<VerticalSpace size="xl" />
			<Row>
				<Col count={6}>
					<Panel>
						<ElderlyPopulationIn10YearsChart ladCode={ladCode} />
					</Panel>
				</Col>
				<Col count={6}>
					<Panel>
						<ElderlyPopulationShare ladCode={ladCode} />
					</Panel>
				</Col>
			</Row>
			<VerticalSpace size="md" />
			<Row>
				<Col count={6}>
					<Panel>
						<AttendanceAllowanceChartByAwardTimeSeries ladCode={ladCode} />
					</Panel>
				</Col>
				<Col count={6}>
					<Panel>
						<ElderlyCareNeedPrevalenceRateChart ladCode={ladCode} />
					</Panel>
				</Col>
			</Row>
		</Container>
	)
}

const Suspended: React.FC<ElderlyProps> = ({ ladCode }) => {
	return (
		<React.Suspense fallback={<Loading />}>
			<Elderly ladCode={ladCode} />
		</React.Suspense>
	)
}

export { Suspended as Elderly }
