/**
 * @generated SignedSource<<3b8c644f7e44bc7e5460f48ff1cc8e48>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type MedianAgeByLadCodeQuery$variables = {
  ladCode: string;
  years: number;
};
export type MedianAgeByLadCodeQuery$data = {
  readonly medianAgeByLadCode: {
    readonly currentYear: number;
    readonly futureYear: number;
    readonly ladAge: number;
    readonly ladCode: string;
    readonly ladFutureAge: number;
    readonly ladGrowthRate: number;
    readonly nationalAge: number;
    readonly nationalFutureAge: number;
    readonly nationalGrowthRate: number;
  };
};
export type MedianAgeByLadCodeQuery = {
  response: MedianAgeByLadCodeQuery$data;
  variables: MedianAgeByLadCodeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ladCode"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "years"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "ladCode",
        "variableName": "ladCode"
      },
      {
        "kind": "Variable",
        "name": "years",
        "variableName": "years"
      }
    ],
    "concreteType": "MedianAge",
    "kind": "LinkedField",
    "name": "medianAgeByLadCode",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ladCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ladGrowthRate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ladAge",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ladFutureAge",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "nationalGrowthRate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "nationalAge",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "nationalFutureAge",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currentYear",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "futureYear",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MedianAgeByLadCodeQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MedianAgeByLadCodeQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "027b1d674f9f4f83beb867e03e475eb3",
    "id": null,
    "metadata": {},
    "name": "MedianAgeByLadCodeQuery",
    "operationKind": "query",
    "text": "query MedianAgeByLadCodeQuery(\n  $ladCode: String!\n  $years: Int!\n) {\n  medianAgeByLadCode(ladCode: $ladCode, years: $years) {\n    ladCode\n    ladGrowthRate\n    ladAge\n    ladFutureAge\n    nationalGrowthRate\n    nationalAge\n    nationalFutureAge\n    currentYear\n    futureYear\n  }\n}\n"
  }
};
})();

(node as any).hash = "f86c1c11214ee97d5c4b4900afb13507";

export default node;
